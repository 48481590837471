﻿.b-carousel-control,
.b-carousel-controls {
    display: inline-flex;
    min-width: 0;
    margin: auto;
    justify-content: center;
    text-align: center;
    position: relative;
    width: auto;

    //elements
    &__wrapper {

        &--position-middle {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            padding: 0 12px;
        }
    }

    &__icon {
        width: 26px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    //modifiers
    &--round {
        border-radius: 50%;
        padding: 5px;
        width: 36px;
        height: 36px;
    }

    .dots {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px 0;
        gap: 16px;
        margin: 0;
        list-style: none;
        margin: auto;
        top: 4px;

        li::before{
            background: #D1D1D1;
            border-radius: 50%;
            content: "";
            display: block;
            height: 12px;
            width: 12px;;
        }

        li.active::before{
            background: $lightGreen;
        }


    }
}
