
$wg: ".imageGallery";
$wgHeight: 500px;
$wgHeightSm: 400px;
$wgHeightXs: auto;
$wgHeightXxs: 195px;


$wgMaxWidth: 1140px;
$content-inset: 1140px;

#{$wg} {

    &-content {
        position: relative;
        height: $wgHeight;
        // width: $wgMaxWidth;
        max-width: $content-inset;

        .rightSideBar & {
            height: $wgHeightXs;
        }
    }
    &-slider {
        position: relative;
        max-width: $content-inset;
    }
    &-frame {
        height: $wgHeight;
        width: 100%;
        max-width: $content-inset;
        position: relative;
        font-size: 0;
        line-height: 0;
        overflow: hidden;
        white-space: nowrap;

        .rightSideBar & {
            height: $wgHeightXs;
        }
    }
    &-slides {
        display: block;
        width: 100%;
        padding: 0;
    }

    &-slide {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 750px;//$wgHeight;
        background-size: cover;
        background-position: center;
        white-space: normal;

        [data-carousel-autoplay] & {
            @media(min-width: $screen-md-min) {
                img {
                    position: relative;
                    top: 50%;
                    margin-top: -50px;
                    width: 100%;
                }
            }
        }

        .wysiwygContent ul &,
        .rightSideBar ul & {
            background-image: none !important;
        }

        .rightSideBar ul & {
            height: $wgHeightXs;
        }
    }
    //
    //&-hdWrap {
    //  display: inline;
    //  position: absolute;
    //  background-color: $xdk-gry;
    //}
    //&-heading {
    //  font-size: 13px;
    //  color: white;
    //  margin: 4px 12px !important;
    //}
    //
    //&-tlWrap {
    //  display: inline;
    //  position: absolute;
    //  padding: 10px 24px;
    //  bottom: 0;
    //
    //}

    &-nextBtn, &-prevBtn {
        position: absolute !important;
        top: 50%;
        margin-top: -16px;
        display: block;
        cursor: pointer;
        border-radius: 50%;
        padding: 5px;
        width: 36px;
        height: 36px;
        z-index: 1;
        background: $lightGreen;
        color: #000;
    }
    &-nextBtn {
        right: 16px;
    }

    &-prevBtn {
        left: 16px;
    }

    .description-photo {
        color: #fff;
        padding: 17px 15px;
        height: 180px;
        position: absolute;
        top: 15px;
        left: 15px;
        right: 0;
        font-size: 1.6rem;
        white-space: normal;
        line-height: 1;
    }

    .video-item__play-btn {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -25px;
        margin-top: -25px;
        width: 50px;
    }

    .ribon-lg {
        background: transparent url('#{$imagePath}hostel-tile-pricing_lge.svg') no-repeat;
        position: absolute;
        top: 15px;
        right: 30px;
        height: 164px;
        width: 164px;
        background-size: cover;

        &.whiskers {
            background-image: url('#{$imagePath}hostel-tile-pricing-whiskers_lge.svg');
        }

        &.has-unit {
            background-image: url('#{$imagePath}hostel-tile-pricing.svg');
        }

        &.has-unit.whiskers {
            background-image: url('#{$imagePath}hostel-tile-pricing-whiskers.svg');
        }

        .rating {
            display: none;
        }

        .mincost {
            color: black;
            font-size: 4.5rem;
            font-weight: bold;
            margin-top: 50px;
            text-align: center;
        }

        .ratingBadgeMedium {
            margin: 32px auto 0;
        }

        .save {
            color: white;
            text-transform: uppercase;
            background: $brand-primary-blue;
            text-align: center;
            font-family: $FuturaBoldCondensedFontStack;
            font-size: 3rem;
            margin-top: 30px;
            padding: 10px;
        }

        .unit {
            margin-top: -9px;
            color: #000;
            text-align: center;
            font-size: 2.3rem;
        }

        .groupSize {
            padding: 4px;
            margin-top: 4px;
            background-color: #3F9C35;
            color: #fff;
            text-transform: uppercase;
            font-size: 2.3rem;
            font-family: $FuturaCondensedFontStack;
            text-align: center;
        }

        @media (max-width: $screen-xsmd-max) {
            transform: scale(0.6);
            right: 5px;
            top: -15px;
        }

    }

}


//States

#{$wg} {
    @media (max-width: $screen-sm-max) {
        &-content {
            height: $wgHeightSm;
        }

        &-frame {
            height: $wgHeightSm;

        }


        &-slide {
            height: $wgHeightSm;
        }

        .description-photo {
            top: auto;
            left: 0;
            height: auto;
           bottom: 25px;
           // padding-right: 130px;

        }
    }

    @media (max-width: $screen-xs-max) {
        &-content {
            height: $wgHeightXs;
        }

        &-frame {
            height: $wgHeightXs;

        }


        &-slide {
            height: $wgHeightXs;
        }
    }
    @media (max-width: $screen-xxs-max) {
        &-content {
            height: $wgHeightXxs;
        }

        &-frame {
            height: $wgHeightXxs;

        }


        &-slide {
            height: $wgHeightXxs;
        }
    }

    &.no-mobile-video {
        .hide-mobile {
            @media (max-width: $screen-sm-max) {
                display: none;
            }
        }
    }
    // modifier to hide ribbon
    &--noPriceRibbon, &.is-playing {
        .ribon-lg {
            display: none;
        }
    }
    &--noWhiskers {
        .ribon-lg {
            background-image: url('#{$imagePath}hostel-tile-pricing_lge.svg') !important;
        }
    }
    &--noPerPerson {
        .ribon-lg {
            background-image: url('#{$imagePath}hostel-tile-pricing-whiskers.svg') !important;
        }
    }
    &--perNight {
        .ribon-lg {
            background-image: url('#{$imagePath}hostel-tile-pricing-lge_per_night.svg') !important;
        }
    }
    &--perNightWhiskers {
        .ribon-lg {
            background-image: url('#{$imagePath}hostel-tile-pricing-whiskers_lge_per_night.svg') !important;
        }
    }



}
