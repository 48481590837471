$pg: ".pgOnlinePaymentConfirmationPage";

#{$pg} {
    color: $baseTextColor;

    section {
        padding: 20px 0;
        //border-bottom: 1px solid #ddd;
    }

    .section-heading {
        h1 {
            color: $grayDark;
        }
    }

    .subHeading {
        font-size: 1.6rem;
        font-weight: bold;
        margin: 15px 0;
    }

    .pageWrap {
        margin: 0 auto;
        width: 100%;
        max-width: 670px;
    }
    .stepper {
        margin-top: 40px;
    }
    .result {
        font-size: 1.8rem;
        .summary {
            padding: 5px 0;
        }
    }

    .successText {
        font-size: 2rem;
        padding-top: 20px;
    }
    .pageError {
        color : $orange;
        font-size: 2rem;
        * {
            color : $orange;
        }
    }

}

//States

//#{$pg} {
//
//}
