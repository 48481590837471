$par: ".whatYouCanDoPagePartial";

#{$par} {

    &-image {
        width: 100%;
    }

    &-content {
        background-color: #f0f0ee;
        margin-top: -10px;
        padding: 20px 20px 10px;
    }
}

/* States */

//#{$par} {
//
//}


