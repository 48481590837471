
$blk: ".sidebarLinksBlock";

#{$blk} {
    &-links {
        margin-bottom: 15px;
        padding: 20px;
    }
    &-link {
        margin-bottom: 6px;
    }

}


/* States */


//#{$blk} {
//
//}
//
