
// normalize.css - http://necolas.github.io/normalize.css/
@import "bootstrap";
@import "bootstrap-datepicker";

//@import 'common/normalize';

// Site-specific variables
@import "variables";
@import 'fonts/fonts';

// commonly used styles
@import 'common/common';

// Shared elements
@import 'common/lists';
@import 'common/input';


// Load Header and Footer Widgets

@import "widgets/header";
@import "widgets/footer";

// Reused components
@import 'common/buttons';
@import 'common/backgroundBanner';
//@import 'Search/searchYHA';
//@import 'Search/searchResultYHA';
@import 'common/googleMap';
@import 'common/flags';
@import 'common/wysiwygContent';
@import 'common/yhaCompactor';
@import 'common/pageTabs';
@import 'common/yhaStyledForm';
@import 'common/carousels';
@import 'common/carousel-controls';
@import 'common/hostelTiles';
@import 'common/hostelReviews';
@import 'common/hostelReviewsCarousel';
@import 'common/progressIndicator';
@import 'common/slick-carousel';
@import 'common/filters';
@import 'common/modal';
@import 'common/calendar';
@import 'common/divider';
@import 'common/link-icon';
@import 'common/compactor';
@import 'common/cta-link';
@import 'common/cta-tile';
@import 'common/icheck';
@import 'common/lazy-loading';
@import "common/line-clamping";
@import "common/form";
@import "common/loader";


// ////////////////////
// Template styles
//@import 'RateThisPage/ratethispageForm';




// ////////////////////
//Generic styles
@import 'common/text';
@import 'common/heading';
@import 'common/margin';
@import 'common/display';
@import 'common/bookingEnquiryForms';
@import "common/foreignLanguage";
@import "common/rightHandPanel";


///////////////////////
// EpiServer Forms
@import 'common/epiForms';


// Load All Block styles
@import "blocks/discount-block";
@import "blocks/icon-widget-block";
@import "blocks/image-link-block";
@import "blocks/image_file_block";
@import "blocks/discount-block";
@import "blocks/hostel-special-block";
@import "blocks/hostel-activity-block";
@import "blocks/state-widget-title-block";
@import "blocks/travel-deal-block";
@import "blocks/youtube_block";
@import "blocks/carousel-block";
@import "blocks/cookie-consent-block";
@import "blocks/discount-listing-block";
@import "blocks/content-page-listing-block";
@import "blocks/find-work-in-aus-block";
@import "blocks/find-work-in-aus-item-block";
@import "blocks/top-travel-destinations-block";
@import "blocks/travel-insurance-block";
@import "blocks/member-special-sidebar-block";
@import "blocks/epack-block";
@import "blocks/epack-image-block";
@import "blocks/marketing-promo-block";
@import "blocks/badge-item-block";
@import "blocks/testimonial-block";
@import "blocks/terms-and-conditions-block";
@import "blocks/banner-image-slider-block";
@import "blocks/location-details-block";
@import "blocks/price-block";
@import "blocks/price-option-item-block";
@import "blocks/share-your-story-block";
@import "blocks/sidebar-links-block";
@import "blocks/webcam-iframe-block";
@import "blocks/where-to-stay-block";
@import "blocks/_private-dormitory-room-type-block";
@import "blocks/hi_sidebar_block";
@import "blocks/sidebar_youtube_block";
@import "blocks/groups-cta-block";
@import "blocks/hostel-traveller-photo-gallery-block";
@import "blocks/standard-deals-block";
@import "blocks/meet-your-hosts-block";
@import "blocks/hostelFeatureBlock";


//Load all Page Partials

@import "partials/hostel_details_page";
@import "partials/related-tour-and-activities";
@import "partials/promo_feature_item";
@import "partials/mini_carousel_item";
@import "partials/hostel-type-key";
@import "partials/hostel-listing-tile";
@import "partials/news-page-partial";
@import "partials/what-you-can-do-page-partial";
@import "partials/group-booking";
@import "partials/my-yha-sidebar-nav";
@import "partials/travel-carousel-tile";
@import "partials/tours-activities-page-partial";
@import "partials/travel-story-tile";
@import "partials/top_hostel_carousel_item";


// ////////////////////
// Widgets specific styles

@import "widgets/badge";
@import "widgets/bannerCarousel";
@import "widgets/calendar";
@import "widgets/findUs";
@import "widgets/fullWidthPromo";
@import "widgets/googleMap";
@import "widgets/hostelDiscount";
@import "widgets/hostelFeaturedReviews";
@import "widgets/hostelRating";
@import "widgets/imageGallery";
@import "widgets/nearbyHostels";
@import "widgets/paging";
@import "widgets/yhaPageTabs";
@import "widgets/filterTabs";
@import "widgets/hostelListings";
@import "widgets/svgMap";
@import "widgets/journeyTimes";
@import "widgets/featuredItemsCarousel";
@import "widgets/yhaSearchBar";
@import "widgets/bookingEnquiryForm";
@import "widgets/auth0Lock";
@import "widgets/simpleCarousel";
@import "widgets/shareYourStoryForm";
@import "widgets/progressStepper";
@import "widgets/paymentCart";
@import "widgets/braintreePayment";
@import "widgets/contactForm";
@import "widgets/searchBar";
@import "widgets/searchResult";
@import "widgets/groupsPageHeader";
@import "widgets/wishList";
@import "widgets/smartAppBanner";
@import "../../wg/backToTopWidget/scss/backToTopWidget";


// ////////////////////
// Page specific styles

@import "pages/homePage";
@import "pages/hostelDetailPage";
@import "pages/hostelGroupPage";
@import "pages/hostelJobsPage";
@import "pages/hostelRentAYhaPage";
@import "pages/hostelReviewPage";
@import "pages/hostelSpecialPage";
@import "pages/hostelSustainabilityPage";
@import "pages/hostelThingsToDoPage";
@import "pages/hostelListingPage";
@import "pages/hostelStateListingPage";
@import "pages/hostelCityListingPage";
@import "pages/newsListingPage";
@import "pages/newsPage";
@import "pages/discountListingPage";
@import "pages/contentPage";
@import "pages/sustainablePage";
@import "pages/groupBookingPage";
@import "pages/searchPage";
@import "pages/loginPage";
@import "pages/userProfilePage";
@import "pages/userBookingsPage";
@import "pages/userEpacksPage";
@import "pages/userWishListPage";
@import "pages/userMembershipCardPage";
@import "pages/toursActivitiesListingPage";
@import "pages/travelStoriesListingsPage";
@import "pages/toursPage";
@import "pages/membershipLandingPage";
@import "pages/membershipLoginPage";
@import "pages/membershipFormPage";
@import "pages/membershipPaymentPage";
@import "pages/membershipConfirmationPage";
@import "pages/epackLoginPage";
@import "pages/epackFormPage";
@import "pages/epackPaymentPage";
@import "pages/epackConfirmationPage";
@import "pages/shareYourStoryFormPage";
@import "pages/userBookingDetailsPage";
@import "pages/tourBookingDetailPage";
@import "pages/tourBookingPaymentPage";
@import "pages/tourBookingConfirmationPage";
@import "pages/tourEnquiryFormPage";
@import "pages/404Page";
@import "pages/searchResultPage";
@import "pages/onlinePaymentPage";
@import "pages/onlinePaymentConfirmationPage";
@import "pages/groupsPage";
@import "pages/groupsQuoteProfilePage";
@import "pages/groupsQuoteDetailPage";
@import "pages/groupsQuoteConfirmationPage";
@import "pages/groupsEnquiryPage";



// ////////////////////
// override new styles
@import "common/newThemeOverrides";
