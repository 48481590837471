$pg: ".pgUserEpacksPage";

#{$pg} {

    color: $baseTextColor;

    h1 {
        margin-top: 30px;;
    }


    .summarybar {
        background-color: $blueLight;
        color: white;
        cursor: pointer;
        padding: 10px 10px 10px 0;
        position: relative;
        transition: background-color 0.3s ease-in-out;

        > div {
            display: inline-block;
            font-size: 1.2rem;
            vertical-align: top;
        }
    }

    .summarybar .arrow {
        cursor: pointer;
        font-size: 2.0rem;
        margin-top: -15px;
        width: 30px;
        height: 30px;
        text-align: center;
        position: absolute;
        left: 13px;
        top: 50%;
        text-shadow: #000 1px 1px;
    }

    .summarybar .title {
        border-left: 1px solid $white;
        margin-left: 50px;
        padding-left: 10px;
        width: 300px;

        .name {
            font-size: 1.6rem;
            font-family: $FuturaCondensedFontStack;
            text-transform: none;
        }

        .number {
            font-weight: bold;
            padding: 5px 0;
        }
    }

    .summarybar .col-right {
        float: right;
    }

    .nights-left .number {
        font-family: $FuturaCondensedFontStack;
        font-size: 1.6rem;
    }

    .summarybar .valid {
        padding: 5px 0;
        text-align: right;
    }

    .epackDetails {
        margin-bottom: 10px;

        &.open .closed {
            display: none;
        }

        &.closed {

            .summarybar {
                background-color: #f0f0ee;
                color: $baseTextColor;
                transition: background-color 0.3s ease-in-out;
            }

            .open {
                display: none;
            }

            .content {
                max-height: 0;
                overflow: hidden;

                .content-inner {
                    border-color: $grayLightest;
                }
            }

            .nights-left .number {
                color: $blueHeadingColor;
            }
        }

        &.nobookings {

            .nobookings-message {
                display: block;
            }

            .booked-nights {
                display: none;
            }
        }
    }

    .booked-nights {

        th {
            border: 0 none;
            font-family: $FuturaCondensedFontStack;
            font-weight: normal;
        }

        td {
            border: 0 none;
        }
    }

    .content {
        max-height: 5000px;
        overflow: hidden;
        transition: all 0.3s ease-in-out;

        .content-inner {
            background-color: white;
            border: 1px solid $blueLight;
            border-top: none;
            color: $baseTextColor;
            padding: 30px;
        }
    }

    .content .terms {
        margin-top: 20px;

        ul {
            list-style: none;
            margin-left: 20px;
            padding: 0;
        }

        li {
            background: url('#{$imagePath}lists/blueSquare.png') no-repeat 0 7px;
            margin-bottom: 8px;
            padding-left: 17px;
        }
    }

    .nobooking .nobookings-message {
        display: none;
        margin-top: 6px;
    }

    .current-epacks {
        margin-bottom: 30px;
    }

    .epacks-buttons {
        border-bottom: 1px solid $formInputElementBorderColor;
        padding-bottom: 30px;

        .btn-right {
            float: right;
        }
    }

    .expired-epacks {
        margin-bottom: 40px;
        margin-top: 30px;
    }

    .epackDetails.expired .nights-left {
        display: none;
    }

    .myYhaEditDetailsSidebar ul {
        list-style-type: none;
        margin-bottom: 0;
        padding: 0;
    }

    .search-keywords {
        width: calc(100% - 180px);
        display: inline-block;
    }
    #search-button {
        display: inline-block;
        height: 50px;
        margin-top: -5px;
        margin-left: 10px;
    }

    @media (max-width: $screen-xsmd-max) {

        .search-keywords {
            width: auto;
            display: block;
        }
        #search-button {
            display: block;
            width: 100%;
            height: 50px;
            margin-top: 0;
            margin-left: 0;
        }
    }

    @media (max-width: 640px) {

        .content .content-inner {
            padding: 15px;
        }

        .summarybar .title {
            max-width: calc(100% - 50px);

            .name {
                font-size: 1.8rem;
            }
        }

        .summarybar .col-right {

            border-left: 1px solid $white;
            float: none;
            margin-left: 50px;
            padding-left: 10px;
            width: calc(100% - 50px);

            .number {
                font-size: 1.4rem;
            }

            .valid {
                text-align: left;
            }
        }

        .epackDetails.closed .nights-left .number {
            color: $gray;
        }

        .table.vw-mobile-sm td {
            padding-left: 110px;

            &:before {
                left: 10px;
                width: 105px;
            }
        }
    }

    @media (max-width: $screen-xsmd-max) {

        .expired .content .content-inner {
            padding: 0;
        }
    }

}
