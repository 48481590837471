$pg: ".pgOnlinePaymentPage";

#{$pg} {
    color: $baseTextColor;

    section {
        padding: 20px 0;
    }

    .section-heading {
        h1 {
            color: $grayDark;
        }
    }

    .subHeading {
        font-size: 1.6rem;
        font-weight: bold;
        margin: 15px 0;
    }

    .pageWrap {
        margin: 0 auto;
        width: 100%;
        max-width: 670px;
    }
    .stepper {
        margin-top: 40px;
    }
    .summary {
        strong  {
            font-size: 1.8rem;
        }
    }
    .result {
        font-size: 1.8rem;
        .summary {
            padding: 5px 0;
        }
    }

    .successText {
        font-size: 2rem;
        padding-top: 20px;
    }
    .pageError {
        color : $orange;
        font-size: 2rem;
        * {
            color : $orange;
        }
    }
    .yhaStyledForm {
        padding-top: 0;
        select {
            font-size: 1.6rem;
        }

        label {
            margin-bottom: 10px;
            margin-top: 10px;
            font-weight: bold;
        }

        .forms {
            //padding-top: 10px;
            width: 400px;
            max-width: 100%;
        }

        .payment {
            .braintreePayment-note {
                font-size: 1.6rem;
            }
        }
        .acceptTNC,
        .acceptPromos {
            margin: 20px 0;
        }
        .form-cta {
            padding-bottom: 20px;
            padding-top: 30px;

            .backButton, .nextButton {
                margin: 10px 0;
            }

            .backButton {
                float: left;
            }

            .nextButton {
                float: right;
            }

            .return-link {
                float: left;
                clear: left;
                margin-top: 10px;
                margin-bottom: 40px;
                font-size: 1.6rem;
            }

        }
    }
    .termsAndConditionsBlock {
        height: auto;
    }
    .invalid {
        border: 2px solid #EE4601 !important;
    }
    .formError {
        color: #EE4601;
        background: url('#{$imagePath}/forms/errorMessageIndicator.png') no-repeat;
        padding-left: 24px;
        margin-top: 5px;
    }
}

//States

#{$pg} {

    @media (max-width: $screen-xsmd-max) {
        .form-cta {
            .backButton, .nextButton {
                margin: 10px 0;
            }

            .backButton {
                float: none;
            }

            .nextButton {
                float: none;
            }

            .return-link {
                float: none;
                display: block;
                text-align: center;
            }

        }
    }

}
