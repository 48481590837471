

$par: ".hostelTypeKey";

#{$par} {

    margin: 30px 0;
    color: $baseTextColor;

    &-image {
        display: inline-block;
        vertical-align: top;

        img {
            width: 60px;
            float: left;
        }
    }

    &-text {
        display: inline-block;
        margin-left: 10px;
        max-width: 440px;
    }

    &-title {
        font-weight: bold;
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
}

/* States */

#{$par} {

    @media (max-width: $screen-md-min) {
        &-text {
            margin-left: 0;
            margin-top: 20px;
            width: 100%;
        }
        &-title {
            font-size: 1.2rem;
        }
    }

}


