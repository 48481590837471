$pg: ".pgEpackLoginPage";

#{$pg} {
    color: $baseTextColor;

    .stepper {
        padding: 25px 0;
    }
    .heading {
        padding-top: 40px;
    }

    .summary {
        > p {
            margin-bottom: 15px;
        }
    }
    .authContainer {
        padding-bottom: 50px;
        text-align: center;

        .privacy {
            padding: 20px;
        }
    }

}
