// Text
//
// Text styles
//
// .b-text - Default
// .b-text--uppercase - Transforms text to uppercase
//
// Markup:
// <p class="b-text">I am text</p>

// Style guide: Components.Text

.b-text {
    font-size: 1em;

    // Elements

    // Modifiers
    &--uppercase {
        text-transform: uppercase;
    }

    &--bold {
        font-weight: bold;
        font-family: $FuturaHeavyFontStack;
    }

    &--bold-condensed {
        font-family: $FuturaBoldCondensedFontStack;
    }

    &-default {
        font-size: 1.6rem;
    }

    &--small {
        font-size: 1.4rem;
    }

    &--med {
        font-size: 1.8rem;
    }

    &--large {
        font-size: 2.0rem;
    }

    &--x-large {
        font-size: 3.2rem;
    }

    &--light {
        color: white;
    }

    &--orange {
        color: #fe502d;
    }

    &--black {
        color: #000;
    }

    &--grey {
        color: #999;
    }

    &--green {
        color: $lightGreen;
    }

}
