
$pg: ".pgNewsPage";

#{$pg} {

    color: $baseTextColor;

    .articlePage {
        padding-top: 40px;
    }

}
