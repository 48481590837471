$pg: ".pg404Page";

#{$pg} {

    color: $baseTextColor;

    &-content {
        padding: 0 40px 80px 40px;

        position: relative;
        background: #fff;
    }

    .headingSection {
        text-align: center;
        padding: 25px 0px;

        h1 {
            margin-bottom: 0;
        }

        div {
            font-size: 2.4rem;
        }
    }

    .subHeader {
        font-size: 2.4rem;
        padding-bottom: 26px;
        text-align: center;
    }

    .mainContentSpacer {
        height: 365px;
    }

    @media (max-width: 767px) {
        .backgroundBanner {
            display: none;
        }
    }

}


