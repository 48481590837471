.b-display {

    &--flex {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        width: 100%;
    }

    &--flex-wrap {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        align-items: stretch;
    }

    &--table {
        display: table;
    }

    &--table-row {
        display: table-row;
    }

    &--table-cell {
        display: table-cell;
    }
}
