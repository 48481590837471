$pg: ".pgUserBookingDetailsPage";

#{$pg} {

    color: $baseTextColor;
    .bookingRef {
        font-size: 18px;
        font-family: $FuturaRegularFontStack;
        text-transform: uppercase;
    }

    .bookingRefNo {
        font-size: 20px;
        font-family: $FuturaCondensedFontStack;
    }

    .roomSummary {
        margin-top: 30px;
    }

    .secHeading {
        background: $grayDark;
        color: #fff;
        font-family: "Futura LT W05 Bold Condensed", "Helvetica Neue", Arial, sans-serif;
        font-size: 1.8rem;
        line-height: 1.5;
        min-height: 40px;
        padding: 5px 15px;
    }
    .secContent {
        border-bottom: 1px dotted $grayDark;
        padding: 20px 15px;
    }

    .guestSummary {
        padding: 5px 15px 20px;
        line-height: 30px;

        .blueCirclesList {
            counter-reset: li-counter;
            list-style-type: none;
            margin: 0;
            padding: 0;


            li {
                line-height: 30px;
                position: relative;
                padding: 5px 0 5px 40px;
                margin-bottom:5px;

                &:before {
                    background-color: $lightGreen;
                    border-radius: 50%;
                    color: white;
                    font-family: $FuturaBoldCondensedFontStack;
                    font-size: 2.0rem;
                    position: absolute;
                    top: 5px;
                    left: 0;
                    line-height: 30px;
                    height: 30px;
                    width: 30px;
                    text-align: center;
                    content: counter(li-counter);
                    counter-increment: li-counter;
                }
            }
        }

        .price-small {
            padding-top: 5px;
            display: inline-block;
            min-width: 120px;
            text-align: right;
            font-size: 2.0rem;
            line-height: 1;

            .currency {
                font-size: 65%;
                line-height: 1.3;
                vertical-align: top;
            }
            .cent {
                font-size: 65%;
                line-height: 1.3;
                vertical-align: top;
            }
        }
    }

}
