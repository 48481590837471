$pg: ".pgSustainablePage";

#{$pg} {

    color: $baseTextColor;

    .heroImage {
        max-width: 100%;
        height: auto;
    }
    .mainBanner {
        margin-bottom: 20px;
    }

    .whatYouCanDo {
        h4 {
            font-size: 1.4rem;
            font-weight: bold;
            margin-top: 0;
        }
    }

    .fullWidthBanner {
        margin-bottom: 30px;
        margin-top: 20px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .hostelListings {
        padding-top: 20px;
    }
}

//States
#{$pg} {
    @media (max-width: $screen-sm-max) {

        .fullWidthBanner {
            margin: 30px 0 10px;
        }
    }



}
