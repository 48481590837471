
$blk: ".badgeItemBlock";

#{$blk} {
    background-color: transparent;

    &-badge-item {
        margin-bottom: 30px;
    }

    &-badge-item-description {
        text-align: center;
    }

    &-image {
        padding-bottom: 20px;
        text-align: center;
        width: 100%;

        img {
            height: auto;
            margin: auto;
            max-width: 100%;
        }
    }


}


/* States */


//#{$blk} {
//
//}
//
