$wg: ".braintreePayment";

#{$wg} {

    background-color: #fff;
    color: $baseTextColor;
    margin-bottom: 20px;
    font-size: 12px;

    &-note {
        background-color: #fff;
        padding: 20px 0 20px 15px;
    }
    &-paymentMethods {
        background-color: #fff;
        border-top: 1px solid #ddd;
        font-size: 1.6rem;
        padding: 20px 0 10px;
    }
    .payment-label {
        padding-left: 30px;
    }
    .accept-cards {
        position: relative;

        &:after {
            content: '';
            background: #ddd;
            width: 1px;
            position: absolute;
            top: 10px;
            bottom: 10px;
            right: 0;
        }
    }
    .ssl-cert {
        float: right;
        padding-left: 20px;
    }

}

//States

//#{$wg} {
//
//
//}
