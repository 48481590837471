﻿.yhaStyledForm {

    .error ul {
        margin: 0 0 20px 0;
        padding: 0;
        list-style-position: inside;
    }

    fieldset {
		border: none;
	}

	.field-group {
		margin-bottom: 20px;
	}

    .form-group {
        margin-bottom: 20px;
    }

	label {
		display: block;
		font-weight: normal;
	}

    .topspacing{
        margin-top: 30px;
    }

    .membership-type, .ucNationality{
        @extend .topspacing;
    }

    input[type="text"], 
    input[type="email"], 
    input[type="password"], 
    textarea {
		@extend .standardInputStyle;
	}

	.styled-select {
		@extend .styledSelectStyle;
	}

    .birthday-select {
        display: block;
    }

    .membership-hi-instruction {
        display: none;
    }

    .double-buttons {
        width: 100%;
        display: block;
        clear: both;

        .btn {
            width: auto !important;
        }

        .double-buttons-left {
            width: 50%;
            float: left;
        }

        .double-buttons-right {
            width: 50%;
            float: right;
            text-align: right;
        }
    }

    .error,
    .error p {
        color: $errorMessageText;
    }

    .captchaField {
        background: #DAEBFD;
        border: 2px solid #DAEBFD;
        border-radius: 6px;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 30px;

        &.invalid {
            border-color: $errorMessageText;
        }

        @media (max-width: $screen-xxs-max) {
            padding: 5px 0px;
            margin-left: -5px;            
        }
    }

    /* From the built-in MVC validation messages */
    .field-validation-error {
        color: red;
        display: block;
    }

    /* From the built-in MVC validation messages */
    .field-validation-valid {
        display: none;
    }
}

html:not(.lt-ie9) .yhaStyledForm:not(.standardCheckboxes) {
	input[type="checkbox"] {
		@extend .styledCheckboxStyle;
	}
}

html:not(.lt-ie9) .yhaStyledForm:not(.standardRadioButtons) {
	input[type="radio"] {
		@extend .styledRadioButtonStyle;
	}
}

html.lt-ie9 .yhaStyledForm {
	input[type="text"], input[type="password"] {
		padding-top: 16px;
	}

	.styled-select select {
		height: 39px;
	}
}

.error-field {
    margin-top: 5px;
}

.section-cta {
    display: inline-block;
    margin: 40px 0;
    width: 100%;
    
    @media (max-width: $screen-xsmd-max) {
    
    margin: 30px 0;
        .btn {
            margin-top: 10px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}