$wg: ".progressStepper";

#{$wg} {

    margin: 0;
    padding: 0;
    display: flex;

    &-item {
        position: relative;
        list-style-type: none;
        flex: 1;

        &:after {
            content: '';
            width: calc(100% - 58px);
            height: 5px;
            background-color: #ddd;
            position: absolute;
            top: 19px;
            margin: 0 20px;
            left: 55%;
            border-radius: 2px;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }

    &-number {
        display: block;
        background: #fff;
        width: 38px;
        height: 38px;
        margin-left: calc(50% - 19px);
        margin-bottom: 2px;
        padding: 2px;
        border-radius: 50%;
        border: 2px solid #464646;
        color: #464646;
        text-align: center;
        font-size: 2.2rem;
        font-family: $FuturaBoldCondensedFontStack;
    }

    &-text {
        text-transform: uppercase;
        font-size: 1.6rem;
        font-family: $FuturaBoldCondensedFontStack;
    }

}

/* States */

#{$wg} {

    &-number {

        &--complete {
            background: $lightGreen;
            padding-top: 3px;
            border: none;
            color: $white;
        }

        &--active {
            background-color: $lightGreen;
            padding-top: 3px;
            font-weight: 700;
            border: none;
            position: relative;

            &:not(.progressStepper-number--complete) {
                color: $lightGreen;
                text-indent: -999em;

                &::before {
                    content: "";
                    border-bottom: 2px solid #fff;
                    position: absolute;
                    left: 7px;
                    bottom: 14px;
                    transform: rotate(45deg);
                    width: 10px;
                }

                &::after {
                    content: "";
                    border-bottom: 2px solid #fff;
                    position: absolute;
                    right: 6px;
                    bottom: 17px;
                    transform: rotate(-45deg);
                    width: 20px;
                }
            }
        }
    }

}


