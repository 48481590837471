
$wg: ".hostelRating";

#{$wg} {

	&-title {
		color: white;
	}

	&-toggle {
		display: block;
		font-family: $sansFontStack;
        font-size: 1.6rem;
        cursor: pointer;
        position: relative;

        @media (min-width: $screen-xs-max) {
            float: none;
        }

        &::after {
            content: '';
            display: block;
            float: right;
            width: 20px;
            height: 20px;
            background: url('#{$imagePath}icons/icon-chevron-down_white.svg') no-repeat 100%;
        }
	}

	&-inner {
		padding: 20px 0;
	}

	&-guidelines {
		display: none;
		font-size: 1.2rem;
	}

	&-overallrating {

	}

	&-totalratting {
		font-size: 3.6rem;
		display: block;
	}

	&-numratting {
		display: block;
		padding-bottom: 20px;
		text-align: center;
	}

	&-ratingpair {
		padding: 10px 0px;
		border-top: 1px dotted #505050;

		.name {
            float: left;
            font-size: 1.8rem;
        }

        .rating {
            float: right;
            font-size: 2.0rem;
        }
	}
}

/* States */


#{$wg} {

    &-ratingpair:last-child {
        border-bottom: 1px dotted #505050;
    }

    &-toggle {
        .is-hr-open & {
            &:after {
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }
    }

    &-guidelines {
        .is-hr-open & {
            display: block;
        }
    }

}

