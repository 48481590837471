//@import "../../../glb/scss/mixins"; // just add what you need

$wg: ".simpleCarousel";
$wgHeight: 485px;
$wgMaxWidth: auto;
$content-inset: auto;

#{$wg} {

    margin: auto;

    &-content {
        position: relative;
    }
    &-slider {
        position: relative;
        max-width: $content-inset;
    }
    &-frame {
        height: $wgHeight;
        width: 100%;
        max-width: $content-inset;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
    }
    &-slides {
        display: inline-block;;
        width: 100%;
        padding: 0;
    }

    &-slide {
        position: relative;
        display: inline-block;
        float: none;
        //width: 100%;
        height: $wgHeight;
        white-space: nowrap;
        vertical-align: middle;

    }

}
