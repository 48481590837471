.specialsItem {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 2px solid #EFEFED;

    .specialsImage {
        position: relative;

        .mainImage {
            width: 100%;
            height: auto;
        }

        .specialsFlagSmall {
            position: absolute;
            top: 0px;
            right: 20px;
        }
    }

    p strong {
        color: $baseTextColor;
    }

    .offerPrice {
        color: #515151;
        font-size: 2.0rem;
        padding-top: 12px;
        background-size: 40px;
        min-height: 54px;
        margin-top: 20px;

        .youSave {
            color: #2ab472;
        }
    }

    .description {
        margin-top: 30px;
    }

    .features {
        margin-top: 20px;
    }

    .listHeading {
        color: $baseTextColor;
        font-weight: bold;
    }

    .btn {
        width: 240px;
        margin-top: 20px;
    }
}

.specialsItem:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

@media (max-width: $screen-sm-max) {

    .specialsItem .specialsImage .specialsFlagSmall {
        display: none;
    }

    .specialsItem .description ul {
        margin-bottom: 10px;
    }

    .specialsItem .offerPrice {
        background-position: left center;
        height: auto;
    }

    .specialsItem .features ul {
        margin-bottom: 10px;
    }

    .discountsContent {

        .badgeItem,
        .sidebarInfoBox,
        .btn {
            display: block;
            margin: 0 auto 30px;
            max-width: 400px;
        }
    }
}

.ribon-lg {
    background: transparent url('#{$imagePath}hostel-tile-pricing.svg') no-repeat;
    background-size: 164px;
    position: absolute;
    top: 15px;
    right: 30px;
    height: 164px;
    width: 164px;
    background-size: cover;

    &.whiskers {
        background-image: url('#{$imagePath}hostel-tile-pricing-whiskers_lge.svg');
    }

    &.has-unit {
        background-image: url('#{$imagePath}hostel-tile-pricing.svg');
    }

    &.has-unit.whiskers {
        background-image: url('#{$imagePath}hostel-tile-pricing-whiskers.svg');
    }

    .mincost {
        color: black;
        font-size: 4.5rem;
        font-weight: bold;
        margin-top: 50px;
        text-align: center;
    }

    .cost-per {
        color: #000;
        font-size: 2.1rem;
        margin-top: -6px;
        line-height: 1;
        text-align: center;
    }

    .ratingBadgeMedium {
        margin: 32px auto 0;
    }

    .save {
        color: white;
        text-transform: uppercase;
        background: $brand-primary-blue;
        text-align: center;
        font-family: $FuturaBoldCondensedFontStack;
        font-size: 3rem;
        margin-top: 30px;
        padding: 10px;
    }

    .unit {
        margin-top: -9px;
        color: #000;
        text-align: center;
        font-size: 2.3rem;
    }

    .groupSize {
        padding: 4px;
        margin-top: 4px;
        background-color: #3F9C35;
        color: #fff;
        text-transform: uppercase;
        font-size: 2.3rem;
        font-family: $FuturaCondensedFontStack;
        text-align: center;
    }

    @media (max-width: $screen-xsmd-max) {
        transform: scale(0.6);
        right: 5px;
        top: -15px;
    }
}
