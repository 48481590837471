
$pg: ".pgGrpsPg";

#{$pg} {

    .rentAYhaImage {
        margin-top: 30px;
        position: relative;
    }

    .imageCaption {
        display: block;
        background: #f0f0ee;
        padding: 0px 10px;
        position: relative;
        top: 0px;

        .wishList {
            .button-like{
                padding: 1.5rem 0.5rem;
                float:right;
            }

            .text {
                margin-left: 0.1rem;
                font-size: 1.3rem;
            }
        }
    }

    .subheading {
        font-size: 1.6rem;
        color: #515151;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .pdf-wrap {
        background: #f0f0ee;

        .pdfLinks {
            margin-bottom: 15px;
            padding: 20px 0;

            &>div {
                margin-bottom: 6px;
            }
        }
    }
    .description {
        padding: 20px;
        padding-bottom: 0;
    }

    .rentAYhaDescription {
        padding-top: 24px;
        display: block;
    }

    .stateHostelList {
        padding: 20px;

        a {
            display: block;
            margin-bottom: 10px;
        }
    }

    .speechBubble {

        padding-top: 40px;
        .speechMarks {
            display: none;
        }
    }

    &-ctaMob {
        .groupsCtaBlock-content {
            padding: 30px 0 0;
        }

        .groupsCtaBlock-enquireNow {
            @include break-sm {
                margin-left: 10px;
            }
        }
    }

}


#{$pg} {

    @media (max-width: $screen-xs-max){
        .imageCaption {
            top:-5px;

            .wishList {
                .button-like {
                    float:left;
                }
            }
        }
    }

    @media (min-width: $screen-xssm-min) and (max-width: $screen-xssm-max){
        .imageCaption {
            top:-35px;
        }
    }
}
