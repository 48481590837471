
$blk: ".memberSpecialSidebarBlock";

#{$blk} {

    .sidebarItemList {
        list-style-type: none;
        margin-bottom: 0;

        li {
            font-size: 1.6rem;
            padding-bottom: 2px;
            padding: 10px 0;
        }
    }



}


/* States */


//#{$blk} {
//
//}
//
