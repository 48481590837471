$par: ".miniCarouselItem";

#{$par} {
	display: inline-flex;
	position: relative;
    white-space: nowrap;
    flex-direction: column;
    min-width: 255px;
    height: auto;
    float: none;

    &-image {
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
            display: block;
        }

        picture {
            display: block;
            overflow: hidden;
        }

		img {
		    height: auto;
            margin: auto;
		    margin-bottom: 10px;
		    max-width: 100%;

            @media (max-width: $screen-xs-max){
                width: 100%;
            }
		}
    }

    &-content {
		//margin-bottom: 80px;
        padding-left: 14px;
        padding-right: 14px;
        .no-padding & {
            padding-left: 0;
            padding-right: 0;
        }
    }

	&-description {
        white-space: normal;
        line-height: pxToRem(24);
    }
    &-heading {
        color: $grayDark;
        line-height: 28px;
        white-space: normal;
    }

    &-subheading {
        color: $grayDark;
        margin: 12px 0px;
    }

    .btn-container {
        //position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 15px 2px 15px;
        margin-top: auto;
        padding-top: 20px;
    }

    a {
        display: block;
        text-decoration: none;
        width: 100%;
    }
}

/* States */

#{$par} {
    [data-alignment="center"] & {
        text-align: center;
    }
}

