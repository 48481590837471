$blk: ".webCamIframeBlock";

#{$blk} {

    &-camframe {
        width: 100%;
        height: 560px;
        background-color: transparent;
    }

}
/* States */

//
//#{$blk} {
//
//}
