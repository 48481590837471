$blk: ".cookieConsentBlock";

#{$blk} {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   background-color: #daeb4c;
   padding: 10px;
   font-size: 18px;   
   text-align: center;
   z-index: 99999;

   .title {
      font-weight: bold;
   }

   .btn-send-cookie-acceptance {
      &:disabled:hover {
         background-color: $defaultButtonBackgroundColor;
      }
   }
}
