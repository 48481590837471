$par: ".topHostelCarouselItem";

#{$par} {
	display: inline-block;
	position: relative;
    white-space: nowrap;
    min-width: 255px;
    height: 288px;
    float: none;
    text-align: center;

    &-image-container {
        min-height: 170px;
        position: relative;
        background: #e3e3e3;
    }

    &-image {
        height: auto;
        width: 100%;
    }

    .yhaFlag {
        position: absolute;
        top: 15px;
        right: 30px;
    }

    &-hostelname, &-heading {
        white-space: normal;
        font-family: $FuturaBoldCondensedFontStack;
        font-weight: bold;
        margin-top: 10px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
    }

}

/* States */

#{$par} {

}

