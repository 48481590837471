// Heading
//
// Heading styles
//
// .b-heading - Default
// .b-heading--light - Lighter coloured headings for dark backgrounds
//
// Markup:
// <p class="b-heading">I am a heading</p>

// Style guide: Components.Heading

.heading {
    &-1 {
        font-family: $font-secondary;
        line-height: 1.2em;
        font-weight: 400;
        font-size: pxToRem(64)!important;
        text-transform: none;
    }
    &-2 {
        font-family: $font-secondary;
        line-height: 1.2em;
        font-weight: 400;
        font-size: pxToRem(48)!important;
        text-transform: none;
    }
    &-3 {
        font-family: $font-secondary;
        line-height: 1.2em;
        font-weight: 400;
        font-size: pxToRem(40)!important;
        text-transform: none;
    }
    &-4 {
        font-family: $font-secondary;
        line-height: 1.2em;
        font-weight: 400;
        font-size: pxToRem(34)!important;
        text-transform: none;
    }
    &-5 {
        font-family: $font-secondary;
        line-height: 1.2em;
        font-weight: 400;
        font-size: pxToRem(26)!important;
        text-transform: none;
    }
    &-6 {
        font-family: $font-secondary;
        line-height: 1.2em;
        font-weight: 400;
        font-size: pxToRem(24)!important;
        text-transform: none;
    }
    &-7 {
        font-family: $font-secondary;
        line-height: 1.2em;
        font-weight: 400;
        font-size: pxToRem(22)!important;
        text-transform: none;
    }
}



.p-heading {
    color: $grayDark;
    font-family: $font-secondary;
    font-size: pxToRem(48);
    font-weight: 400;

}

.s-heading {
    font-family: $font-secondary;
    font-size: pxToRem(24);
    line-height: 28px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.b-heading {
    color: $grayDark;
    font-family: $font-secondary;
    font-weight: 400;
    // Elements
    // Modifiers
    &--light {
        color: white;
    }

    &--accent {
        &::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 29px;
            background: url('#{$imagePath}highlights/LHS_Word_Accent_orange.svg') no-repeat;
            background-size: cover;
            margin-right: 5px;
        }

        &::after {
            content: '';
            display: inline-block;
            width: 20px;
            height: 29px;
            background: url('#{$imagePath}highlights/RHS_Word_Accent_orange.svg') no-repeat;
            background-size: cover;
            margin-left: 5px;
        }
    }

    &--accent-light {
        &::before {
            background: url('#{$imagePath}highlights/LHS_Word_Accent_white.svg') no-repeat;
            height: 38px;
            vertical-align: middle;
            margin-right: 20px;
        }

        &::after {
            background: url('#{$imagePath}highlights/RHS_Word_Accent_white.svg') no-repeat;
            height: 38px;
            vertical-align: middle;
            margin-left: 20px;
        }
    }

    &--underlined {
        color: $brand-primary-orange;
        padding-bottom: 15px;
        background: url('#{$imagePath}dividers/heading-h2.svg') 50% 100% no-repeat;
    }

    &--colour-orange {
        color: $brand-primary-orange;
    }

    &--blue-bg {
        padding: 16px 19px 15px;
        color: white;
        margin: 0 0 18px;
        background: $brand-primary-blue;
        font-size: 2.0rem;

        a,
        a:hover {
            color: white;
        }
    }

    &--green-bg {
        //padding: 16px 19px 15px;
        padding: 16px 0 15px 0;
        color: $grayDark;
        margin: 0 0 18px;
        //background: $brand-secondary-emerald-green;
        font-size: 2.0rem;

        a,
        a:hover {
            color: white;
        }
    }

    &--grey-bg {
        padding: 16px 19px 15px;
        margin: 0 0 18px;
        background: $compactorBackground;
        color: $compactorColor;
        font-size: 2.0rem;
    }

    &--secondary {
        font-family: $LunaRegularFontStack;
        line-height: 1.6em;
        text-transform: none;
        font-size: 2.0rem;
    }

    &--xlarge {
        font-size: 4.2rem;
    }
}


/* states */
@media (min-width: $screen-sm-min ) and (max-width: $screen-sm-max ) {
    .p-heading {
        font-size: pxToRem(40);
    }

    .s-heading {
        font-size: pxToRem(22);
    }
}

@media (max-width: $screen-xs-max ) {
    .p-heading {
        font-size: pxToRem(30);
    }

    .s-heading {
        font-size: pxToRem(20);
    }
}
