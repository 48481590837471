﻿::-ms-clear {
    display: none;
}

.standardInputStyle {
    height: 50px;
    font-size: 1.6rem;
    padding: 14px 18px;
    color: #000;
    border: 1px solid $formInputElementBorderColor;

    @include placeholder {
        color: #999;
        font-size: 1.8rem;
    }
}

.styledSelectStyle {
    height: 50px;
    overflow: hidden;
    background: url('#{$imagePath}icons/icon-chevron-down_blk.svg') no-repeat right 15px top 23px white;
    background-size: 15px 8px;
    border: 1px solid $formInputElementBorderColor;
    border-radius: 0;

    &:focus {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $formInputElementBorderColor;
        outline: none;
    }

    select {
        background: white;
        padding: 5px;
        border: 0;
        height: 50px;
        color: #7f7f7f;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &[disabled="disabled"] {
            background-color: #EBEBE4;
        }

        @media (min-width: $screen-lg-min) {
            background: transparent;
        }
    }
}

.styledCheckboxStyle {
    & {
        display: none;
    }

    & + label {
        padding-left: 36px;
        padding-top: 2px;
        min-height: 26px;
        background: url('#{$imagePath}forms/checkboxUnchecked.png') 0px 0px no-repeat;
    }

    &:checked + label {
        background: url('#{$imagePath}forms/checkboxChecked.png') 0px 0px no-repeat;
    }
}

.styledRadioButtonStyle {
    & {
        display: none;
    }

    & + label {
        padding-right: 35px;
        padding-top: 2px;
        height: 24px;
        background: url('#{$imagePath}forms/radioButtonUnchecked.png') top right no-repeat;
    }

    &:checked + label {
        background: url('#{$imagePath}forms/radioButtonChecked.png') top right no-repeat;
    }
}

input:focus,
input.form-control:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $formInputElementBorderColor;
    border: 2px solid $formInputElementBorderColor;
    outline: none;
}

textarea,
textarea.form-control {
    border: 2px solid $formInputElementBorderColor;
    height: auto;
    padding: 10px;

    &:focus {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $formInputElementBorderColor;
        outline: none;
        border: 2px solid $formInputElementBorderColor;
    }
}

.input-xs {
    width: 80px;

    &.inline {
        display: inline-block;
    }

    &.styled-select {
        padding: 0;

        select {
            width: 105px;
        }
    }
}

.input-sm {
    width: 100px;

    @media(max-width: $screen-xsmd-max) {
        width: 93px;
    }

    &.inline {
        display: inline-block;
    }

    &.styled-select {
        padding: 0;

        select {
            font-size: 1.2rem;
            width: 125px;
        }
    }
}

.input-md {
    width: 205px;

    @media(max-width: $screen-xsmd-max) {
        width: 190px;
    }

    &.inline {
        display: inline-block;
    }

    &.styled-select {
        padding: 0;

        select {
            font-size: 1.2rem;
            width: 230px;
        }
    }
}

.input-lg {
    max-width: 100%;
    width: 307px;


    @media(max-width: $screen-xsmd-max) {
        width: 288px;
    }

    &.inline {
        display: inline-block;
    }

    &.styled-select {
        padding: 0;

        select {
            font-size: 1.2rem;
            width: 332px;
        }
    }
}

.input-xl {
    max-width: 100%;
    width: 407px;
}

// remove default ios input field top shadow
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
}

// overwrite bootstrap default input border
.form-control {
    border-radius: 0;
    box-shadow: none;
    border: 2px solid $formInputElementBorderColor;
    font-size: 1.6rem;
    height: 40px;
    padding: 0 12px;
}

// overwrite ektron error style
.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
    border: 2px solid $orange;
    background: $white;
}

input[type="checkbox"].ui-state-error {
    outline: 2px solid $orange;
}
