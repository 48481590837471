
$wg: ".auth0Lock, .auth0-lock";

#{$wg} {
    /*
    .auth0-lock-header-logo,
    .auth0-lock-name,
    .auth0-lock-header-bg {
        display: none !important;
    }

    .auth0-lock-header {
        height: 110px !important;
    }
    */

    .auth0-lock-submit {
        background-color: $lightGreen !important;
        color: #fff;
        font-size: 2rem !important;
        padding: 0px 13px !important;
        text-shadow: none;
        text-decoration: none;
        border: none;
        border-radius: 0 !important;
        background-image: none;
        text-transform: uppercase;
        min-width: 134px;
        height: auto !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
        width: calc(100% - 40px) !important;

        .auth0-label-submit {
            font-family: 'GT America' !important;
            height: 48px !important;
            line-height: 48px !important;
            font-size: 16px !important;
            text-transform: capitalize;

            .span svg.icon-text {
                margin-top: -6px;
            }
        }
    }

}

