$pg: ".pgTravelStoriesListingsPage";

#{$pg} {

    color: $baseTextColor;

    .headingSection {
        text-align: center;
    }

    .selected-title {
        margin-bottom: 28px;
    }


    .sortDD {
        vertical-align: middle;
        padding: 25px 5px;
        font-size: 16px;
        text-align: right;

        span {
            display: inline-block;
        }

        label {
            display: inline-block;
        }
        select {
            display: inline-block;
            height: 48px;
            min-width: 165px;
        }
    }

    //.pagingWrap {
    //    padding: 40px 0 40px;
    //}

}
