////==================================
//// global calendar theme overwrite
////==================================
//$calendarBG: #D9F6F8;
//$calendarDateDisabled: #0083BE;
//$calendarHeading: $calendarDateDisabled;
//
//.datepicker .datepicker-switch {
//	text-align: center;
//
//	&:hover {
//		background-color: transparent;
//		cursor: default;
//	}
//}
//
//.datepicker .prev:hover,
//.datepicker .next:hover {
//	background-color: transparent;
//	color: $grayDark;
//}
//
//.datepicker table tr th {
//	text-align: center;
//}
//
//.datepicker table tr td {
//	text-align: center;
//	width: 37px;
//
//	&.day {
//		color: $grayDark;
//
//		&:hover,
//		&.focused {
//			background-color: $calendarDateDisabled;
//            color: white;
//		}
//	}
//
//	&.old {
//		color: $grayDark;
//	}
//
//	&.new {
//		color: $grayDark;
//
//		&:hover {
//			color: $grayDark;
//		}
//	}
//
//	&.active,
//	&.active.active,
//	&.selected {
//		background-image: none;
//		background-color: $white;
//		color: $grayDark;
//		text-shadow: none;
//
//		&:hover {
//			background-color: $calendarDateDisabled;
//			color: white;
//		}
//	}
//
//	&.disabled {
//		color: $calendarDateDisabled;
//		cursor: default;
//		position: relative;
//
//		&:hover {
//			background-color: transparent;
//			color: $calendarDateDisabled;
//		}
//
//		&.range,
//		&.range-end {
//			backgruond-color: #c1e580;
//			color: $orange;
//		}
//	}
//
//	&.range-start {
//		background-color: $white;
//		border-radius: 3px 0 0 3px;
//
//		&.range-end {
//			border-radius: 3px;
//		}
//	}
//
//	&.range {
//		background-color: $white;
//	}
//
//	&.range-end {
//		background-color: $white;
//		border-radius: 0 3px 3px 0;
//	}
//}
//
//.datepicker table tr td.today,
//.datepicker table tr td.today:hover,
//.datepicker table tr td.today.disabled,
//.datepicker table tr td.today.disabled:hover {
//	background-color: transparent;
//	background-image: none;
//	color: $white;
//	font-weight: 700;
//
//	&.selected,
//	&.range-start {
//		background-color: $white;
//		color: $grayDark;
//	}
//
//	&:hover {
//		background-color: $calendarDateDisabled;
//	}
//}
//
//.datepicker-dropdown {
//	background-color: $calendarBG;
//    color: $grayDark;
//	padding: 0;
//	min-width: 290px;
//    border-radius: 0;
//
//    &:after {
//    	border-bottom-color: $calendarBG;
//    }
//}
//
////===============================
//// inner selected date displays
////===============================
//.datepicker-dropdown .yb-calendar {
//	border-bottom: 1px solid $calendarDateDisabled;
//	display: block;
//	padding: 15px;
//
//	.c-box {
//		background-color: $white;
//		border-radius: 3px;
//		float: left;
//		margin-left: 9px;
//		width: 95px;
//
//		&:first-child {
//			margin-left: 0;
//		}
//
//		&.nights {
//			width: 75px;
//		}
//	}
//
//	.c-heading {
//		background-color: $grayDark;
//	    border-radius: 3px 3px 0 0;
//		color: $white;
//		padding: 2px 10px;
//		text-align: center;
//	}
//
//	.c-date-wrap {
//		color: $grayLight;
//		font-family: $MuseoSansRounded900RegularFontStack;
//		text-align: center;
//	}
//
//	.c-day-mth {
//	    display: inline-block;
//	    float: left;
//	    font-size: 1.4rem;
//	    line-height: 1.1;
//	    margin-top: 5px;
//	    text-align: right;
//	    text-transform: uppercase;
//	    width: 45%;
//
//	    > span {
//	    	display: block;
//	    	text-align: right;
//	    }
//	}
//
//	.c-date {
//		display: inline-block;
//		float: left;
//		font-size: 3.4rem;
//		line-height: 1.2;
//		width: 55%;
//	}
//
//	.c-date.night {
//		width: 100%;
//	}
//}
//
//.datepicker-dropdown .datepicker-days {
//	padding: 15px;
//	text-align: center;
//}
//
//.datepicker-dropdown table {
//	width: 100%;
//	max-width: 100%;
//}
//
//.datepicker-days {
//    color: $calendarHeading;
//}
//
//@media (max-width: $screen-xsmd-max) {
//
//	.datepicker-dropdown .yb-calendar .c-box {
//		width: 87px;
//
//		&.nights {
//			width: 64px;
//		}
//	}
//}

/* datepicker plugin overwrite */
//.calendar {
//    position: relative;
//}
//.b-calendar__group {
//    width: 100%;
//}
//.b-calendar__label {
//    display: table;
//    position: relative;
//    width: 100%;
//    border-collapse: separate;
//    font-weight: bold;
//    font-family: $FuturaMediumFontStack;
//}
//.b-calendar__input {
//    border: 2px solid #d9d9d9;
//    display: table-cell;
//    position: relative;
//    padding: 10px 44px 10px 15px;
//    height: 48px;
//    width: 100%;
//    font-size: 1.8rem;
//    font-weight: normal;
//    z-index: 2;
//    &:focus {
//        border-color: #d9d9d9;
//        outline: none;
//        box-shadow: 0 0 2px 1px #d9d9d9;
//    }
//}
//.b-calendar__icon {
//    position: absolute;
//    right: 0;
//    top: 0;
//    padding: 0 22px;
//    width: 1%;
//    height: 100%;
//    background: url("../images/Calendar_Icon_REV.svg") no-repeat center;
//    background-color: #00cedb;
//    z-index: 2;
//}
//.b-calendar__btn {
//    margin-bottom: 15px;
//}
.datepicker {
    border-radius: 0;
    &:before {
        right: 14px;
    }
    &:after {
        right: 15px;
    }
    table {
        font-family: $FuturaMediumFontStack;
        thead {
            th {
                font-family: $FuturaRegularFontStack;
                font-weight: bold;
                width: 36px;
                font-size: 14px;
            }
        }
        tr {
            td {
                border-radius: 0;
            }
            td.disabled {
                color: #999;
            }
            td.active.active {
                background: #0f7c67;
            }
        }
    }
    .dow {
        color: #0f7c67;
        font-family: $FuturaRegularFontStack;
        font-weight: bold;
    }

    table tr td.arrSelected,
    table tr td.arrSelected.disabled {
        background-color: $green;
        color: $white;
    }
}

@media (min-width: 1200px) {
    .datepicker {
        padding: 15px;
    }
}
