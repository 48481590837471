$wg: ".paymentCart";

#{$wg} {
    margin-top: 40px;

    strong {
        color: #666;
    }

    &-heading {
        background: $blue;
        padding: 10px 20px;

        h4 {
            color: white;
        }
    }

    &-content {
        padding: 20px 20px 10px;
        background: #f7f2ed;
        color: #40372f;
        min-height: 0;
    }

    &-name {
        border-bottom: 1px dotted #888;
        color: #666;
        font-weight: 700;
        padding-bottom: 10px;
    }

    &-table {
        margin-bottom: 0;
        width: 100%;

        td {
            border-top: 0 none;
            padding: 10px 0;
        }
    }

    &-price {
        font-size: 1.6rem;
        text-align: right;
        width: 120px;

        span {
            display: inline-block;
            line-height: 20px;
            margin-left: -5px;
        }
    }

    &-summary {
        color: #666;
        font-size: 1.6rem;
        font-weight: 700;
        padding: 10px 0;
        text-align: right;
    }

    &-cents {
        font-size: 75%;
        line-height: 20px;
        vertical-align: text-bottom;

    }

    &-totalLabel {
        color: #666;
        line-height: 32px;
        padding: 0;
        text-align: right;
    }

    &-totalInner {
        border-top: 1px dotted #666;
        display: inline-block;
        padding: 20px 0 10px;
        width: 100%;
    }
    &-totalPrice {
        display: inline-block;
        font-size: 3.0rem;
        line-height: 30px;
        font-weight: 700;
        text-align: right;
        text-transform: uppercase;
        vertical-align: middle;
        width: 120px;

        .amount, .dollars, .cents, .currency {
            display: inline-block;
            color: #666;
            float: left;
        }

        .cents {
            font-size: 1.5rem;
            vertical-align: text-top;
            line-height: 20px;
        }

    }
    .convert {
        display: block;
        font-size: 1.4rem;
        font-weight: normal;
    }




}

/* States */

#{$wg} {

}


