
.hostelReview {
    padding: 0 66px;
}

.hostelReview .speechBubble {
    background: $reviewBg;
    padding: 10px 19px 19px;
    position: relative;
    width: 100%;
    font-size: 1.6rem;
}

.hostelReview .speechMarks {
   display: block;
   width: 22px;
   height: 20px;
   margin-bottom: 5px;
}

.hostelReview .speechMarksRight {
    display: none; /* only show on group accommodation review */
    position: absolute;
    right: 20px;
    top: 20px;
}

.hostelReview .speechArrow {
    position: absolute;
    bottom: -18px;
    left: 50px;
}

.hostelReview .ratingBadgeSmall {
    position: absolute;
    right: 12px;
    top: 12px;
}

.hostelReview .reviewDetails {
    margin-top: 16px;

    .nameLocation {
        font-weight: 700;
    }
}

.hostelReview .tooltipWrapper {
    border-bottom: 1px dotted $grayDark;
    padding: 5px 0;
    width: 250px;
    height: auto;

    p {
        cursor: default;
        display: inline;
        margin: 0;
        padding: 0;

        &:last-of-type {
            float: right;
        }
    }

    &:last-of-type {
        border: 0;
    }
}

@media (max-width: $screen-xs-max) {

    .hostelReview .speechBubble {
        background: $reviewBg;
    }
}

@media (max-width: $screen-xsmd-max) {

    .hostelReview .speechBubble {

        .ratingBadgeSmall {
            //left: 0;
            //right: 0;
            //margin: 0 auto;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
        }
    }
}

@media (max-width: $screen-sm-max) {

    .hostel-details .whatTravellersThink {
        margin: 30px 0 20px;
        width: 100%;
        line-height: 1.1;
    }
}
