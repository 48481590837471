
.progress-indicator {
    height: 50px;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%;
}

.progress-indicator li {
    background-color: $grayLightest;
    border: 2px solid $formInputElementBorderColor;
    border-width: 2px 0;
    border-left-color: $grayLightest;
    color: $grayDark;
    font-family: $FuturaBoldCondensedFontStack;
    font-size: 1.6rem;
    float: left;
    height: 50px;
    line-height: normal;
    padding: 13px 0 13px 25px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
 
    &.current {
        color: $white;
        background-color: $lightBlue;
        border-color: $lightBlue;

        + li {
            border-left-color: $lightBlue;

            .start-icon {
                background-position: 2px -1900px;
            }
        } 
    }

    &.first {
        border-left: 2px solid $formInputElementBorderColor;
        padding-left: 5px;
        
        &.current {
            border-left-color: $lightBlue;
        } 
    }

    &.last { 
        border-right: 2px solid $formInputElementBorderColor;

        &.current {
            border-right-color: $lightBlue;
        } 
    }
}

.progress-indicator.three-steps li {
    width: 33.333%;
}

.progress-indicator.four-steps li {
    width: 25%;
}

.start-icon {
    background: transparent url('#{$imagePath}bookings-2.0/sprites.png') no-repeat 0 -1950px;
    display: inline-block;
    height: 50px;
    width: 50px;
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 1;
}

@media(max-width: $screen-xs-max) {
   
   .progress-indicator li {
        text-indent: -9999em;

        &:after {
            content: attr(data-label-short); /* display short name */
            font: normal 16px $FuturaBoldCondensedFontStack;
            margin-left: 27px;
            position: absolute;
            text-indent: 0;
            left: 0;
            right: 0;
            top: 15px;
        }

        &.current:after {
            content: attr(data-label); /* display full name */
        }
    }

   .progress-indicator.three-steps li {
        width: 20%;
        
        &:first-child {
            width: 15%;

            &:after {
                margin-left: 10px;
            }

            &.current {
                width: 56%;
            }
        }

        &.last {
            width: 24%;

            &.current {
                width: 65%;
            }
        }

        &.current {
            width: 61%;
        }
    }
}
