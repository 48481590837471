$wg: ".header";

#{$wg} {
    position: relative;
    z-index: 2;

    .navigation {
        &-inner {
            align-items: center;
            display: flex;
            flex-direction: row;
            position: relative;
            width: 100%;

            > * {
               flex: 0 1 auto;
            }
        }

        &-logo {
            flex: 0 0 124px;
            padding: 31px 30px 26px 0;
            width: 124px;
        }

        &-menu {
            flex: 0 1 auto;
            margin-right: auto;
            width: auto;

            .navAreas {
                display: flex;
                font-size: 16px;
                padding: 0;
                margin: 0;
                list-style: none;
                justify-content: space-between;
                gap: 20px;

                @media screen and (min-width: $screen-sm-max) and (max-width: $screen-md-max) {
                    gap: 12px;
                }

                > li {
                    position: relative;
                    > a {
                        white-space: nowrap;
                    }

                }
            }

            a {
                color: $grayDark;
                text-decoration: none;
            }
        }

        &-search {
            flex: 0 1 60px;
            text-align: center;
            width: 60px;

            a.control {
                cursor: pointer;
            }

            .searchIcon {
                font-size: 20px;
                position: relative;
                z-index: 16;
            }

            .searchFoldout {
                //background-color: $white;
                bottom: inherit;
                padding-left: 120px;
                padding-right: 120px;
                margin-top: -25px;
                top: 50%;

                .keyword {
                    padding-right: 60px;
                }
            }
        }

        &-access {
            align-items: center;
            display: inline-flex;
            flex: 0 0 auto;
            font-size: 16px;
            width: auto;
            justify-content: space-between;
            position: relative;
            gap: 10px;

            .languageSiteLinks {
                display: block;
                padding: 10px 20px;
                //position: absolute;
                right: 0;
                //top: -2px;

                .languageSiteLink{
                    padding: 2px;

                    &:not(.selected) {
                        display: none;
                    }
                }
            }

            a {
                color: $grayLight;
                text-decoration: none;
            }

            .navAccessMenu a {
                color: $grayDark;
            }

            .language-chevron {
                position: absolute;
                top: 18px;
                right: 0px;
                width: 16px;
                height: 14px;
                z-index: 25;
                transition: all 0.2s ease-in-out;
            }
        }

        &-btn {
            margin-right: 8px;

            .btn-bookNow {
                padding: 8px 32px !important;
            }
        }
    }

    .loginLinks,
    .loggedIn {
        padding-left: 10px;
    }

    .glyphicon-user {
        color: $lightGreen !important;
    }
}

/* states */
#{$wg} {
    .languageSiteLinks {

        &:hover {
            background-color: $white;
            box-shadow: 0px 10px 6px -4px rgba(0,0,0,0.5);
            cursor: pointer;

            a {
                text-decoration: none;
            }

            .languageSiteLink {
                display: block !important;
            }

            .language-chevron {
                transform: rotate(180deg);
                transition: all 0.2s ease-in-out;
            }
        }

        img {
            margin-right: 2px;
        }

        .foldout {
            margin-top: 41px;
        }
    }

    .languageSiteLinks:hover {
        cursor: pointer;
        display: flex;
        flex-direction: column;

        .language-chevron {
            transform: rotate(180deg);
            transition: all 0.2s ease-in-out;
        }
    }

    .languageSiteLinks:hover .languageSiteLink.selected {
        order: -1;
        display: block;
    }

    .navAreas {
        li:hover .foldout {
            display: block !important;
        }
    }
}

$findOutMoreLinkColor: #00ADEC;


//$wg: ".header";
//
//#{$wg} {
	.header {

		.modal-dialog {
			padding: 10px;
		}
		button.close:not([data-dismiss="alert"]) {
			right: -15px;
			top: -15px;
		}
	}

	 // START TOP NAV
	.topNav {
		background-color: #ffffff;
		color: $grayLight;
		height: 40px;

		.content {
			text-align: right;
			position: relative;
		}

		.loginLinks, .myBookingsLink {
			display: inline-block;
			margin: 0;
			vertical-align: top;
		}

		.loginLinks {
			> div {
				display: inline-block;
				padding: 9px 25px 9px 20px;

				&.signUpLogin {
					position: relative;
					margin-right: 95px;
				}
			}
		}



		// Disabled as it looks funny
		//.loginLinks .loggedIn {
			//padding-top: 0;
			//padding-bottom: 0;
		//}

		.loginLinks {
			a:hover {
				color: #00CEDA;
			}
		}

		.pen-icon {
			padding: 5px 30px 5px 0;
			width: 32px;
			height: 32px;
			background: transparent url('#{$imagePath}nav/icon-edit.png') center right no-repeat;
		}

		.pen-icon:hover {
			background-image: url('#{$imagePath}nav/icon-edit-hover.png');
		}

		.languageSiteLinks {
			display: inline-block;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 30;
			padding: 9px 25px 9px 12px !important;

			&:hover {
				cursor: pointer;

				.languageSiteLink {
					display: block;
				}

				.language-chevron {
					transform: rotate(180deg);
					transition: all 0.2s ease-in-out;
				}
			}

			img {
				margin-right: 2px;
			}
		}

		.languageSiteLinks:hover {
			cursor: pointer;
			display: flex;
			flex-direction: column;

			.language-chevron {
				transform: rotate(180deg);
				transition: all 0.2s ease-in-out;
			}
		}

		.languageSiteLinks:hover .languageSiteLink.selected {
			order: -1;
			display: block;
		}

		.myBookingsLink {
			position: relative;
			margin-right: 5px;
		}

		.logoutLink {
			position: relative;
			margin-right: 95px;
		}

		.languageSiteLink {
			margin: 8px 3px 0;
			display: none;
		}

		a {
			color: $grayLight;
			text-decoration: none;
		}

		.selected {
			display: block;
			margin: 0 4px;
		}

		.divider {
			display: inline-block;
			background-color: #919191;
			width: 1px;
			height: 100%;
			position: relative;
			top: 0;
			margin: 0 15px;
		}
	}

	.social-nav {
		display: inline-block;
		border-right: 1px solid #919191;
		margin: 0;
		padding-bottom: 4px;

		li {
			display: inline-block;
			margin-right: 15px;
			padding-top: 10px;
		}
	}

	.social-nav-icon {
		display: block;

		.b-icon {
			width: 20px;
			height: 20px;
		}
	}

	// END TOP NAV

	// START MAIN NAV

	.mainNav {
		position: relative;

		.content {
			text-align: right;
		}

		.logo {
			position: absolute;
			top: -39px;
			left: 0px;
			z-index: 250;
		}

		.navAreas {
			list-style-type: none;
			display: inline-block;
			color: #FFF;
			margin-bottom: 0;
			font-weight: 500;
			font-style: normal;
			font-size: 2.0rem;
			color: #FFFFFF;
			padding-left: 0px;

			li {
				border-top-radius: 8px;
				display: inline-block;
				padding: 16px 30px;
				position: relative;

				& > a {
					text-decoration: none;
				}

				@media (min-width:$screen-md-min) and (max-width: $screen-lg-min) {
					padding: 10px 10px;
				}
			}

			li:hover, li.hovered {
				color: $brand-secondary-blue-lagoon;
			}
		}

		.searchIcon {
			position: relative;
			display: inline-block;
			margin-left: 20px;
			z-index: 20;
			font-size: 2.0rem;
		}

		a {
			color: $grayDark;
			text-decoration: none;

			&:hover {
				color: $brand-secondary-blue-lagoon;
			}
		}
	}

	// END MAIN NAV

	// START BREADCRUMBS NAV

	.breadcrumbsNav {
		width: 100%;
		color: $grayLight;
		text-align: left;
		padding: 10px 0px;

		a {
			color: $grayLight;

            &:hover,
            &:active,
            &:focus {
                color: #333;
                text-decoration: none;
            }

            ~ span {
                overflow: hidden;
                text-indent: -20em;
                display: inline-flex;
                width: 30px;
                position: relative;

                &::before,
                &::after {
                    content: "";
                    width: 10px;
                    border-top: 2px solid $lightGreen;
                    position: absolute;
                }
                &::before {
                    transform: rotate(45deg);
                    right: 8px;
                    top: 8px;
                }
                &::after {
                    transform: rotate(-45deg);
                    top: 15.5px;
                    right: 8px;
                }
            }
		}
	}

	// END BREADCRUMBS NAV

	// START MY BOOKING HOVER


	.myBookingsLink.hovered .myBookingsHover {
		display: block;
	}

	.myBookingsHover {
		display: none;
		width: 270px;
		position: absolute;
		top: 55px;
		left: -90px;
		background: #FFF;
		z-index: 20;
		padding: 20px;
		text-align: left;
		color: #464646;

		.heading span {
			font-size: 2.4rem;
			vertical-align: bottom;
		}

		label .required {
			display: none;
		}

		input {
			//@extend .standardInputStyle;  // Todo fix this reference
			width: 100%;
			margin-top: 10px;
		}
	}

	.myBookingsHover:after {
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: #ffffff;
		border-width: 10px;
		margin-left: -10px;
	}

	// END MY BOOKING HOVER



	// START NAV FOLDOUT
    .navAccessFoldouts,
	.mainNavFoldouts {
		position: absolute;
		left: 50%;
		z-index: 20;
		transform: translate(-50%, 0);

		.foldout {
			display: none;
			padding: 20px 52px;
			width: auto;
			color: $grayDark;
			margin-top: 27px;
			background: white;
			width: 309px;
			box-shadow: 5px 5px 10px rgba(0,0,0,0.2);

			// triangle
			&::before {
				display: block;
				margin: 0 auto;
				content: '';
				width: 0;
				height: 0;
				border-left: 11px solid transparent;
				border-right: 11px solid transparent;
				border-top: 11px solid white;
				position: absolute;
				top: 16px;
				left: 50%;
				transform: translate(-50%, 0);
			}

			.row {
				overflow: hidden;
			}

			.navYoutubeEmbed {
				padding-right: 15px;
			}

			a {
				border-bottom: 0;
				padding: 0;
			}

			ul {
				margin: 0;
				padding: 0;
			}

			li {
				display: block;
				text-align: left;
				padding: 0;
				font-size: 16px;
				margin-bottom: 28px;

				&:last-child {
					margin: 0;
				}
			}
		}
	}

    .navAccessFoldouts {
        white-space: nowrap;
        .loginLinks & {
            left: 0;
        }
        .foldout {
            width: auto;
            &::before {
                display: none;
            }
        }
        :hover > & .foldout {
            display: block !important;
        }
    }


	.embeded-thumb-wrap {
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 40px;
		height: 0;

		@media (min-width: $screen-md-min) and (max-width: $screen-md-max ) {
			top: -20px;
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.yt-thumb {
		display: none;
	}

	.yt-btn-play {
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -43px;
		margin-top: -30px;
		width: 85px;
		height: 60px;
		z-index: 1;
		transform: scale(0.5);
	}

	.yt-btn-play svg {
		height: 100%;
		width: 100%;
	}

	.yt-thumb:hover .yt-play-btn-svg {
		fill: #cc181e;
		opacity: 1;
	}

	.mainNavFoldouts .yt-thumb {
		display: block;
		cursor: pointer;
		z-index: 101;
		position: absolute;
		top: 0;
		width: 100%;
		max-width: 100%;
		clip: rect(23px, 248px, 163px, 0px); /* croping out black area(4:3 ratio to 16:9) */
		@media (min-width: $screen-md-min) and (max-width: $screen-md-max ) {
			top: 25px;
			clip: auto;
		}
	}

	.mainNavFoldouts .foldout {
		.findOutMore {
			a {
				color: $findOutMoreLinkColor;
			}

			img {
				margin-left: 10px;
			}
		}

		.column3 a {
			color: #FF9900;
		}
	}

	.mainNavFoldouts .youtubeVideo iframe {
		margin-top: 24px;
		width: 100%;
		height: auto;
	}

	.australianHostelsFoldout {
		.searchmap {
			.searchmap-image img, .searchTravelmap-image img {
				width: 100%;
				height: auto;
			}
		}

		.mapDescription {
			vertical-align: top;

			p {
				font-weight: bold;
			}
		}

		.mapSearch {
			margin-top: 30px;

			.field-group {
				display: inline-block;
				float: left;
			}

			input {
				border: 0 none;
				color: #000;
				height: 38px;
				padding: 7px;
				width: 160px;
				border-radius: 6px 0px 0px 6px;
			}

			a {
				float: left;
				vertical-align: top;
				background-color: $searchbarWithMagnifyingGlassBackgroundTop; // IE8 fallback
				background-image: linear-gradient($searchbarWithMagnifyingGlassBackgroundTop, $searchbarWithMagnifyingGlassBackgroundBottom);
				border-radius: 0px 6px 6px 0px;
			}

			.btn {
				height: 38px;
				padding: 8px 12px;
			}
		}
	}

	.travelAdventuresFoldout {
		.searchmap {
			.searchmap-image img, .searchTravelmap-image img {
				width: 100%;
				height: auto;
			}
		}

		p {
			font-weight: bold;
		}

		.column3 {
			img {
				width: 100%;
				height: auto;
			}

			div {
				margin-top: 10px;
			}
		}
	}

	.membershipFoldout {
		.column1 {
			.mainImage {
				width: 100%;
				height: auto;
			}

			.btn-join {
				width: 100%;
				display: block;
				margin-top: 20px;
			}
		}

		p {
			font-weight: bold;
		}
	}

	.groupsFoldout {
		.column1 {
			.mainImage {
				width: 100%;
				height: auto;
			}
		}

		p {
			font-weight: bold;
		}
	}

	.aboutYHAfoldout {
		.column1 {
			.mainImage {
				width: 100%;
				height: auto;
			}
		}

		p {
			font-weight: bold;
		}

		.sustainableMessage {
			position: relative;
			bottom: 7px;
			left: 8px;

			a, .chevron {
				margin-left: 10px;
			}

			a {
				color: $findOutMoreLinkColor;
			}
		}
	}

	img.map, map area {
		outline: none;
	}


	// END NAV FOLDOUT

	// START Nav Youtube Embed

	.navYoutubeEmbed {
		p {
			font-weight: bold;
		}
		a.videoLink {
			color: #FF9900;
			display: inline-block;
			padding-top: 5px;
		}
	}

	// END Nav Youtube Embed


	// START MOBILE HEADER

	.mobileHeader {
		height: $mobileHeaderHeight;
		position: relative;
	}

	.mobileHeader .yhaLogo {
		position: absolute;
		top: 50%;
		left: 100px;
		transform: translate(-50%, -50%);
        z-index: 10;

        .imageLinkBlock-link {
            display: block;
            height: $mobileHeaderHeight;
            padding: 2px 0;
        }

		.imageLinkBlock-img {
		 	height: 100%;
		}
	}

	.mobileHeader .user {
		float: right;
		margin: 20px;
		position: relative;
		transition: all 0.25s ease-in-out;
        font-size: 3rem;
        color: $lightGreen;

		img {
			width: 30px;
			transition: all 0.65s ease-in-out;
		}

		span {
			opacity: 0;
			border-radius: 9px;
			transform: rotate(0deg);
			transition: all 0.25s ease-in-out;
			background-color: $white;
			display: block;
			margin-left: -3px;
			height: 30px;
			width: 5px;
			position: absolute;
			top: 0;
			left: 50%;
		}

		&.open {

			img {
				transition: all 0.25s ease-in-out;
				transform: scaleX(0);
			}

			span {
				opacity: 1;
				transition: all 0.5s ease-in-out;

				&:nth-child(1) {
					transform: rotate(45deg);
				}

				&:nth-child(2) {
					transform: rotate(-45deg);
				}
			}
		}
	}

	.mobileHeader .user-login {
		float: right;
		margin: 0 20px;
        transform: translateY(3px);
        height: $mobileHeaderHeight;
        display: flex;
        align-items: center;

		a {
			font-size: 1.8rem;
			color: $grayDark;
		}
	}

	.mobileHeader .user-option {
		display: none;
		position: absolute;
		right: -240px;
		width: 240px;

		ul {
			list-style: none;
			padding-left: 0;
		}

		li {
            border-bottom: 1px solid #d8d8d8;
            padding: 16px 20px;
		}

		a {
			color: $grayDark;
			font-weight: normal;

			&:hover {
				text-decoration: none;
			}
		}
    }

    .mobileHeader-searchWrap {
        float: right;
        display: flex;
        align-items: center;
        height: $mobileHeaderHeight;

        .searchIcon {
            font-size: 2.5rem;
            transform: translateY(8px);

            .control {
                color: $lightGreen;

                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                }

                &.open .glyphicon-search {
                    display: none;
                }

                .glyphicon-remove {
                    display: none;
                }

                &.open .glyphicon-remove {
                    display: inline-block;
                }
            }
        }

        .searchFoldout {
            top: $mobileHeaderHeight;
            width: 100%;
            height: auto;
            transition: all .3s ease-in-out;
            z-index: -1;

            &.open {
                padding-left: 15px;
                padding-right: 15px;
                z-index: 15;
            }
        }

        .yhaSearchBar {
            margin-bottom: 0;
        }
    }

	#navHamburger {
		cursor: pointer;
		float: left;
		width: 26px;
		height: 26px;
		position: relative;
		margin: 21px 13px 17px;
		overflow: hidden;
		transform: rotate(0deg);
		transition: all 0.5s ease-in-out;

		&:focus {
			outline: none;
		}
	}

	#navHamburger span {
		background-color: $grayLight;
		display: block;
		position: absolute;
		left: 0;
		height: 4px;
		width: 100%;
		border-radius: 9px;
		opacity: 1;
		transform: rotate(0deg);
		transition: all 0.25s ease-in-out;

		&:nth-child(1) {
			top: 0;
		}

		&:nth-child(2) {
			top: 10px;
		}

		&:nth-child(3) {
			top: 19px;
		}
	}

	#navHamburger.open span:nth-child(1) {
		top: 10px;
		transform: rotate(135deg);
	}

	#navHamburger.open span:nth-child(2) {
		opacity: 0;
		left: -60px;
	}

	#navHamburger.open span:nth-child(3) {
		top: 10px;
		transform: rotate(-135deg);
	}


	// END MOBILE HEADER


	// START MOBILE NAV

	body {
		overflow-x: hidden;
	}

	.mobileNav {
		width: 240px;
		position: absolute;
		left: -240px;
		color: $grayDark;
	}

	.mobileNav .sideNav-heading {
		color: $grayDark;
		font-size: 1.8rem;
		font-weight: normal;
		margin: 0;
	}

	.mobileNav .yhaCompactor {
		border-bottom: 1px solid $mobileNavOpenCompactorBackgroundColor;
		transition: background-color 0.5s ease-in-out;

		.trigger {
			padding: 16px 20px;
			position: relative;
		}

		.openIndicator {
			background-image: url('#{$imagePath}icons/Mobile_NAV_chevron.svg');
			background-size: 16px 10px;
			height: 10px;
			width: 16px;
			position: absolute;
			right: 16px;
			top: 21px;
			transition: all 0.2s ease-in-out;
		}
	}

	.mobileNav .yhaCompactor .content {
		display: block;
		max-height: 0;
		height: 0;
		overflow: hidden;
		transition: all 0.3s ease-in-out;

		.inner-wrap {
			padding: 4px 20px 20px;
			font-size: 1.4rem;
		}

		a {
			color: $grayDark;
			padding-left: 16px;
			position: relative;
			display: block;

			&::before {
				content: '';
				background: url('#{$imagePath}icons/mobile_NAV_chevron_right.svg') no-repeat 100%;
				width: 10px;
				height: 7px;
				display: block;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		ul {
			margin: 0;
			padding: 0;
		}

		li {
			margin-bottom: 10px;
			list-style-type: none;
		}
	}

	.mobileNav .yellowChevronList {
		padding-bottom: 4px;

		li {
			background-size: 6px;
			background-position: 0 5px;
			margin-bottom: 9px;
			padding-left: 12px;
		}

		a {
			color: $white;
		}

		.italic {
			font-size: 1.0rem;
		}
	}


	.mobileNav {

		.searchmap-image img,
		.searchTravelmap-image img {
			width: 100%;
			height: auto;
		}

		.mapDescription {
			margin-top: 14px;
			vertical-align: top;

			p {
				font-size: 1.0rem;
				line-height: 1.55;
			}
		}

		.mapSearch {
			display: inline-block;
			margin-top: 14px;

			.field-group {
				float: left;
				width: 178px
			}

			input {
				border-radius: 3px 0px 0px 3px;
				border: 0 none;
				color: #000;
				font-size: 1.2rem;
				padding: 7px;
				width: 100%;
				height: 41px;
			}

			a {
				background-image: linear-gradient($searchbarWithMagnifyingGlassBackgroundTop, $searchbarWithMagnifyingGlassBackgroundBottom);
				border-radius: 0px 3px 3px 0px;
				background-color: $searchbarWithMagnifyingGlassBackgroundTop; // IE8 fallback
				float: left;
				height: 41px;
				width: 38px;
			}

			.btn {
				color: $white;
				padding: 10px 11px 9px 11px;
			}
		}
	}


	.mobileNav .membership {
		.mainImage {
			height: auto;
			width: 100%;
		}

		.btn-default{
			display: block;
			font-size: 1.8rem;
			margin-top: 14px;
			width: 100%;
		}
	}


	.mobileNav .groups {

		.mainImage, img {
			margin-bottom: 10px;
			max-width: 100%;
		}
	}


	.mobileNav .aboutYha {

		.mainImage, img {
			max-width: 100%;
		}

		.sustainableMessage {
			position: relative;
			bottom: 6px;
		}
	}

	.mobileNav .yhaCompactor.open {
		transition: background-color 0.3s ease-in-out;

		h1:after {
			content: "";
			background-color: $navbarSelectedItemUnderlineColor;
			height: 2px;
			width: 75%;
			position: absolute;
			bottom: 3px;
			left: 10px;
		}

		.openIndicator {
			transform: rotate(180deg);
			transition: all 0.2s ease-in-out;
		}

		.content {
			max-height: 1800px;
			height: 100%;
			transition: all 0.5s ease-in-out;
		}
	}

	.mobileNav .yt-thumb {
		display: block;
		position: relative;
		top: -40px;
	}

	.mobileNav .mybooking label {
		font-size: 1.4rem;
	}

	.mobileNav .mybooking .btn {
		font-size: 1.8rem;
		margin-bottom: 10px;
		padding: 8px 0;
	}

	.mobileNav .static-link {
		display: inline-block;
		margin: 10px 0 20px;
	}

	.mobileNav .findBooking-error {
		color: $orangeDark;
		margin-bottom: 20px;
	}


    .mobileNav .language-nav {
        .trigger {
            .languageSiteLink {
                display: block;

                &.selected {
                    display: block;
                }
            }
        }

        .content {
            a {
                font-size: 1.8rem;
                padding: 10px 0;

                &::before {
                    display: none;
                }
            }

            .selected {
                display: none;
            }
        }

        .flag {
            border: 1px solid $grayLightest;
        }
    }

    .mobileNav .btn-bookNow {
        display: block;
        font-size: 18px !important;
        letter-spacing: 1px;
    }

	// END MOBILE NAV



//}

//Search Foldouts

.searchFoldout {
    position: absolute;
    top: 5px;
    right: 0;
    z-index: -2;
    height: 0;
    width: 0;
    transition: width 0.3s ease-in-out;
    padding: 0;
    opacity: 0;

    .is-searchfoldout-open & {
        height: auto;
    }

    &.open {
        height: auto;
		opacity: 1;
        width: 100%;
		//padding-left: 200px;
        z-index: 15;
    }
}

// States
// Show hide header menus
@media (min-width: $screen-md-min) and (max-width: $screen-lg-min ) {
    .mobileNav, .mobileHeader {
        display: none;
    }
    .topNav, .mainNav, .breadcrumbs {
        display: block;
    }
}
@media (min-width: $screen-lg-min ) {
    .mobileNav, .mobileHeader {
        display: none;
    }
    .topNav, .mainNav {
		display: block;
	}

	.languageSiteLink{
		display: inline-block;
	}
}
@media (max-width: $screen-sm-max ) {
    .navigation-main {
        display: none;
    }
}
@media (min-width: $screen-xxs) and (max-width: $screen-sm-max ) {
    .mobileNav, .mobileHeader {
        display: block;
    }
    .topNav, .mainNav, .breadcrumbs {
        display: none;
    }
}

