$par: ".myYhaSidebarNav";

#{$par} {
    .navSecondary {
        margin-top: 30px;
        list-style-type: none;
        padding: 0;
        margin-bottom: 0;

        li {
            background-image: url('#{$imagePath}lists/chevron-thin-right-green.png');
            background-repeat: no-repeat;
            background-position: 10px center;
            background-size: 14px auto;
            font-size: 1.6rem;
            border-bottom: 2px solid #fff;
            padding: 10px 30px;

            a {
                color: #666!important;
                text-decoration: none;
            }
        }
    }
}

/* States */

#{$par} {
    @media (max-width: $screen-sm-max) {
        display: none;
    }

}


