
$blk: ".shareYourStoryBlock";

#{$blk} {
    background: #f0f0ee;

    &-content {
        border-bottom: 2px solid #fff;
        padding: 10px 20px 20px;
    }

    &-btn {
        margin: 10px 0;
        width: 100%;
    }

}

