
$blk: ".epackImageBlock";

#{$blk} {
    position: relative;
    padding: 15px;

    &-item-image {
        width: 100%;
        height: auto;
    }

    &-desc {
        padding: 30px 0;
    }

    &-total,
    &-validity {
        font-size: 18px;
        font-weight: bold;
    }

    &-btn {
        margin: 10px auto 15px;
        display: block;
        width: 150px;
    }
}


/* States */


#{$blk} {
    @media (min-width: 640px) and (max-width: 767px) {
            width: 50% !important;
    }

    @media (max-width: $screen-xs-max) {
        &-btn {
            margin-bottom: 0;
        }
        &-desc {
            padding-top: 20px;
            padding-bottom: 0;
        }
    }
}
