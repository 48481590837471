.video-container {
  position: relative;
  padding-bottom: 56.25%;
  //padding-top: 30px;
  overflow: hidden;
  height: 100%;

  &.video-item-iframe {
    z-index: 10;
  }
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}