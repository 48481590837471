$wg: ".shareYourStoryForm";

#{$wg} {

    background-color: $white;
    color: $grayDark;
    padding: 15px 20px;
    position: relative;
    min-height: 1200px;

    &-tellUsYourStory {
        position: absolute;
        width: 700px;
        max-width: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    &-yourStory {
        background-color: $grayLightest;
        margin-bottom: 10px;
        padding: 10px 20px;
    }

    &-yourStoryText {
        font-size: 1.2rem;
        height: 230px;
    }
    &-textarea {
        display: block;
        line-height: 20px;
        height: 200px;
        width: 100%;
        padding: 10px;
        resize: none;
    }
    &-charCount {
        font-size: 1.4rem;
        color: $gray;
        line-height: normal;
        margin-top: 10px;
        padding-right: 2px;
        text-align: right;
    }
    &-attachFiles {
        background-color: $grayLightest;
        display: inline-block;
        margin-bottom: 10px;
        padding: 10px 20px;
        width: 100%;
    }
    &-uploadedFiles {
        border-top: 1px dotted $grayLight;
        padding-bottom: 10px;
        padding-top: 15px;

        .noFiles {
            display: none;
            float: left;
        }

        &.empty {

            .files {
                display: none;
            }

            .noFiles {
                display: block;
            }
        }
    }

    &-files {
        float: left;
        margin-bottom: 0;
        list-style: none;
        padding-left: 0;
        max-width: 460px;

        li {
            background-color: $white;
            border: 1px solid $formInputElementBorderColor;
            float: left;
            font-size: 1.2rem;
            height: 28px;
            line-height: 26px;
            margin-bottom: 10px;
            margin-right: 10px;
            padding: 0 10px;
        }

        .closeButton {
            display: inline-block;
            margin-left: 10px;
            height: 12px;
            width: 12px;
            vertical-align: text-top;

            &:hover {
                text-decoration: none;

                &:before {
                    color: $orangeDark;
                }
            }
        }
    }

    &-input-wrapper {
        float: right;
        position: relative;

        .fileInputOverlay {
            font-size: 1.8rem;
            padding: 5px 18px;
            z-index: 2;
        }

        .storyFileUpload {
            opacity: 0;
            cursor: pointer;
            display: block;
            height: 35px;
            width: 120px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;

            &.selected {
                z-index: 1;
            }
        }
    }
    &-yourDetails {
        background-color: $grayLightest;
        margin-bottom: 10px;
        padding: 10px 20px;
    }

    &-form {
        border-top: 1px dotted $grayLight;
        padding-bottom: 10px;
        padding-top: 15px;

        label {
            display: block;

            &.field-required {
                color: inherit;
                font-weight: inherit;
                font-size: inherit;
                line-height: inherit;
                padding-left: inherit;
                vertical-align: inherit;

                &:after {
                    content: '*';
                    color: #00a5e3;
                    font-weight: bold;
                    font-size: 1.8rem;
                    line-height: 14px;
                    padding-left: 5px;
                    vertical-align: middle;
                }
            }
        }

        .form-group {
            margin-bottom: 10px;
        }

        .form-control {
            width: 100%;
        }
    }
    &-country {
        border: 2px solid $formInputElementBorderColor;
        height: 40px;
        margin-bottom: 0;
        padding: 5px 10px;
        width: 100%;

        &.ui-state-error {
            border: 2px solid $orange;
        }
    }

    &-tnc {
        background-color: $grayLightest;
        margin-bottom: 10px;
        padding: 10px 20px;

        input[type="checkbox"] {
            margin-right: 5px;
        }

        a {
            color: $blueDark;
            font-size: 1.2rem;
            text-decoration: underline;
        }

        .TCText {
            transition: height .2s ease-in;
            font-size: 1.4rem;
            height: 0;
            overflow: hidden;

            &.open {
                transition: height .2s ease-in;
                background-color: $white;
                height: 80px;
                margin-bottom: 10px;
            }

            .termsAndConditionsBlock {
                margin-top: 0;
                padding-top: 0;
            }
        }

    }

    &-formOptions {
        margin: 30px 0 20px;

        @media (max-width: 480px) {
            .cancel {
                margin-top: 10px;
            }
        }
    }



}

/* States */

#{$wg} {

    @media (max-width: $screen-xs-max) {
        min-height: 1400px;
    }


}
