$par: ".newsPagePartial";

#{$par} {

    .newsListItem {
        height: 460px;
        margin-bottom: 40px;
        padding: 0;
        position: relative;
    }

    .mainImageContainer {
        height: 166px;
        max-height: 166px;
        overflow: hidden;
    }

    &-contBtn {
        padding: 8px 18px;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

/* States */

#{$par} {

}

