
$pg: ".pgDiscountListingPage";

#{$pg} {

    color: $baseTextColor;

    .internationalDiscountsButton {
        text-align: center;
        margin: 60px 0 40px;
    }

}
