//@import "../../../glb/scss/variables";
//@import "../../../glb/scss/mixins/mx-selectors";

$pg: ".pgMembershipLandingPage";

#{$pg} {

    color: $baseTextColor;

    .headingSection {
        margin-bottom: 20px;
    }

    .introBtn {
        min-width: 300px;
        margin: 25px auto;
    }

    .whyJoinYha {

        .main-img img {
            max-width: 100%;
            width: 100%;
        }


        .message {
            display: inline-block;
            padding: 0 20px 30px;
            width: 100%;

            &List {
                @extend .yellowTickList;
                font-size: 1.5rem;
            }
        }
    }

    .promoItemFeature {
        padding-top: 80px;
    }

    .fullWidthBanner {

        .bannerImage {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }

    .featuredDiscounts {
        margin-top: 40px;
    }

}
