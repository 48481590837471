
$blk: ".priceOptionItemBlock";

#{$blk} {


    &-cell {
        text-align: left;
        background: #f8f8f7;
        padding: 10px;
    }

    &-bookingBtn {
        margin-top: 25px;
    }



}


/* States */


//#{$blk} {
//
//}
//
