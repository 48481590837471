﻿.wysiwygContent {
	color: $baseTextColor;

	p {
		margin-bottom: 30px;

		&:first-child {
			margin-bottom: 19px;
		}
	}

	ul {
		@extend .blueSquareList;
		margin-bottom: 20px;
	}

    img {
        max-width: 100%;
    }
}
