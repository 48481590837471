$par: ".hostelDetailsPage";

#{$par} {
    &-hostelname {
        color: #464646;
        font-family: $FuturaBoldCondensedFontStack;
        font-weight: bold;
        font-size: 2rem;
        margin-top: 10px 0 6px 0;
        text-align: center;
        width: 100%;
    }

    &-location {
        text-align: center;
        color: #9f9f9f;
        margin-bottom: 10px;
    }

    &-image-container {
        min-height: 175px;
        position: relative;
        background: #e3e3e3;
    }

    &-image {
        height: auto;
        width: 100%;
    }

    &-wishList {
        text-align: center;
    }

    .wishList {
        .button-like {
            padding: 0px;
        }
    }

    .yhaFlag {
        position: absolute;
        top: 15px;
        right: 30px;
    }
    &-view-details {
        padding-left: 50%;
        transform: translateX(-81px);
        padding-top: 15px;
    }

    &-view-details-btn {
        padding-left: 30px;
        padding-right: 30px;
    }

    &-costs {
        text-align: center;
        padding: 10px 0;
        color: #9f9f9f;

    }
}

/* States */

#{$par} {

}



.hostelDescription p {
    margin-bottom: 30px;
}


