
$wg: ".filterTabs";

#{$wg} {

    background: #fff;
    padding: 35px 0;
    position: relative;

    &-tab {
        border: none;
        border-bottom: 3px solid transparent;
    }

    &-tab-panes {
        display: none;
        width: 100%;
        position: relative;
        padding: 20px;
        margin-top: 20px;
    }

    &-cityCb {
        background-color: transparent;
        color: #000;
        padding: 8px;
        font-weight: normal;
    }

    &-btnControls {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 33px;
    }

    &-resetBtn {
        margin-right: 12px;
    }

    &-tab {
        margin-left: 3px;
        margin-right: 3px;
    }

    .tabs {
        background-color: #fff;
        padding: 0;
        z-index: 1;
    }

    .tab {
        display: inline-block;
        text-align: center;
        background-color: transparent;
        color: #000;
        font-size: pxToRem(18);
        font-weight: 500;
    }

    .tabs-wrap {
        padding: 0;
        float: none;
    }

    .is-nested-options {
        position: relative;
        column-gap: 24px;
    }

    &-nested {
        display: inline-block;
        border-top: 1px solid #CACACA;
        margin:  0.25rem;
        padding:  28px 10px;
        width:  100%;
    }

}

// States
#{$wg} {

    &-tab {
        position: relative;

        &.active {
            border-bottom-color: $lightGreen;

            a .tab {
                color: $lightGreen;
            }
        }
        &.open {
            border-bottom-color: $lightGreen;
        }
        &:hover {
            text-decoration: underline;
        }
    }


    &-tab-panes {

        &.open {
            display: block;
        }
    }


    &-cityCol {

        &.active {
            background-color: #ffffff;
            box-shadow:0 0 0 5px #F0F0EE inset;
        }
    }
    &-mbToggleFilters {
        display: none;
        text-align: center;
    }
    &-toggleFilterBtn {
        width: 200px;
        margin-top: 10px;
    }

    @media (max-width: $screen-sm-max) {
        display:block;
        margin: 0;
        padding: 10px 0;

        .row {
            margin: 0;
        }

        .tab {
            background: #ffffff;
            display: inline-block;
            height: 48px;
            text-align: left;
            width: 100%;
            padding: 1px 12px;

            img {
                margin: 5px;
                width: 40px;

                &.inactive {
                    display: inline-block;
                }

                &.active {
                    display: none;
                }
            }

            &.active {
                height: 50px;

                img {

                    &.inactive {
                        display: none;
                    }

                    &.active {
                        display: inline-block;
                    }
                }
            }
            &.open {
                border-bottom: none;
            }
        }

        .selected-label {

            .tab.active {
                height: 48px;
            }

            .tab {

                img.active {
                    display: inline-block;
                }
            }
        }

        .tabs {
            background-color: #fff;
            display: inline-block;
            margin-bottom: 30px;
            width: 100%;
            padding: 30px 0 0;

            > a {
                width: 100%;
            }

            ul li {
                &:first-child .tab,
                &:last-child .tab {
                    width: 100%;
                    border-radius: 0;
                }
            }
        }

        &-tab-panes {
            max-height: 300px;
            overflow-y: scroll;
        }

        .tabs-wrap {
            background-color: $white;
            display: inline-block;
            width: 100%;
        }
        &-mbToggleFilters {
            display: block;
        }


    }



    @media (min-width: $screen-md-min) {

        .tabs ul {
            border-bottom: 1px solid $gray-lighter;
            display: flex !important;
            opacity: 1 !important;
            align-items: center;
            justify-content: space-between;

            li {
                &.active {
                    display: block !important;

                    .tab {
                        display: inline-block !important;

                    }
                }
            }
        }

    }

    @media (max-width: $screen-md-max) {

        .is-nested-options {
            column-width: 250px;
        }
    }
    @media (min-width: $screen-lg-min) {

        .is-nested-options {
            column-width: 300px;
        }
    }


    // Mods

    &--dropdownArrow {

        #{$wg}-tab {
            min-width: 280px;

            &:after {
                content: '';
                position: absolute;
                right: 30px;
                top: 30px;
                //background-image: url('#{$imagePath}icons/icon-chevron-down_blk.svg');
                width: 20px;
                height: 20px;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &.open {
                &:after {
                    top: 20px;
                    transform: scaleY(-1);
                }
            }

            @media (max-width: $screen-sm-max) {
                &:after {
                    display: none;
                }
            }

        }

    }


}


