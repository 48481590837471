
$pg: ".pgHsDtPg";

#{$pg} {

    .detailsTabIntroduction {
        @media(min-width: $screen-md-min) {
            margin-top: 40px;
        }
        @media (min-width: $screen-xsmd-max) and (max-width: $screen-sm-max) {
            margin-top: 32px;
        }
        
        @media(max-width: $screen-xsmd-max) {
            margin-top: 24px;
        }
    }

    .hostelLists {
        margin: 40px 0;
    }

    .hostelDirectionsItem {
        margin-bottom: 30px;
    }

    .googleMap {
        margin-bottom: 50px;
    }

    .imageCaption {
        display: block;
        background: #f0f0ee;
        padding: 0px 10px;
        position: relative;
        top: 0px;

        .wishList {
            .button-like{
                padding: 1.5rem 0.5rem;
                float:right;
            }

            .text {
                margin-left: 0.1rem;
                font-size: 1.3rem;
            }
        }
    }

    .hostelDirections {
        .hostelDirectionsItem {
            .image {
                display: inline-block;
                width: 100px;
                vertical-align: top;
                margin-right: 20px;

                img {
                    width: 100%;
                }
            }

            .content {
                display: inline-block;
                width: 500px;

                @media(max-width: $screen-xsmd-max) {
                    display: block;
                    width: 90%;
                    margin-top: 10px; 
                }
            }

            a .pdfSize {
                color: #888;
            }
        }
    }

    .hostelAttractions {
        margin-bottom: 50px;

        .content {
            border: 1px solid #F3F3F1;
            border-top: none;
            padding: 20px;
        }
        //
        //ul {
        //    @extend .blueSquareList;
        //}
    }

}

#{$pg} {

    @media (max-width: $screen-xs-max){
        .imageCaption {
            top:-5px;

            .wishList {
                .button-like {
                    float:left;
                }
            }
        }   
    }

    @media (min-width: $screen-xssm-min) and (max-width: $screen-xssm-max){
        .imageCaption {
            top:-35px;
        }   
    }
}
