//@import "../../../glb/scss/variables";
//@import "../../../glb/scss/mixins/mx-selectors";

$pg: ".pgHsLsPg";

#{$pg} {


    .hostelTypeKeys {
        border-bottom: 2px solid #f0f0ee;
        border-top: 2px solid #f0f0ee;
        margin-bottom: 40px;
    }

    .badgeItem-description {
        p {
            color: $gray;
        }
    }

    .promoItemFeature {
        padding-bottom: 40px;
    }

    .map {
        min-height: 555px;
    }

    .details {
        background-color: $grayLightest;
        padding: 15px 20px 0 20px;
        position: relative;
        margin-left: -15px;
    }

}

// States

#{$pg} {

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
        .map {
            min-height: 460px;
        }
    }

    @media (max-width: $screen-xs-max){
        .map {
            display: none;
        }
    }

}
