
$blk: ".bannerImageSliderBlock";

#{$blk} {

    margin-bottom: 40px;

    &-frame {
        width: 100%;
        position: relative;
        font-size: 0;
        line-height: 0;
        overflow: hidden;
        white-space: nowrap;
    }

    &-slides {
        display: inline-block;
        padding-left: 0 ;

        .js_slide {
            position: relative;
            display: inline-block;
            width: 100%;
        }
    }

    &-image {
        max-width: 100%;
        width: 100%;
        max-height: 522px;
    }
}

//States

#{$blk} {

    &-image {

        @media (max-width: $screen-xsmd-max) {
            max-height: 182px;
        }
        @media (max-width: $screen-xs-max) {
            max-height: 334px;
        }
    }

}
