$pg: ".pgUserWishListPage";

#{$pg} {
    color: $baseTextColor;
    .page-heading {
        font-family: $FuturaBoldCondensedFontStack;
        font-size: 2.4rem;
        margin-top: 25px;
    }

    .user-wishList-item {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px dotted $gray;

        .hostelImageContainer {
            min-height: 175px;
            position: relative;
        }

        .hostelImage {
            width: 100%;
        }
    }

    .btn-toolbar {
        display: flex;

        .btn {
            min-width: auto;
            flex-grow: 1;
            text-align: center;
            margin-right: 20px;
        }

        .btn:last-child{
            margin-right: 0;
        }

        justify-content: space-between !important;
    }

    .btn {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .sort-buttons {
        padding: 1.2rem 0;
        text-align: center;
    }

    .btnMoveDown,
    .btnMoveUp {
        outline: none;
        background: transparent;
        border: 0;
        padding: 0;

        .icon {
            color: $lightGreen;
            font-size: 18px;
            vertical-align: middle;
            transform: translateY(-2px);
        }
    }

    .btnMoveDown {
        margin-left: 10px;
    }

    .first .btnMoveUp {
        display: none;
    }

    .last .btnMoveDown  {
        display: none;
    }
}

/* STATES */
#{$pg} {
    @media (max-width: $screen-xsmd-max) {
        .btn-toolbar {
            .btn {
                min-width: auto;
                font-size: 1.5rem;
                margin-right: 0;
            }
        }

        .sort-buttons {
            text-align: left;
            padding: 1.2rem 1.5rem;
        }

        .btnMoveDown {
            position: relative;
            left: 0px;
        }
    }
}
