$pg: ".pgsearchresults";

#{$pg} {

    color: $baseTextColor;
    background-color: $grayLighter;

    .pgschrs-container {
        padding-top: 60px;
        padding-bottom: 70px;
    }

    .pgschrs-resultmsg {
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 1.6rem;
        color: #000;
    }
    .pgschrs-list {
        margin-top: 40px;
        background-color: #fff;
        padding: 10px 45px;
    }

    .pgschrs-listitem {
        border-bottom: 1px solid #e4e4e4;
        &:last-child{
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }


    .pagination {
        width: 100%;
    }


    // States


}
