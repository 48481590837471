$pg: ".pgGroupsQuoteProfilePage";

#{$pg} {
    color: $baseTextColor;

    section {
        padding: 20px 0;
        border-bottom: 1px solid #ddd;
    }

    .gender {
        width: 100px;
    }
    .pageWrap {
        margin: 0 auto;
        width: 100%;
        max-width: 670px;
    }
    .stepper {
        margin-top: 40px;
    }

    .yhaStyledForm {
        select {
            font-size: 1.6rem;
        }

        .cust-details {
            border-bottom: 1px solid #ddd;
            margin-bottom: 10px;
            padding-bottom: 30px;

            label {
                margin-bottom: 10px;
                margin-top: 10px;
                font-weight: bold;
            }
        }

        .form-cta {
            padding-bottom: 20px;
            padding-top: 10px;
        }
        .formError {
            color: #EE4601;
            background: url('#{$imagePath}/forms/errorMessageIndicator.png') no-repeat;
            padding-left: 24px;
            margin-top: 5px;
        }

        .input-sm {
            &.month {
                width: 130px;
            }
        }
        .numNights {
            width: 65px;
            background-color: #eeeeee;
            border: none;
            text-align: center;
        }

    }

    .submitButton,
    .backButton {
        padding-top: 15px;
    }

}
