/* Generic override */
.map  #canberra {
    display: none;
}

/* override reference: home page */
.carouselBlock {
    &-content {
        padding-top: 0;
    }

    li.topHostelCarouselItem {
        a {
            color: $grayDark;

            &:active,
            &:hover,
            &:focus {
                color: $grayDark !important;
                text-decoration: none !important;
            }

            .topHostelCarouselItem-hostelname {
                font-family: $font-secondary;
                font-size: pxToRem(22);
                font-weight: 400;
                line-height: 28px;
                text-transform: capitalize;
            }

            .topHostelCarouselItem-heading {
                font-weight: 300;
                font-size: pxToRem(16);
                line-height: 24px;
            }
        }
    }

    &-controls {
        padding-top: 30px;
    }
}

h2.text-center.b-heading,
h2.text-center.b-heading.b-margin--tight-bottom {
    margin-bottom: 48px;
}

.b-divider.b-divider--long.b-margin--loose-bottom {
    display: none;
}

.modal-backdrop {
    z-index: 0;
}

/* override reference: hostel details page */

.nearbyHostels, .groupsNearbyHostels {
    background-color: transparent;

    .nearbyHostels-inner-content,
    .groupsNearbyHostels-inner-content {
        padding: 0;
    }
}

.hostelDetailsPage-location,
.nearbyHostels-distance, .groupsNearbyHostels-distance {
    color: $grayDark;
    font-weight: 400;

    span {
        color: $grayDark;
    }
}

.hostelDetailsPage-hostelname {
    font-weight: 300;
    padding-top: 10px;
}

.accommodationList h3 {
    font-size: pxToRem(16);
}

ul.blueSquareList,
.wysiwygContent ul,
.pgToursPage .thingsYouShouldKnow ul {
    padding-left: 18px;
    li {
        background: none;
        list-style: disc;
        padding-left: 0;
    }
}
.hostelAttractions,
.pgHsDtPg .hostelAttractions {
    border: 1px solid $grayDark;
    padding: 40px;

    .b-heading.b-heading--green-bg.b-margin--no-bottom {
        padding: 0;
    }

    .content {
        padding: 0;
        border: none;
    }
}

/* override reference: Epack Form page */
.pgEpackFormPage .formTitle {
    font-family: $font-secondary;
}

/* global */
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-secondary;
}

.b-heading.b-heading--green-bg,
.heading h2,
h2.b-heading--green-bg.b-margin--no-bottom,
h3.b-heading--green-bg.b-margin--no-bottom {
    background-color: transparent;
    color: $grayDark;
    font-family: $font-secondary;
    font-size: pxToRem(24);
    line-height: 28px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 40px;
    padding-bottom: 10px;
}

.b-heading--light.b-margin--loose-top {
    @extend .p-heading;
    color: $grayDark;
}

picture img {
    max-width: 100%;
}

.pdf-wrap,
.sidebarBackground {
    background-color: transparent !important;
}

.imageCaption {
    background-color: transparent !important;
    padding: 0 !important;
}


/* CarouselBlock */
[data-block="CarouselBlock"] {
    .carouselBlock-content {
        padding-top: 20px;
        margin-left: -15px;
        margin-right: -15px;
    }

    .slick-track {
        display: flex;
        flex-flow: row wrap;
    }

    .carouselBlock-slides {
        transition: all 0.2s linear;
        opacity: 0;

        > .slick-slide:not(:first-child) {
            display: none;
        }

        &.slick-initialized {
            opacity: 1;
        }
    }

    .slick-slide {
        flex: 1 0 0%;
        padding-left: 15px;
        padding-right: 15px;
        height: inherit;

        > div {
            height: 100%;
        }

        .miniCarouselItem {
            display: flex !important;
            height: 100%;
            padding-left: 0;
            padding-right: 0;
            flex-direction: column;
            min-width: inherit;
            width: 100%;
        }
    }

    a {
        outline: none;
    }

    .btn {
        outline: none !important;
        padding-left: 0;
        padding-right: 0;
        width: 100%;

        &.btn-carousel {
            width: 46px;
        }
    }

    .slick-arrow {
        bottom: inherit;
        @media (min-width: 561px) {
            margin-top: 0;

        }
    }

    .carouselBlock-controls {
        padding-top: 40px;

        @media (max-width: 560px) {
            padding-top: 0;
            width: 100%;
            position: absolute;
            height: 46px;
            z-index: 10;
            top: 100px;

            > .btn-carousel {
                width: 46px !important;
                position: absolute;

                &.btn-carousel-left {
                    left: 20px;
                }

                &.btn-carousel-right {
                    right: 20px;
                }
            }

        }
    }

    .slick-dots {
        bottom: -17px;
        padding-left: 10px;
        padding-right: 10px;

        .slick-active button {
            background-color: $green;
        }
    }
}
