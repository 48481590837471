$blk: ".stateWidgetTitleBlock";

#{$blk} {

    &.b-cta-tile {
        display: block;
        width: 100%;
        height: 100%;
        padding: 34px 62px;
        text-align: center;
        font-family: $LunaRegularFontStack;
        background: $grayLightest;
        color: white !important; // :(
        position: relative;
        background-size: cover;
        background-position: bottom left;

        @media (max-width: $screen-sm-max) {
            padding: 20px;
            margin-bottom: 13px;
        }
        // Elements
        &__wrapper {
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
        }

        &__column {
            margin-bottom: 30px;

            @media (max-width: $screen-sm-max) {
                margin-bottom: 15px;
            }
        }

        &__icon {
            display: block;
            margin: 0 auto 7px;
            width: 73px;
            height: 56px;
            position: relative;
            z-index: 2;

            .b-cta-tile--compact & {
                margin-bottom: 4px;
            }
        }

        &__button {
            position: relative;
            font-family: $FuturaBoldCondensedFontStack;
            font-size: 2.4rem;
            border: 3px solid white;
            display: block;
            width: 100%;
            margin-bottom: 20px;
            padding: 10px 5px;
            z-index: 2;
            text-transform: uppercase;

            @media (max-width: $screen-sm-max) {
                width: auto;
                border-width: 2px;
                display: inline-block;
                padding: 10px 20px;
                margin-bottom: 16px;
            }

            .b-cta-tile--compact & {
                margin-bottom: 16px;
            }
        }

        &__description {
            position: relative;
            z-index: 2;
        }

        &__overlay {
            background: white;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            transition: opacity 0.5s ease-in-out;
        }
        //hover styles
        &:hover {
            color: black !important;

            .b-cta-tile__button {
                border-color: black;
            }

            .b-cta-tile__overlay {
                opacity: .5;
            }
        }
        // Modifiers
        &--compact {
            padding: 8px 20px 16px;

            @media (max-width: $screen-sm-max) {
                padding: 20px;
            }
        }
    }


}


/* States */

//
//#{$blk} {
//
//}
