$wg: ".svgMap";
$wg2: ".svgMapNew"; // New SVGMap widget for HomePage


#{$wg} {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;


    .svgMapState {

        .svgMapStateShape {
            fill: $gray-lighter;

            &.selected {
                fill: $lightGreen;
            }
        }

        .svgMapStateText {
            fill: $lightGreen;
            font-family: $font-primary;
            font-size: pxToRem(18);
            line-height: 22px;
            text-transform: none;

            &.selected {
                fill: $white;
            }
        }

    }

    .svgMapCity {
        .svgMapCityBox {
            fill: $white;
            stroke: $red;
            stroke-width: 1px;
        }
    }

    .circleLocation {
        stroke: $red;
        stroke-width: 2px;
    }

}

/* States */
#{$wg} {

    .svgMapState {
        cursor: pointer;

        &:hover {
            .svgMapStateShape {
                fill: $lightGreen;
            }
            .svgMapStateText {
                fill: $white;
            }
        }
    }

    .svgMapCity {
        cursor: pointer;

        &:hover {
            .svgMapCityBox {
                fill: #FFB059;
            }

        }


    }

}

// New SVGMap widget for HomePage

$map-state-hover-bg: #FF8600;
$map-txt-hover-color: #000;
$map-txt-hover-bg-color: #00A1AB;

#{$wg2} {

    svg {
        max-width: 100%;
    }


    .b-map {

        &__hint {
            display: inline-block;
            padding: 10px 12px;
            margin-top: 15px;
            background: $map-txt-hover-color;
            color: white;
            font-size: 1.3rem;
            font-weight: 500;
        }

        &__hint-highlight--light {
            font-weight: 700;
            color: white;
        }

        &__hint-highlight--color {
            font-weight: 700;
            color: $map-state-hover-bg
        }
    }
}

/* States */
#{$wg2} {

    svg {
        @media (max-width: 767px) {
            height: 280px;
        }
    }

    .b-map {

        &__state {

            &:hover {
                cursor: pointer;
                .b-map__state--shape {
                    fill: $map-state-hover-bg;
                    fill-opacity: 1;
                }

                .b-map__state--title {
                    fill: $map-txt-hover-color;
                }
            }
        }

        &__city {

            &:hover {
                cursor: pointer;
                .b-map__city--bg {
                    fill: $map-txt-hover-bg-color;
                }

                .b-map__city--title {
                    fill: $map-txt-hover-color;
                }
            }
        }

        &__hint {

            @media (min-width: 768px) {
                display: none;
            }
        }

    }

}
