
.hostelReviewCarousel {
    position: relative;
    overflow: hidden;

    ul {
        width: 20000em;
        position: relative;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            float: left;
            margin-right: -99px;
        }
    }

}

.speechBubble {
    background-color: $reviewBg;
    // triangle
    &::after {
        display: block;
        margin: 0 auto;
        content: '';
        width: 0;
        height: 0;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-top: 11px solid $reviewBg;
        position: absolute;
        bottom: -11px;
        left: 50%;
        transform: translate(-50%, 0);
    }
}
