
$blk: ".topTravelDestinationsBlock";

#{$blk} {

    background: #f0f0ee;

    .linkItem {
        padding: 20px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        padding-left: 20px;
        background: url('#{$imagePath}lists/chevron-thin-right-green.png') no-repeat top 3px left;
        background-size: 17px auto;
        margin-bottom: 10px;
    }

    li:last-child {
        margin-bottom: 0;
    }



}


/* States */

//
//#{$blk} {
//
//}

