
$wg: ".badgeItem";

#{$wg} {
	text-align: center;

	&-image {

		width: 100%;
		padding-bottom: 20px;
		padding-top: 20px;


		img {
			height: auto;
			max-width: 116px;
		}
	}
}

