
$wg: ".bannerCarousel";

#{$wg} {
    &-slides:not(.slick-initialized) .bannerCarousel-slide:not(:first-child) {
        display: none !important;
    }

    &-slide {
        position: relative;
        display: inline-block;
        overflow: hidden;
        width: 100%;

        &-img {
            background-color: #fafafa;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        &-inner {
            padding-top: 4em;
            display: flex;
            flex-direction: column;
            padding-bottom: 4em;
            min-height: 610px;
            text-align: center;
            justify-content: flex-end;
        }

        &-title {
            font-family: $font-secondary;
            color: $white;
            font-size: pxToRem(64);
            font-weight: 400;
            line-height: 68px;
            text-shadow: 2px 2px 2px rgba(0, 0, 0, .5);
            position: relative;
            z-index: 1;
        }

        &-act {
            padding-top: 24px;
            padding-bottom: 29px;
            position: relative;
            z-index: 1;

            .btn {
                display: inline-flex;
                align-items: center;
                border: none !important;
                outline: none !important;

                svg {
                    margin-right: 12px;
                }
            }
        }

        &-video {

            &-wrapper {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 0;
            }

            &-container {
                position: relative;
                width: 100%;
                padding-bottom: 56.25%;

                iframe,
                object,
                embed {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            &-shadow {
                background-color: rgba($color: #000000, $alpha: 0.3);
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                position: absolute;
                z-index: 1;
            }
        }
    }

    /*

    &-slider {
        position: relative;
    }

    &-frame {
        position: relative;
        font-size: 0;
        line-height: 0;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
    }

    &-slides {
        display: inline-block;
    }

    &-slide {
        position: relative;
        display: inline-block;
        width: 100%;

        &-img {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
        }
        &-inner {
            padding-top: 4em;
            display: flex;
            flex-direction: column;
            padding-bottom: 4em;
            min-height: 500px;
            text-align: center;
            justify-content: flex-end;
        }

        &-title {
            font-family: $font-secondary;
            color: $white;
            font-size: pxToRem(64);
            font-weight: 400;
            line-height: 68px;
        }
    }


    &-nextBtn, &-prevBtn {
        position: absolute !important;
        top: 50%;
        margin-top: -16px;
        display: block;
        cursor: pointer;
        border-radius: 50%;
        padding: 5px;
        width: 36px;
        height: 36px;
        z-index: 1;
        background: #00cedb;
        color: #000;
    }
    &-nextBtn {
        right: 16px;
    }

    &-prevBtn {
        left: 16px;
    }
    */



}


//States

#{$wg} {
    @media (min-width: $screen-sm-min) {
      padding-bottom: 38px;
    }

    @media (max-width: $screen-sm-max) {
        padding-bottom: 30px;

        &-slide{
            &-inner {
                min-height: 400px;
            }
            &-title {
                font-size: pxToRem(30);
                line-height: 1.4em;
                padding-left: 50px;
                padding-right: 50px;
            }
            &-video-container {
                height: 100%;
            }
        }
    }

    @media (max-width: $screen-xs-max) {

    }
    @media (max-width: $screen-xxs-max) {

    }
}


