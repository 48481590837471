
$wg: ".wishList";

#{$wg} {

	.button-like {
		border: 0px;
		background-color: transparent;
		text-decoration: none;
		padding: 1.5rem;
		position: relative;
		text-align: center;
		display: inline-block;
		color: $wishlistTextColor;
		transition: all ease 0.4s;
		outline: none;
		
		.text {
			margin-left: 0.5rem;
			font-weight: bold;			
			font-size: 1.6rem;
		}

		.glyphicon {
			font-size: 2.5rem;
		}
	  
		.glyphicon, .text {
			transition: all ease 0.4s;
			vertical-align: middle;
		}
	  
		&:hover {
		  .glyphicon, .text {
				color: $wishlistHoverColor;
		  }
		}
	}	  	
}
