$blk: ".hostelFeatureBlock";

#{$blk} {
    &-header {
        font-family: $font-secondary;
        font-size: pxToRem(24);
        line-height: 28px;
    }

    &-list {
        /*
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding-left: 16px;
        */
        overflow: auto;
        margin: 0;
        padding: 0;
        list-style: inside;

        &::after {
            content: "";
            clear: both;
            display: table;
        }
    }

    &-item {
        float: left;
        //flex: 0 1 calc(100%/3);
        width: calc(100%/3);
        padding-left: 20px;
    }
}

/* states */
#{$blk} {
    @media (min-width: $screen-sm-min ) and (max-width: $screen-sm-max ) {
        &-item {
            //flex: 0 1 calc(100%/2);
            width: calc(100%/2);
        }
    }

    @media (max-width: $screen-xs-max ) {
        &-item {
            //flex: 0 1 100%;
            padding-right: 0;
            width: 100%;
        }
    }

}
