
$wg: ".hostelDiscount";

#{$wg} {

	&-title {
		color: white;
	}

	&-content {
		padding: 40px 0;
	}

	&-badge-item {
		margin-bottom: 30px;
	}

	&-badge-item-description {
		text-align: center;
	}

	&-image {
		padding-bottom: 20px;
		text-align: center;
		width: 100%;

		img {
			height: auto;
			margin: auto;
		}
	}

	&-btn {
		width: 100%;
	}
}


