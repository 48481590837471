$icheck-color: #017C67 !default;


.icheckbox,
.iradio{
    background: transparent;
    border: 2px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    padding: 0;
    margin-right: 10px;
    text-align: center;
    cursor:pointer;
    &.hover {
        border: 2px solid $icheck-color;
        transition: border 0.3s ease;
    }
    &.checked {
        border: 1px solid $icheck-color;
        background: $icheck-color;

    }
    &.disabled {
        cursor: default;
    }
    &.checked.disabled {
        opacity:0.5;
    }
}
.icheckbox{
    position:relative;
    &.checked:before {
        position:absolute;
        top:0;
        left:2px;
        font-family: 'Glyphicons Halflings';
        content: "\e013";
        color: #ffffff;
        font-size:12px;
    }
}
.iradio {
    border-radius:50%;
    &.checked{
        background: $icheck-color;
        border: 2px solid $icheck-color;
        box-shadow: inset 0px 0px 0px 4px #fff;
    }
}
