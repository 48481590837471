
$pg: ".pgHsRaYhaPg";

#{$pg} {

    .rentAYhaImage {
        margin-top: 30px;
        position: relative;
    }

    .groupSizeAndRates {
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .groupRate {
        color: #515151;
        font-size: 2rem;
        padding-left: 50px;
        padding-top: 12px;
        background: url('#{$imagePath}specials-sticker.png') left center no-repeat;
        background-size: 40px;
        height: 54px;
        margin-top: 20px;
        margin-bottom: 8px;
    }
    .subheading {
        font-size: 1.6rem;
        color: #515151;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .enquiryHeading {
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .requiredText {
        float: right;
        font-family: "Helvetica Neue",Arial,sans-serif;
        font-size: 1.4rem;
        margin-top: 3px;
    }

    .pdf-wrap {
        background: #f0f0ee;

        .pdfLinks {
            margin-bottom: 15px;
            padding: 20px;

            &>div {
                margin-bottom: 6px;
            }
        }
    }
    .description {
        padding: 20px;
        padding-bottom: 0;
    }

    .stateHostelList {
        padding: 20px;

        a {
            display: block;
            margin-bottom: 10px;
        }
    }

    .imageCaption {
        display: block;
        background: #f0f0ee;
        padding: 0px 10px;
        position: relative;
        top: 0px;

        .wishList {
            .button-like{
                padding: 1.5rem 0.5rem;
                float:right;
            }

            .text {
                margin-left: 0.1rem;
                font-size: 1.3rem;
            }
        }
    }
}

#{$pg} {

    @media (max-width: $screen-xs-max){
        .imageCaption {
            top:-5px;

            .wishList {
                .button-like {
                    float:left;
                }
            }
        }
    }

    @media (min-width: $screen-xssm-min) and (max-width: $screen-xssm-max){
        .imageCaption {
            top:-35px;
        }
    }
}

