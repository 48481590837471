.filters {
    margin: 20px 0;
    position: relative;
}

.filters .filters-wrap {
    display: inline-block;
}

.filters {

    .filter-by {
        display: inline-block;
        line-height: 18px;
        vertical-align: top;
    }

    ul {
        display: inline-block;
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        width: 100%;  
    }

    .filter {
        border-left: 1px solid $grayMedium;
        float: left;
        line-height: 18px;
        padding: 0 20px;
        @media(min-width: $screen-sm-min) and (max-width: $screen-md-max) {
            padding: 0 8px;
        }

        &:first-child {
            border-left: 0 none;
        }
    }

    .current a {
        color: $gray;
        font-weight: 700;
    }
}

.filters .filter-dropdown {
    background: url('#{$imagePath}compactors/discountCompactorClosed.png') no-repeat right center;
    border: 1px solid $formInputElementBorderColor;
    color: $grayMedium;
    cursor: pointer;
    display: none;
    margin-bottom: 0;
    height: 50px;
    position: relative;
    z-index: 101;
}

@media( max-width: $screen-xs-max) {

    .filters .filter-by {
        display: none;
    }

    .filters .filter-dropdown {
        display: block;
    }
    
    .filters .filters-wrap {
        height: 0;
        position: absolute;
        top: 50px;
        width: 100%;
        z-index: 100;
        
        ul {
            float: left;
        }

        li {
            background-color: $white;
            border: 1px solid $formInputElementBorderColor;
            border-width: 0 1px;
            float: left;
            height: 0;
            line-height: 50px;
            overflow: hidden;
            padding: 0;
            width: 100%;
            transition: all 0.25s ease-in-out;

            &:first-child {
                border-left: 1px solid $formInputElementBorderColor;
            }

            > span {
                display: block;
                padding: 0 20px;

                a {
                    display: block;
                }
            }
        }
    }
    
    .filters .filter-checkbox:checked + .filters-wrap {
        height: auto;

        ul {
            box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.3);
        }

        li {
            border-bottom-width: 1px;
            height: 50px;
            transition: all 0.25s ease-in-out;
        }
    }

    .filters .filter .current a:before {
        content: attr(data-label);
        color: $gray;
        display: block;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 30px;
        width: 100%;
        height: 50px;
        padding: 10px 10px 10px 21px;
        position: absolute;
        top: -50px;
        left: 0;
        visibility: visible;
    }
}