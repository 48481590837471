

$wg: ".featuredItemsCarousel";
$wgHeight: auto;
$wgMaxWidth: auto;
$content-inset: auto;

#{$wg} {

    background-color: #f0f0ee;
    padding: 40px 25px;

    &-content {
        position: relative;
        height: $wgHeight;
        // width: $wgMaxWidth;
        max-width: $content-inset;
    }
    &-slider {
        position: relative;
        max-width: $content-inset;
    }
    &-frame {
        height: $wgHeight;
        width: 100%;
        max-width: $content-inset;
        position: relative;
        font-size: 0;
        line-height: 0;
        overflow: hidden;
        white-space: nowrap;
    }
    &-slides {
        display: block;
        width: 100%;
        padding: 0;
    }

    &-slide {
        position: relative;
        display: inline-block;
        width: 100%;
        height: $wgHeight;
        background-size: cover;
        background-position: center;
    }

    &-slideImg {
        width: 100%;
        height:auto;
        vertical-align: text-top;
    }

    &-dots {
        margin: 0;
        padding: 0;
        position: absolute;
        right: 10px;
        margin-top: -50px;
    }

    &-dot {
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 50%;
        box-shadow: inset 1px 1px 1px #888;
        display: inline-block;
        height: 10px;
        width: 10px;
        margin: 0 5px;
        cursor: pointer;
        opacity: .4;
    }

    &-linksList {
        margin-bottom: 2px;
        padding: 0;
        margin-top: -10px;
        list-style-type: none;
        line-height: 22px;
    }

    &-linkItem {
        line-height: 22px;
    }

    &-link {
        color: $grayDark;
        padding: 12px 0px 12px 30px;
        border-bottom: 1px solid white;
        display: block;
    }

}

/* States */


#{$wg} {

    @media (min-width: $screen-sm-max) {
        max-height: 365px;
    }

    &-link {
        &.active {
            font-weight: bold;
            background: url('#{$imagePath}lists/travelStoriesCarouselChevron.png') no-repeat 0 9px;
        }
    }

    &-dot {
        &.active {
            opacity: 1;
        }
    }

    @media (max-width: $screen-sm-max) {
        &-linkItem {
            background: transparent url('#{$imagePath}lists/chevron-thin-right-green.png') no-repeat 1px 14px;
            background-size: 18px auto;
        }

        &-link {
            &.active {
                background: none;
                font-weight: normal;
            }
        }
    }

}


