
$pg: ".pgHostelStateListingPage";

#{$pg} {
    color: $baseTextColor;

    &-sort {
        vertical-align: middle;
        padding: 25px 5px;
        font-size: 16px;
        text-align: right;

        span {
            display: inline-block;
        }

        label {
            display: inline-block;
        }
        select {
            display: inline-block;
            height: 48px;
            min-width: 265px;
        }
    }

    .hostelTypeKeys {
        border-bottom: 2px solid #f0f0ee;
        border-top: 2px solid #f0f0ee;
        margin-bottom: 40px;
    }

    .map {
        background-color: #00BFEE;
        padding: 0;
    }

    .details {
        background: #f0f0ee;
        padding: 0 0 30px 40px;
        position: relative;
        //top: auto;
        //right: auto;
        //height: auto;
        //margin-top: 0;
        ////padding: 15px;
        display: block;

        ul {
            list-style-type: none;
            padding-left: 0;
        }

        li {
            margin-bottom: 10px;
        }
        a {
            color: #428bca;
            text-decoration: none;
        }

    }

    .listTitle {
        font-weight: bold;
        margin-bottom: 15px;
    }

    .blueHeading {
        margin-top: 50px;
        margin-bottom: 30px;

        .blueHeadingLink {
            float: right;

            a {
                font-family: $sansFontStack;
                font-size: 1.4rem;
                font-weight: normal;
                color: white;
            }
        }
    }

    .freeThingsToDo {
        margin-bottom: 40px;

        li {
            display: inline-table;
            float: left;
            width: 25%;
            padding-right: 50px;
        }
    }

    // States


    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        .freeThingsToDo li {
            width: 50%;
        }

        .blueHeading {
            display: inline-block;
            margin: 30px 0;
            width: 100%;

            .blueHeadingLink {
                float: none;
            }
        }

    }

    @media (max-width: $screen-xs-max) {

        .freeThingsToDo li {
            width: 100%;
        }
    }

    @media (max-width: $screen-sm-max) {

        .map {
            display: none;
        }

    }





}


