$blk: ".contentPageListingBlock";

#{$blk} {

    border-top: 2px solid #f0f0ee;
    clear: both;
    margin-bottom: 30px;
    padding-top: 30px;

    img {
        float: left;
        margin-top: 15px;
        padding-bottom: 30px;
        width: 200px;
    }

    .content {
        margin-left: 230px;
    }

}

/* States */

#{$blk} {

    @media (max-width: $screen-xsmd-max) {
        img {
            display: block;
            float: none;
            margin: 0 auto;
            max-width: 100%;
            width: auto;
        }

        .content {
            margin: 0;
            text-align: center;
        }
    }

}


