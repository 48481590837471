
$blk: ".testimonialBlock";

#{$blk} {

    background-color: #f0f0ee;
    color: $baseTextColor;

}


/* States */


//#{$blk} {
//
//}
//
