
$pg: ".pgHostelCityListingPage";

#{$pg} {

    color: $baseTextColor;

    .mainAustraliaMap {
        position: relative;
        margin-top: 40px;
    }

    &-sort {
        vertical-align: middle;
        padding: 25px 5px;
        font-size: 16px;
        text-align: right;

        span {
            display: inline-block;
        }

        label {
            display: inline-block;
        }
        select {
            display: inline-block;
            height: 48px;
            min-width: 265px;
        }
    }

    .hostelTypeKeys {
        border-bottom: 2px solid #f0f0ee;
        border-top: 2px solid #f0f0ee;
        margin-bottom: 40px;
    }

    .map {
        background-color: #00BFEE;
        padding: 0;
    }

    .details {
        position: relative;
        top: auto;
        right: auto;
        height: auto;
        margin-top: 0;
        padding: 15px;
        display: block;

        .title {
            text-transform: uppercase;
        }

        h1 {
            font-family: $FuturaBoldCondensedFontStack;
            margin-top: 4px;
        }

    }


    // States


    @media (max-width: $screen-sm-max) {

        .map {
            display: none;
        }

    }
    //
    //@media (max-width: $screen-xs-max) {
    //
    //
    //}

}
