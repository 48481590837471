.backgroundBanner {
	position: relative;
	width: 100%;

	/* the new banners has ribbon with the text in the center which will not be seen if viewing on large screen */
	max-width: 1920px; 
	margin: 0 auto;

	.backgroundBannerLink {
		color: $white;
		text-align: right;
		margin-top: 10px;

		a {
			color: $white;
			text-decoration: none;
		}
	}

	img {
        -webkit-mask-image: -webkit-gradient(linear, left 70%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
}