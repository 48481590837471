// needs the extra specificity because EPiServer a "/WebResource.axd?" CSS file
.sitewrap .EPiServerForms {

    .is-aside & {
        padding: 3rem;
        color: #fff;
        margin: 0 auto;
    }


    label {
        user-select: none;
        font-weight: normal;
    }

    .Form {

        &__NavigationBar {
            margin: 30px auto;
            text-align: center;

            &__Action {

                @extend .btn;
                @extend .btn-default;
            }

            &__ProgressBar {
                display: inline-block;
            }

        }
        &__MainBody {
            background-color: #fff;
            max-width: 100%;
            width: 400px;


            .is-aside & {
                max-width: 100%;
                background-color: transparent;
                padding: 0;
            }

            img {
                max-width: 100%;
            }
        }

        //&__Title {
            //@extend .h-lg;
            //text-align: center;
            //margin-bottom: 0;
            //font-weight: normal;
            //
            //.is-aside & {
            //    text-align: left;
            //    color: #fff;
            //}
        //}

        &__Description {
            //@extend .p-md;
            //text-align: center;
            //margin-bottom: 20px;

            //.is-aside & {
            //    text-align: left;
            //}
            display: block;
            width: 100%;
            padding: 0;
            margin-bottom: 20px;
            font-size: 21px;
            line-height: inherit;
            color: #474747;
            border: 0;
        }

        &__Element {
            margin-bottom: 20px;

            &__ValidationError {
                color: #ee4601;
                background: url('#{$imagePath}forms/errorMessageIndicator.png') no-repeat;
                padding-left: 24px;
                margin-top: 5px;
            }

            &__Caption {
                display: block; // removed inline-block because it caused subsequent checkboxes from falling onto same line
                max-width: 100%;
                margin-bottom: 5px;

                // if there is an text input field, don't want border bottom or margin on caption (which is a label element)
                //.FormTextbox & {
                //    margin-bottom: 0;
                //    border-bottom: 0;
                //}

            }

            &.FormSelection {
                @extend .styled-select;

                > select {
                    border-radius: 0;
                    height: 50px;
                    border: 1px solid #ddd;
                    min-width: 280px;
                    background-image: url('#{$imagePath}icons/icon-chevron-down_blk.svg');
                    background-repeat: no-repeat;
                    background-size: 15px 8px;
                    background-position: right 15px top 23px;
                    background-color: #fff;

                    &[multiple] {
                        background-image: none;
                        height: 150px;
                        overflow-y: scroll;
                        padding: 10px;
                    }
                }
            }

            &.FormSubmitButton {
                @extend .btn;
                @extend .btn-default;
            }

            &.ValidationRequired {
                > label {
                    &:after {
                        color: #00a5e3;
                        font-weight: bold;
                        font-size: 1.8rem;
                        line-height: 14px;
                        padding-left: 5px;
                        vertical-align: middle;
                        content: '*';
                    }
                }
            }

            &.FormParagraphText {
                &.Form__Element--NonData {
                    display: block;
                    margin-top: -15px;
                    margin-bottom: 10px;
                    color: #737373;
                }
            }
        }
    }

    .FormRange {

        & > span {
            width: 100%;
            display: table;
            //display: table-row;
        }

        .FormRange__Input,
        .FormRange__Min,
        .FormRange__Max {
            display: table-cell;
        }

        .FormRange__Min,
        .FormRange__Max {
            padding-top: 2px;
            vertical-align: top;
        }

        .FormRange__Min {
            width: 20px;
        }

        .FormRange__Max {
            width: 30px;
            text-align: right;
        }
    }

    .FormTextbox {

        &__Input {
            width: 100%;
            max-width: 100%;
            height: 50px;
            font-size: 1.6rem;
            padding: 14px 18px;
            color: #000;
            border: 1px solid #ddd;

            .FormCaptcha & {
                display: inline-block;
                margin-right: 1.5rem;
            }
        }

        &--Textarea {
            .FormTextbox__Input {
                width: 100%;
                max-width: 100%;
                height: 120px;
                font-size: 1.6rem;
                padding: 14px 18px;
                color: #000;
                border: 1px solid #ddd;
            }
        }
    }

    .FormCaptcha {
        text-align: left;

        &__Image {
            width: 100%;
            margin-bottom: 1.5rem;
        }

        &__Refresh {
            //@extend .rasbtnlink;
        }
    }

    .FormChoice {
        > label {
            //@extend .raslabel;
            margin-right: 1.5rem;
            line-height: 16px;
        }
    }
} // end .EPiServerForms


// removed Edge and IE's clear "X" button
input::-ms-clear {
    display: none;
}


// removed Edge and IE's double dropdown arrow
select::-ms-expand {
    display: none;
}
