
$blk: ".termsAndConditionsBlock";

#{$blk} {

    border: 1px solid #ddd;
    height: 240px;
    margin: 30px 0;
    overflow: auto;
    padding: 15px;
    color: $baseTextColor;

    ul {
        list-style: disc inside none;
    }

    li {
        background-image: none !important;
    }
}


/* States */


//#{$blk} {
//
//}
//
