
$wg: ".bookingEnquiryForm";

#{$wg} {

    legend {
        display: block;
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
        font-size: 21px;
        line-height: inherit;
        color: #474747;
        border: 0;
    }

    //color: #666;
    .requiredText {
        float: right;
        font-family: $sansFontStack;
        font-size: 1.4rem;
        margin-top: 3px;
    }

    label {
        &.field-required {
            color: inherit;
            font-weight: inherit;
            font-size: inherit;
            line-height: inherit;
            padding-left: inherit;
            vertical-align: inherit;

            &:after {
                content: '*';
                color: #00a5e3;
                font-weight: bold;
                font-size: 1.8rem;
                line-height: 14px;
                padding-left: 5px;
                vertical-align: middle;
            }
        }
    }

    .no-of-nights {
        width: 100px !important;

        &:focus {
            box-shadow: none;
        }
    }

}

/* States */


#{$wg} {

    #submit-rentayha-group-bookingform {
        &:hover {
            background-color: #007C67;
        }
    }
}

