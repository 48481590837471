.b-divider {

    background: none;
    border: none;
    margin: 20px auto;

    &--long {
        width: 100%;
        height: 1px;
        // https://jira.yha.com.au/browse/CMSR-2069 : Remove background
        // background: url('#{$imagePath}dividers/long-divider.svg') 50% 50% no-repeat;
        // background-size: contain;

        @media (min-width: $screen-md-min) {
            width: 946px;
        }

        @media (max-width: $screen-xs-max) {
            background-image: url('#{$imagePath}dividers/short-divider.svg');
            height: 23px;
            background-size: 100%;
        }

    }

    &--short {
        width: 100%;
        height: 19px;
        background: url('#{$imagePath}dividers/short-divider.svg') no-repeat;
        background-size: contain;
    }

    &--white {
        background: white;
        width: 100%;
        height: 2px;
        clear: both;
        margin: 18px 0;
    }
}
