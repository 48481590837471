
$wg: ".hostelFeaturedReviews, .reviewCarouselBlock";
$wgHeight: auto;
$wgMaxWidth: auto;
$content-inset: auto;
#{$wg} {

    background: #ffffff;

    .total-rating {
        font-size: 3.6rem;
        display: block;
    }

    .numRatings {
        display: block;
    }

    &-content {
        position: relative;
        height: $wgHeight;
         width: $wgMaxWidth;
        max-width: $content-inset;
    }
    &-slider {
        position: relative;
        max-width: $content-inset;
    }
    &-frame {
        height: $wgHeight;
        width: 100%;
        max-width: $content-inset;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
    }
    &-slides {
        display: block;
        width: 100%;
        padding: 0;
    }

    &-slide {
        position: relative;
        display: inline-block;
        width: 100%;
        height: $wgHeight;
        white-space: normal;
        vertical-align: middle;

    }

    &-nextBtn, &-prevBtn {
        position: absolute !important;
        top: 50%;
        margin-top: -16px;
        display: block;
        cursor: pointer;
        border-radius: 50%;
        padding: 5px;
        width: 36px;
        height: 36px;
        z-index: 1;
        background: $lightGreen;
        color: #000;
    }
    &-nextBtn {
        right: 16px;
    }

    &-prevBtn {
        left: 16px;
    }

    .b-divider--short {
        margin-bottom: 0;
    }

}

.reviewCarouselBlock {
    padding-bottom: 20px;
    &-content {
        padding-top: 20px;
    }
}


