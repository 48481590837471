$wg: ".contactForm";

#{$wg} {

    background-color: $white;
    color: $grayDark;
    padding: 15px 20px;
    position: relative;
    min-height: 1200px;

    .blueHeading {
        width: 700px;
        margin: 30px auto;

        .blueHeadingRight {
            margin-top: 2px;
        }
    }

    .blueHeadingRight {
        display: inline-block;
        float: right;
        padding-left: 5px;
        margin-top: 2px;
        text-transform: none;
        font-size: 14px;
        font-weight: normal;
        font-family: $FuturaRegularFontStack;
    }

    .contactYHA-form {
        @extend .yhaStyledForm;
        width: 550px;
        margin: 0px auto;

        fieldset > div {
            margin-bottom: 20px;
        }

        input[type="text"], textarea, .styled-select {
            width: 100%;
            max-width: 100%;
        }

        select {
            width: 560px;
        }

        textarea {
            height: 200px;
        }

        .captchaField {
            display: inline-block;
            background: #DAEBFD;
            border: 2px solid #DAEBFD;
            border-radius: 6px;
            padding: 20px;
            margin-top: 20px;
            margin-bottom: 30px;
        }

        .contact-us-btn {
            @extend .btn-default;
            width: 230px;
        }

        .formError {
            color: $errorMessageText;
            background: url('#{$imagePath}forms/errorMessageIndicator.png') no-repeat;
            padding-left: 24px;
            width: 400px;
            margin-top: 5px;
        }

        .invalid {
            border: 2px solid $errorMessageText !important;
        }
    }

    .contactUsFormBadges {
        background: $sidebarBackgroundColor;
        margin: 30px -40px 0px;

        .badgeItem {
            padding: 40px 120px 20px;
            text-align: center;
        }
    }

    .acceptTermsRow {
        label {
            display: inline-block;
        }
    }

}

/* States */

#{$wg} {

    @media (max-width: $screen-xs-max) {
        min-height: 1400px;
    }

    @media (max-width: $screen-xs-max) {

        .blueHeading {
            max-width: 100%;
        }

        .contactYHA-form {
            max-width: 100%;

            input[type="text"],
            textarea,
            .styled-select {
                max-width: 100%;
            }

            .contact-us-btn {
                width: 100%;
            }

            .captchaField {
                max-width: 100%;
            }
        }

        .contatcYHA-fieldset {
            padding: 0;
            margin: 0;
        }

    }

}
