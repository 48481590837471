﻿.yellowChevronList {
	list-style-type: none;
	padding: 0px;
	margin: 0px;

	li {
		background-image: url("#{$imagePath}lists/chevron-thin-right-green.png");
        background-size: 14px auto;
		background-repeat: no-repeat;
		background-position: 0px 3px;
        background-position: auto 100%;
		padding-left: 16px;
		margin-bottom: 4px;
	}

	a {
		text-decoration: none;
	}
}

.yellowTickList {
	list-style-type: none;
	padding: 0px;
	margin: 0px;

	li {
		background-image: url("#{$imagePath}lists/yellowTick.png");
		background-repeat: no-repeat;
		background-position: 0px 0px;
		padding-left: 30px;
		margin-bottom: 8px;
	}

	a {
		text-decoration: none;
		color: #464646;
	}
}

.greenTickList {
	list-style-type: none;
	padding: 0px;
	margin: 0px;

	li {
		background-image: url("#{$imagePath}lists/greenTick.png");
		background-repeat: no-repeat;
		background-position: 0px 3px;
		padding-left: 25px;
		margin-bottom: 8px;
	}

	a {
		text-decoration: none;
		color: #464646;
	}
}

.greenTickList2 {
	list-style-type: none;
	padding: 0px;
	margin: 0px;

	li {
		background-image: url("#{$imagePath}lists/greenTick2.png");
		background-repeat: no-repeat;
		background-position: 0px 1px;
		padding-left: 30px;
		margin-bottom: 8px;
	}

	a {
		text-decoration: none;
		color: #464646;
	}
}

.blueSquareList {
	list-style-type: none;
	padding: 0px;
	margin: 0px;

	li {
		background-image: url("#{$imagePath}lists/blueSquare.png");
		background-repeat: no-repeat;
		background-position: 0px 7px;
		padding-left: 17px;
		margin-bottom: 8px;
	}

	a {
		text-decoration: none;
		color: #464646;
	}
}

.orangeSquareList {
	list-style-type: none;
	padding: 0px;
	margin: 0px;

	li {
		background-image: url("#{$imagePath}lists/orangeSquare.png");
		background-repeat: no-repeat;
		background-position: 0px 7px;
		padding-left: 17px;
		margin-bottom: 2px;
	}
}

.yellowChevronRight {
    &::after {
        content: '';
        width: 33px;
        height: 17px;
        display: inline-block;
        margin-left: 10px;
        background: url("#{$imagePath}icons/RHS_Arrow.svg") no-repeat;
        background-size: cover;
        background-position: right bottom -8px;
        vertical-align: middle;
    }
}

.yellowChevronLeft {
	padding-left: 20px;
	background: url("#{$imagePath}lists/chevron-thin-right-green.png");
	background-repeat: no-repeat;
    background-size: auto 100%;
	background-position: 0px;
}

.sidebarItemList {
	list-style-type: none;
	padding: 0px 0 20px 0;

	li {
		font-size: 1.6rem;
		padding: 10px 30px;

		a, a:hover, a:visited {
			color: $baseTextColor !important;
			text-decoration: none;
		}
	}

	li.current {
		background: $sidebarHighlightedItemBackgroundColor;

		a, a:hover, a:visited {
			color: $sidebarHighlightedItemTextColor !important;
		}
	}
}

ol.blueCirclesList {
	list-style-type: none;
	margin: 0;
	padding: 0;
	counter-reset: li-counter;
}

ol.blueCirclesList > li {
	position: relative;
	margin-bottom: 30px;
	padding-left: 60px;
	padding-right: 20px;
	min-height: 45px;
}

ol.blueCirclesList > li:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 45px;
	height: 45px;
	font-size: 3.0rem;
	line-height: 45px;
	text-align: center;
	color: white;
	border-radius: 50%;
	background-color: $blueHeadingColor;
	content: counter(li-counter);
	counter-increment: li-counter;
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

	ol.blueCirclesList li:nth-child(2n+1) {
		clear: both;
	}
}

@media (min-width: $screen-md-min) {

	ol.blueCirclesList li:nth-child(4n+1) {
		clear: both;
	}
}
