﻿.googleMapHostelPopup {
	width: 200px;
	position: relative;

	img {
		width: 100%;
		height: auto;
	}

	.ratingBadgeSmall {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.hostelName {
		color: #464646;
		font-size: 1.6rem;
		font-weight: bold;
		text-align: center;
		margin-top: 10px;
	}

	.hostelLocation {
		color: #7F7F7F;
		font-size: 1.2rem;
		text-align: center;
		margin-top: 4px;
	}

	.btn-viewDetails {
		width: 100%;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.btn-booknow {
		width: 100%;
	}
}

div[data-ux-pagebuilder="Column"] .google-map img {
	max-width: none;
}