
$blk: ".discountListingBlock";

#{$blk} {

    .col-cust-2 {
        margin-top: 30px;
    }

    .discountItem {
        border: 1px solid #f0f0ee;
        padding: 10px;
        text-align: center;

        li {
            position: relative;

            &::before {
                content: '■';
                position: absolute !important;
                left: -1px !important;
                top: -1px;
                // bottom: 2.5px;
                // right: 18px;
                color: #2ab472;
            }

            a {
                text-decoration: underline !important;
            }

            a:hover {
                text-decoration: none !important;
                color: #2a6496;
            }
        }
    }

    .img-wrap {
        display: inline-block;
        height: 152px;
        overflow: hidden;
        white-space: nowrap;
        width: 228px;

        img {
            margin-left: -3px;
            max-width: 100%;
            vertical-align: middle;
        }
    }
    .companyName {
        text-align: center;
        font-weight: bold;
        font-size: 1.6rem;
        margin-top: 10px;
        margin-bottom: 10px;
        @include line-clamp(1, 1.4);
    }

    .offerSummary {
        text-align: center;
        background: #2ab472; // #f90;
        color: #fff;
        // text-transform: uppercase;
        font-size: 1.8rem;
        font-weight: bold;
        padding: 4px 0;
        @include line-clamp(1, 1.4);
    }
    .offerText {
        margin-top: 10px;
        font-size: 1.2rem;
        text-align: left;
        //@include line-clamp(5, 1.45);
    }

    .redemptionText {
        font-size: 1.2rem;
        text-align: left;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        text-align: left;
        margin-top: 10px;
    }

    li {
        // background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAHAgMAAABIN+TNAAAAA3NCSVQICAjb4U/gAAAACVBMVEX/mQD/5b//sj9lDyIUAAAACXBIWXMAAAsSAAALEgHS3X78AAAAFXRFWHRDcmVhdGlvbiBUaW1lADE0LzIvMTSAxuYmAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAAA1JREFUCJljaGBkwEAAJtsDiOXIWUcAAAAASUVORK5CYII=');
        // background-repeat: no-repeat;
        // background-position: 0 7px;
        padding-left: 17px;
        margin-bottom: 2px;

        &:before {
            width: 10px;
        }
    }

    .phone {
        font-weight: bold;

        a {
            color: #333333;
            font-weight: normal;
        }
    }

    .link a {
        color: #333333;
    }

}


/* States */


//#{$blk} {

    //@media (min-width: $screen-lg-min) {
    //    .col-cust-2:nth-child(4n+1) {
    //        clear: both;
    //    }
    //}

//}

