$pg: ".pgEpackFormPage";

#{$pg} {
    color: $baseTextColor;

    .stepper {
        padding: 25px 0;
    }
    .heading {
        padding-top: 40px;
    }
    .formTitle {
        font-size: 2.4rem;
        font-family: $FuturaCondensedFontStack;
        text-transform: none;
        color: $baseTextColor;
    }
    .mandatoryMessage {
        line-height: 56px;
        float: right;
    }
    .detailsForm {
        max-width: 100%;
        margin-bottom: 80px;


        .text, .nationality {
            width: 300px;
            max-width: 100%;
            margin-bottom: 15px;
        }

        .dobDay, .dobMonth, .dobYear {
            width: 96px;
            max-width: 100%;
        }

        .buttonControls {
            margin: 20px 0;
        }
        .submitButton, .backButton {
            width: 200px;
        }
        .submitButton {
            float: right;
        }
    }
}
