$pg: ".pgGroupsQuoteDetailPage";

#{$pg} {
    color: $baseTextColor;



    section {
        padding: 20px 0;
    }

    .gender {
        width: 100px;
    }
    .pageWrap {
        margin: 0 auto;
        width: 100%;
        max-width: 670px;
    }
    .stepper {
        margin-top: 40px;
    }



    .yhaStyledForm {
        select {
            font-size: 1.6rem;
        }

        .cust-details {
            border-bottom: 1px solid #ddd;
            margin-bottom: 10px;
            padding-bottom: 30px;

            label {
                margin-bottom: 10px;
                margin-top: 10px;
                font-weight: bold;
            }
        }

        .form-cta {
            padding-bottom: 20px;
            padding-top: 10px;
        }

        .formError {
            color: #EE4601;
            background: url('#{$imagePath}/forms/errorMessageIndicator.png') no-repeat;
            padding-left: 24px;
            margin-top: 5px;
        }

        .input-sm {
            width: 60px;
            height: 36px !important;
            border: 1px solid #555555;
            border-radius: 0;
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;

            .ie-msie11 &,
            .ie-msie10 &,
            .ie-msie19 & {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .numNights {
            width: 65px;
            background-color: #eeeeee;
            border: none;
            text-align: center;
        }

        .total-guests-input-top {
            margin-top: -5px;
            border: 1px solid #ddd;
        }

        .table {
            background-color: #eeeeec;
            margin-bottom: 4px;

            th, td {
                vertical-align: middle;
                border: 2px solid #ffffff;
            }

            td {
                background-color: #f7f7f6;
            }

            th {
                color: #333333;
                font-size: 18px;
            }
        }
    }

    .field-validation-error {
        color: red;
        display: block;
    }

    .field-validation-valid {
        display: none;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        font-size: 1.6rem;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .invalid {
        border: 2px solid #EE4601;
    }

    .acceptTNC {
        .icheckbox {
            margin-left: -30px;
        }

        label {
            padding-left: 30px;
        }

        + .formError {
            margin-bottom: 15px;
        }
    }

    .total-guests {
        padding: 4px;
    }

    .optional-addons {
        padding-left: 12px;
        padding-right: 12px;
    }

    .optional-addons-col {
        height: 40px;
        vertical-align: middle;
        padding-bottom: 8px;
        padding-top: 8px;
    }

    .section {
        display: block;
        width: 100%;
        border-top: 1px solid #ddd;
        padding: 16px 0;
    }

    .submit-form {
        width: 200px;
    }

    .submitButton,
    .backButton {
        padding-top: 15px;
    }

}
