
$pg: ".pgHome, .pgHomeOld";

#{$pg} {

    .mainPageContent {
        padding-bottom: 33px;
    }

    .map-wrapper {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .searchYHA {
        position: relative;
        width: 100%;
    }

    .searchYHA .btn {
        vertical-align: top;
        margin-left: -1px;
        padding: 11px 28px;
        background-color: $grayDark;
        color: $white;
    }

    .searchYHA .btn .glyphicon {
        padding: 0 8px 0 0;
    }

    .searchWithMap {
        background-position: bottom right;
        background-size: cover;
        background-repeat: no-repeat;
        text-align: center;
        padding: 40px 0;
        height: 610px;
    }


    .searchWithMapNew {
        background-position: top left;
        background-size: cover;
        background-repeat: no-repeat;
        text-align: center;
        padding: 0;
        height: 710px;

        .searchWithMapInner {
            position: relative;
            padding: 20px 15px;

        }

        .b-hero {

            &__wrap {
                height: auto;
                background-position: top left;
                padding: 0;
            }

            &__col--left {
                z-index: 1;
                height: 530px;
                @media (max-width: 767px) {
                    height: 330px;
                }
            }

            &__content {
                position: relative;
                top: 50%;
                //left: 50%;
                transform: translateY(-50%);
            }

            &__caption {
                max-width: 260px;
                margin: 0 auto;
            }


            &__gradient-filter {
                height: 100%;
                padding: 40px 0;
                background: -moz-linear-gradient(left, rgba(0,0,0,0) 36%, rgba(0,5,7,0) 37%, rgba(0,131,190,1) 63%, rgba(0,131,190,1) 99%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left, rgba(0,0,0,0) 36%,rgba(0,5,7,0) 37%,rgba(0,131,190,1) 63%,rgba(0,131,190,1) 99%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right, rgba(0,0,0,0) 36%,rgba(0,5,7,0) 37%,rgba(0,131,190,1) 63%,rgba(0,131,190,1) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#0083be',GradientType=1 ); /* IE6-9 */

                @media (max-width: 767px) {
                    background: -moz-linear-gradient(top, rgba(0,0,0,0) 36%, rgba(0,5,7,0) 37%, rgba(0,131,190,1) 63%, rgba(0,131,190,1) 99%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 36%,rgba(0,5,7,0) 37%,rgba(0,131,190,1) 63%,rgba(0,131,190,1) 99%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom, rgba(0,0,0,0) 36%,rgba(0,5,7,0) 37%,rgba(0,131,190,1) 63%,rgba(0,131,190,1) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#0083be',GradientType=0 ); /* IE6-9 */
                    padding: 15px 0;
                }
            }

        }

        .b-map {

            &__hint {
                display: inline-block;
                padding: 10px 12px;
                margin-top: 15px;
                //background: $map-txt-hover-color;
                color: white;
                font-size: 1.3rem;
                font-weight: 500;

                @media (min-width: 768px) {
                    display: none;
                }
            }

            &__hint-highlight--light {
                font-weight: 700;
                color: white;
            }

            &__hint-highlight--color {
                font-weight: 700;
                color: $map-state-hover-bg
            }
        }

        .search-keywords {
            margin-right:140px;
        }

        .search-btn {
            width: 100%;
        }
    }

    // .topHostelCarouselBlockWrap {
    //     .carouselBlock-frame {
    //         height: 310px;
    //     }
    // }

    .carouselBlockWrap,
    .topHostelCarouselBlockWrap {
        .carouselBlock-frame,
        .carouselBlock-slide  {
            height: auto;
        }

        .carouselBlock-slide {
            vertical-align: top;
        }
    }

    .homepageAllHostelsMap {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        .svgMap {
            height:100%;
        }


    }

    .full-page-wrapper {
        background-color: white;
        position: relative;
        left: 0;
        right: 0;


        .label-book {
            display: block;
            padding-bottom: 4px;
            font-weight: normal;
            color: #fff;
        }
    }

    .whyBookWithYhaInner {
        padding: 0;
        margin: 40px 0;

        .fullWidthBanner .col-md-8 {
            padding: 0 15px;
        }
    }

    .searchWithMap .mapLink a {
        color: #fff;
        font-size: 2.4rem;
        font-weight: normal;
        text-decoration: none;
        border: 3px solid #fff;
        font-family: "Futura LT W05 Bold Condensed","Helvetica Neue",Arial,sans-serif;
        text-transform: uppercase;
        display: inline-block;
        padding: 10px 19px 6px;

        &:hover {
            background-color: #fff;
            color: #000;
        }
    }

    .searchWithMap .mapLink a span {
        display: inline-block;
        margin-right: 15px;
    }

    .searchWithMap .mapLink a .b-icon {
        width: 52px;
        height: 47px;
        vertical-align: middle;
        margin-top: -7px;
    }

    .b-fullwidth-promo {
        display: block;
        padding: 10px 20px 10px;
        background: #e3e3e3 no-repeat 50% 100%;
        background-size: cover;
        text-align: center;
    }

    .b-fullwidth-promo__container {
        background-size: contain;
        background-repeat: no-repeat;
    }

    .mapLink.hide-map {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0;

        a {
            width: 40px;
            height: 40px;
            background: url('#{$imagePath}icons/MAP_close_button.svg') no-repeat 100%;
            border: 0;

            span,
            .b-link-icon__inline-icon {
                display: none;
            }

            &:hover {
                background-image: url('#{$imagePath}icons/MAP_close_button_hover.svg');
            }
        }
    }


    @media (min-width: 768px) {
        .searchWithMap .search-btn, .searchWithMapNew .search-btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 140px;
        }
    }

    @media (max-width: $screen-xs-max) {

        .backgroundBanner {

            img {
                -webkit-mask-image: none;

                &.img-holder {
                    display: block;
                    position: static;
                }
            }

            .container {
                position: absolute;
                bottom: 10px;
                right: 0;

                .backgroundBannerLink {
                    text-shadow: 2px 2px 2px #000;
                }
            }
        }

        .mainContentSpacer.medium {
            display: none;
        }

         .searchWithMap {

            .searchWithMapInner {
                display: inline-block;
                padding: 20px 15px;
            }

            .mapLink {
                display: none;
            }


            .searchYHA {
                float: none;
                margin: 0;
                display: block;

                .btn {
                    width: 100%;
                }
            }
        }

        .searchWithMapInner {

            .search-keywords,
            .homepage-datePicker,
            .checkin-date,
            .checkout-date {
                width: 100%;

                .field-group {
                    width: 100%;
                }
            }
        }

        .top5Item {
            margin: 0 auto;
            padding-top: 30px;

            img {
                width: auto;
                max-width: 100%;
            }
        }

        .promo-wrap .badgeItem {
            margin: 0 auto;
        }

        .miniCarousel {
            margin: 30px auto 0;
            max-width: 100%;
            width: 100%;
        }

        .miniCarouselItem .headerImage img {
            height: auto;
        }

        .miniCarousel .jcarousel-pagination {
            top: 228px;
        }

        .whyBookWithYhaInner h3 {
            padding-top: 20px;
        }

        .supporting-nav-outer li {
            background: transparent url('#{$imagePath}images/lists/chevron-thin-right-green.png') no-repeat 1px 14px;
        }

        .travelStoriesCarousel .supporting-nav li a {
            padding-left: 16px;

            &.active {
                background: none;
                font-weight: normal;
            }
        }

        .badgeFeature .row {
            margin: 0 -15px;
        }

        .badgeFeatureInner {
            padding: 0;
        }
    }

    @media (min-width: $screen-xxs-max) and (max-width: $screen-xsmd-max) {

         .backgroundBanner {
            background-color: $blue;
            min-height: 128px;
        }

        .top5Item img {
            width: auto;
        }

        .badgeItem {
            text-align: center;

            .badgeItem-image,
            .badgeItem-description {
                width: 100%;
            }

        }

        .promoItemFeature .promo-wrap .badgeItem {
            img {
                width: 100%;
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        .searchWithMap, .searchWithMapNew {
            .searchYHA {
                max-width: 460px;
                margin: 20px auto 0 auto;
            }

            .search-keywords {
                width: 100%;
                padding-right: 140px;

                .field-group {
                    width: 100%;
                }

                input {
                    width: 100%;
                }
            }

            .search-btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 140px;
            }
        }
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

        .miniCarousel .jcarousel-pagination {
            top: 190px;
        }
    }

    @media (max-width: $screen-xs-max) {
        .searchWithMapInner {
            img {
                max-width: 260px;
            }
            .b-heading--xlarge {
                font-size: 1.5em;
            }
            .b-heading--secondary {
                font-size: .8em;
            }
        }
    }

    @media (min-width: 992px) {
        .searchWithMap .searchYHA,
        .searchWithMap .mapLink {
            margin-top: 25px;
        }
    }

    @media (max-width: 991px) {

        .searchWithMapNew {

            .searchYHA {
                margin-top: 15px;
            }
        }
        .searchYHA input {
            width: 100%;
            padding-right: 0;
            margin-right: 2px;
        }
    }


    @media (min-width: 768px) and (max-width: 991px) {
        .b-fullwidth-promo .container {
            background-image: url("#{$imagePath}Membership_Image_tablet.png");
        }
    }
    @media (min-width: 992px) {
        .b-fullwidth-promo .container {
            background-image: url("#{$imagePath}Membership_Image_desktop.png");
        }
    }

    @media (min-width: $screen-xs-max) {
        .searchWithMapNew {
            height: 610px;
        }
    }




}

