$blk: ".hostelActivityBlock";

#{$blk} {
    margin-bottom: 30px;
    //height: 425px;

    img {
        width: 100%;
    }

    .activityName {
        font-family: $MuseoSansRounded900FontStack;
        font-size: 1.8rem;
        color: #505050;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .activityDescription {
        //@include line-clamp(3, 1.4);
    }

    .activityDetail {
        margin-top: 10px;

        & > div {
            display: inline-block;
        }

        .detailName {
            font-weight: bold;
            width: 60px;
            vertical-align: top;
        }

        .detailValue {
            width: 260px;
        }
    }
}

/* States */


#{$blk} {

    @media (max-width: $screen-xs-max) {
        height: auto;
    }

}
