$par: ".relatedTourAndActivityPage";

#{$par} {
    .toursAndActivitiesItem {
        position: relative;
        height: 480px;
        margin-bottom: 25px;

        .mainImageContainer {
            height: 228px;
            overflow: hidden;
        }

        .mainImage {
            width: 100%;
        }

        .yhaFlag {
            position: absolute;
            top: 0px;
            right: 25px;
        }

        .textSection {
            overflow: hidden;
            height: 180px;

            a {
                color: $grayDark;
            }
        }

        .location {
            color: $lightTextColor;
            margin-bottom: 10px;
        }

        .btn {
            position: absolute;
            bottom: 20px;
            left: 0px;
            width: 200px;
        }
    }
}

/* States */

#{$par} {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

        .toursAndActivitiesItem {
            height: auto;
            margin-bottom: 30px;
            padding-bottom: 90px;

            .mainImageContainer {
                height: auto;
            }

            .textSection {
                height: auto;
            }
        }
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

        .toursAndActivitiesItem {

            .mainImageContainer {
                height: auto;
                max-height: 185px;
            }

            .btn {
                bottom: 0;
            }
        }
    }

}





