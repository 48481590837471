// Link icon
// 
// Link icon styles for svg hovers
// 
// .b-link-icon - Default
// 
// Markup:
// <a href="https://www.facebook.com/YHAhostels" class="social-nav-icon b-link-icon" target="blank">
//      <svg class="b-icon b-icon--small b-icon--facebook b-link-icon--default" role="img"><use xlink:href="/ui-assets/images/icons.svg#icon-fb"/></svg>
//      <svg class="b-icon b-icon--small b-icon--facebook b-link-icon--hover" role="img"><use xlink:href="/ui-assets/images/icons.svg#icon-fb_hover"/></svg>
// </a>

// Style guide: Components.Link icon

.b-link-icon {
    position: relative;
    display: block;

    //Elements
    &__inline-icon {
        display: inline-block;
        vertical-align: middle;
    }

    // Modifiers
    &--default {
        display: block;
    }

    &--hover {
        display: none;
    }

    // Hover swap svg
    &:hover {
        .b-link-icon--default {
            display: none;
        }

        .b-link-icon--hover {
            display: block;
        }
    }

    &::after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
