
$wg: ".hsLstnWg";

#{$wg} {
    &-hostelList {
        display: flex;
        flex-wrap: wrap;
    }

    &-selectedHostelsTitle {
        color: $grayDark;
        font-family: $font-secondary;
        font-size: pxToRem(34);
        font-weight: 500;
        line-height: 38px;
    }

    &-sort {
        vertical-align: middle;
        padding: 25px 5px;
        font-size: 16px;
        text-align: center;

        span {
            display: inline-block;
        }

        label {
            display: inline-block;
        }
        select {
            display: inline-block;
            height: 48px;
            min-width: 265px;
        }

        @media(min-width: 768px) {
            text-align: right;
        }
    }
}

/* states */

#{$wg} {
    &-hostelList {
        @include breakpoint (only-sm) {
            .hostelTile {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                height: 100%;
            }
        }
    }

    &-selectedHostelsTitle {
        @media (min-width: $screen-sm-min ) and (max-width: $screen-sm-max ) {
            font-size: pxToRem(28);
        }

        @media (max-width: $screen-xs-max ) {
            font-size: pxToRem(22);
        }

    }
}
