
$wg: ".yhaPageTabs";
@keyframes spinner-border {
    to { transform: rotate(360deg) }
}

#{$wg} {

    .row {
        background-color: #fff;
        padding-top: 0;
    }

    .tabs {
        padding-bottom: 24px;
        ul {
            display: flex !important;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            z-index: -1;

            li {
                flex: 0 0 auto;
                list-style: none;

                &:first-child .tab {
                    border-left-width: 0;

                }
            }
        }

        a {
            color: $grayDark;
            font-weight: 500;
            text-decoration: none !important;

            &:hover,
            &:active,
            &:focus {
                color: $grayDark !important;
            }
        }

        .tab {
            margin: 0;

            &.active {
                color: $lightGreen;
                span {
                    border-bottom-color: $lightGreen;
                }
            }

            span {
                border-bottom: 3px solid transparent;
                display: inline-block;
                padding: 16px 0;
            }
        }

        .selected-label {

            &.showSpinner {
                position: relative;
                &::before {
                    content: "";
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    right: 50px;
                    top: 12px;
                    vertical-align: -0.125em;
                    border: 3px solid currentColor;
                    border-right-color: transparent;
                    border-radius: 50%;
                    -webkit-animation: .75s linear infinite spinner-border;
                    animation: .75s linear infinite spinner-border;
                }
            }

            &.open ~ ul {
                z-index: 4;
            }

            .tab {
                padding-left: 0;
                padding-right: 0;
                background: transparent;
                font-weight: bold;
            }

            span {
                padding: 0;
            }
        }
    }

}


/* States */

@media (max-width: $screen-sm-max) {

    .yhaPageTabs .row {
        margin: 0 -15px;
    }

    .yhaPageTabs .tabs .tab {
        background: #fff;
        border-bottom: 0 none;
        display: inline-block;
        height: 48px;
        text-align: left;
        width: 100%;
        padding: 1px 12px;

        img {
            margin: 5px;
            width: 40px;

            &.inactive {
                display: inline-block;
            }

            &.active {
                display: none;
            }
        }

        &.active {
            height: 50px;

            img {

                &.inactive {
                    display: none;
                }

                &.active {
                    display: inline-block;
                }
            }
        }
    }

    .yhaPageTabs .tabs .selected-label {

        img.inactive {
            display: none;
        }

        .tab.active {
            height: 48px;
        }

        .tab {

            img.active {
                display: inline-block;
            }
        }
    }
}


@media (max-width: $screen-sm-max) {

    .yhaPageTabs {
        margin: 0;
    }

    .yhaPageTabs .tabs-wrap .tabs {
        background-color: #fff;
        display: inline-block;
        margin-bottom: 30px;
        width: 100%;
        padding: 30px 0 0;

        > a {
            width: 100%;
        }

        ul li {
            &:first-child .tab,
            &:last-child .tab {
                width: 100%;
                border-radius: 0;
            }
        }
    }

    .tabs-wrap {
        background-color: $white;
        display: inline-block;
        width: 100%;
    }
}

@media (min-width: $screen-md-min) {

    .yhaPageTabs .tabs ul {
        li {
            border-left: 0 none;
            padding: 0;


            &.active {
                display: block !important;

                .tab {
                    display: inline-block !important;

                    img.active {
                        display: block;
                    }

                    img.inactive {
                        display: none;
                    }
                }
            }
        }
    }
}
