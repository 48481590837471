// Common styles for all hostel tile types
.hostelTilesControl {
    .orderBy {
        margin: 28px 0px;
        text-align: center;
        font-size: 1.6rem;

        .orderByLabel {
            padding-right: 0px;
            border-right: none;
        }

        span {
            margin-right: 30px;
            padding-right: 30px;
            border-right: 1px solid $lightTextColor;
        }

        span:last-child {
            margin-right: 0px;
            padding-right: 0px;
            border-right: none;
        }

        span.current, span.current a {
            color: $baseTextColor;
            text-decoration: none;
            font-weight: bold;
        }

        & > div {
            display: inline-block;
        }

        .linkDropdown {
            display: none;
        }
    }
}

.hostelListItem {

    .yhaFlag {
        position: absolute;
        top: 15px;
        right: 30px;
    }

    .hostelImageContainer {
        min-height: 175px;
        position: relative;
        background: #e3e3e3;
    }

    .hostelImage {
        width: 100%;
    }

    .hostelName {
        .link {
            display: table;
            color: #000;
        }

        .link:hover {
            color: #2a6496;
        }

        .text {
            display: table-cell;
            vertical-align: middle;
            color: $grayDark;
        }
    }

    .popover-content {
        color: #666;
    }

    .hostelLocation {
        color: $lightTextColor;
        margin: 4px 0px;
    }

    .buttons {
        position: absolute;
        bottom: 0;
        left: 15px;
        right: 15px;

		.btn.details {
            display: block;
            width: 100%;
            padding: 8px 8px;
        }

        .btn.bookNow,
        .redPlayButton {
            display: none;
        }
    }
}

.hostelTile {

    .ribon-wrap {
        position: absolute;
        width: 100%;
        top: 0;
        right: 0;
        z-index: 1;
    }

    .b-rent-a-yha-icon {
        position: relative;
        top: 3px;
        width: 94px;
        height: 22px;
    }

    .hostelImageContainer {
        .yhaLogo {
            width: 40px;
            height: 40px;
            background-image: url('#{$imagePath}logos/yhaLogoSmall.png');
            background-size: cover;
            margin-right: 5px;
            position:absolute;
            bottom: 10px;
            left: 10px;
        }

        .exploreLogo {
            width: 40px;
            height: 40px;
            background-image: url('#{$imagePath}generic/ic_hostel_type_explore.png');
            background-size: cover;
            margin-right: 5px;
            position:absolute;
            top: 120px;
            left: 10px;
        }
    }
}

.hostelTile {
    .hostelName {
        .yhaLogo {
            width: 40px;
            height: 40px;
            background-image: url('#{$imagePath}logos/yhaLogoSmall.png');
            background-size: cover;
            margin-right: 5px;
        }

        .exploreLogo {
            width: 40px;
            height: 40px;
            background-image: url('#{$imagePath}generic/ic_hostel_type_explore.png');
            background-size: cover;
            margin-right: 5px;
        }
    }
}

.nearbyHostelsItem {
    padding-bottom: 44px;

    .distance {
        text-align: center;
        padding: 10px 0px;
        font-weight: bold;
        color: #999;
    }

    img.hostel {
        width: 100%;
    }

    .hostelName {
        text-align: center;
        color: #464646;
        font-size: 2.0rem;
        text-transform: uppercase;
        margin: 6px 0px;
        width: 100%;
    }

    .location {
        text-align: center;
        color: #9F9F9F;
        margin-bottom: 10px;
    }

    .viewDetails {
        position: absolute;
        bottom: 0;
        left: 15px;
        right: 15px;

        .btn {
            width: 100%;
            display: block;
        }
    }

    .image {
        position: relative;

        .yhaFlag {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }
}

.groupHostelTile {
    display: block;
    height: 100%;

    @media (min-width: $screen-sm-min) {
        padding-bottom: 44px;
    }

    .hostelImage {
        min-height: 167px;
        width: 100%;
    }

    .yhaFlag {
        position: absolute;
        top: 15px;
        right: 30px;
        z-index: 1;
    }

    .hostelImageContainer {
        position: relative;
    }

    .hostelName {
        font-weight: bold;
        font-size: 1.6rem;
        margin-top: 10px;
        display: table;
        height: 40px;

        .text {
            display: table-cell;
            vertical-align: middle;
            color: black;
            font-size: 2.0rem;
            color: $grayDark;
        }

        .text:hover {
            color: #107ac5;
        }

        a {
            display: table;
        }
    }

    .hostelLocation {
        color: $lightTextColor;
        margin: 4px 0px;
    }

    .buttons {
        position: absolute;
        bottom: 0px;
        left: 15px;
        right: 15px;

        .btn-secondary {
            // padding: 8px 16px;
        }

        .redPlayButton {
            width: 44px;
            height: auto;
        }

        .rentAYhaButton {
            display: block;
            text-decoration: none;
            background-color: #3F9C35;

            .buttonBackground {
                position: relative;
                margin-top: 10px;
            }
        }
    }
}

.hostel-details-wrapper {
    margin-bottom: 20px;
}

.hostelTilesControl {

    @include breakpoint(only-xs) {
        .orderBy {

            .linkList, .orderByLabel {
                display: none;
            }

            .linkDropdown {
                display: block;
            }
        }
    }
}

