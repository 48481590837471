$pg: ".pgEpackConfirmationPage";

#{$pg} {
    color: $baseTextColor;
    padding-bottom: 40px;

    .stepper {
        padding: 25px 0;
    }
    .heading {
        padding-top: 40px;
    }
    .searchCta {
        margin: 30px 0;
    }

    .searchKeywords {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    .searchButton {
        position: absolute;
        right: 5px;
        top: 5px;
        width: 40px;
        height: 40px;
        background: transparent;
        border: none;
    }
    .badgeItemBlock {
        margin: 50px;
        background-color: #f0f0ee;
        padding: 40px 40px 10px 40px;
        min-height: 350px;
    }
    .termsAndConditionsBlock {
        height: auto;
    }
    .gotoMyYha {
        margin-top: 25px;
    }

    .gotoMyYhaBtn {
        width: 200px;
    }
}
