﻿$compactorBackground: #f0f0ee;
$compactorIconBackground: $lightGreen;

.yhaCompactor {
	.content {
		display: none;
	}

	.trigger {
		cursor: pointer;
	}
}

.yhaCompactor.open {
	.content {
		display: block;
	}
}

@media (max-width: $screen-sm-max) {

    .mob-dropdown {
        height: 50px;
        color: $lightTextColor;
        cursor: pointer;
        position: relative;
        font-family: $FuturaCondensedFontStack;
        font-size: 20px;
        background: $compactorBackground;

        .selected-label {
            border: 1px solid $formInputElementBorderColor;
            color: $baseTextColor;
            display: block;
            font-weight: bold;
            height: 50px;
            line-height: 50px;
            width: 100%;
            padding-left: 20px;
            color: $grayDark;
            text-transform: capitalize;

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 44px;
                height: 50px;
                background: url('#{$imagePath}icons/icon-chevron-down_white.svg') no-repeat 95% 50% $compactorIconBackground;
                background-size: 44px 14px;
            }

            &.open::after {
                background: url('#{$imagePath}icons/icon-chevron-up_white.svg') no-repeat 50% 50% $compactorIconBackground;
            }

            a {
                width: calc(100% - 60px);
            }

            .tab.active {
            	display: block !important; // ensure to display label at all time
                background-color: $compactorBackground;
            }

            img {
                margin: 5px;
                width: 40px;
            }
        }
    }

	.mob-dropdown .orderBy-label {
		display: none;
	}

    .mob-dropdown ul {
        display: none;
        list-style: none;
        margin: 0;
        min-width: 100%;
        padding-left: 0;
        position: absolute;
        top: 54px;
        z-index: 5;
        height: 0;
        opacity: 0;
         box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.3);

        li {

            border: 1px solid $formInputElementBorderColor;
            border-width: 0 1px 1px;
            background-color: $white;
            height: 50px;
            line-height: 50px;
            width: 100%;

            a {
                display: block;
                padding-left: 10px;
                width: 100%;

                .tab {
                    width: 100%;
                    border-left: 0;

                    &.active {
                        height: 50px;
                    }

                    span {
                        line-height: 50px;
                        padding: 0 !important;
                    }
                }
            }

            &:first-child .tab,
            &:last-child .tab {
                width: 100%;
                border-radius: 0;
            }

            &.active {
                border-bottom: 0 none;
                display: none;
            }
        }
    }
}

@media (min-width: $screen-md-min) {

	.mob-dropdown .orderBy-label {
		display: inline-block;
		vertical-align: top;
	}

	.mob-dropdown ul {

		/* reset for desktop view */
		display: inline-block !important;
		height: auto !important;
		opacity: 1 !important;
		margin-bottom: 0;
		padding-left: 0;

		li {
			display: block !important;
            flex: 1 0 auto;
			list-style: none;
            margin: 0;
			padding: 0 0 16px 0;
            text-align: center;

			&:first-child {
				border-left: 0 none;
			}
		}
	}

}
