

// embeded video in modal
.youtube-lightbox .modal-dialog {
	margin: 50px auto;
	padding: 0 15px;
	width: 700px;
	max-width: 100%;
}

.modal-dialog .embeded-video {
    height: 0;
    padding-bottom: 56.25%;
	position: relative;
}

.modal-dialog .embeded-video iframe {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.modal-dialog .modal-title {
    text-transform: none;
}

#loginModal {
    z-index: 20;

    @media (max-width: $screen-sm-max) {
        padding-right: 0 !important;
    }
    &::before {
        position: fixed;
        display: block;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba($color: #000000, $alpha: 0.5);
    }

    .modal-dialog {
        margin: auto;
        margin-top: 50px;
        max-width: 368px;

        @media (max-width: $screen-xs-max) {
            padding: 16px;
        }

        .auth0-lock.auth0-lock {
            &.auth0-lock-opened-in-frame {
                width: 100% !important;
            }

            .auth0-lock-widget {
                width: 100%;
            }
        }
    }
}


.header-content {
    .modal-open & {
        position: relative;
        z-index: 0;
    }
}
