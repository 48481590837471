﻿$compactorBackground: #f0f0ee;
$compactorIconBackground: $lightGreen;
$compactorColor: $grayDark;

.b-compactor {
    width: 100%;
    display: block;
    margin-bottom: 15px;
    position: relative;

    &__trigger {
        background: $compactorBackground;
        color: $compactorColor;
        padding: 11px 80px 11px 30px;

        //expand icons
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 44px;
            height: 44px;
            background: url('#{$imagePath}icons/icon-chevron-down_white.svg') no-repeat 50% 50% $compactorIconBackground;
            background-size: 24px 14px;
        }
    }

    &__heading {
        font-size: 2rem;
        color: $grayDark;
        margin: 0;
    }


    &__content {
        margin-top: 26px;
    }

    &__state-container {
        position: relative;
    }

    &__state-name {
        margin-right: 57px;
        font-size: 1.6rem;
    }

    &__state-icon {
        position: absolute;
        top: -10px;
        right: 0;
    }

    &__details {
        font-size: 1.6rem;
        font-family: $FuturaRegularFontStack;
    }
    //modifiers
    &.open .trigger {
        &::after {
            background-image: url('#{$imagePath}icons/icon-chevron-up_white.svg');
        }
    }
}
