// Self Modern
@font-face {
    font-family: 'Self Modern';
    src: url(#{$font-path + 'self-modern/SelfModern-Book.woff2'}) format('woff2'),
        url(#{$font-path + 'self-modern/SelfModern-Book.woff'}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Self Modern';
    src: url(#{$font-path + 'self-modern/SelfModern-Italic.woff2'}) format('woff2'),
        url(#{$font-path + 'self-modern/SelfModern-Italic.woff'}) format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Self Modern';
    src: url(#{$font-path + 'self-modern/SelfModern-Regular.woff2'}) format('woff2'),
        url(#{$font-path + 'self-modern/SelfModern-Regular.woff'}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Self Modern';
    src: url(#{$font-path + 'self-modern/SelfModern-SemiBold.woff2'}) format('woff2'),
        url(#{$font-path + 'self-modern/SelfModern-SemiBold.woff'}) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Self Modern';
    src: url(#{$font-path + 'self-modern/SelfModern-Bold.woff2'}) format('woff2'),
        url(#{$font-path + 'self-modern/SelfModern-Bold.woff'}) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

// GT America
@font-face {
    font-family: 'GT America';
    src: url(#{$font-path + 'gt-america-std/GTAmericaTrial-BdIt.woff2'}) format('woff2'),
        url(#{$font-path + 'gt-america-std/GTAmericaTrial-BdIt.woff'}) format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url(#{$font-path + 'gt-america-std/GTAmericaTrial-BlIt.woff2'}) format('woff2'),
        url(#{$font-path + 'gt-america-std/GTAmericaTrial-BlIt.woff'}) format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url(#{$font-path + 'gt-america-std/GTAmericaTrial-Bl.woff2'}) format('woff2'),
        url(#{$font-path + 'gt-america-std/GTAmericaTrial-Bl.woff'}) format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url(#{$font-path + 'gt-america-std/GTAmericaTrial-LtIt.woff2'}) format('woff2'),
        url(#{$font-path + 'gt-america-std/GTAmericaTrial-LtIt.woff'}) format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url(#{$font-path + 'gt-america-std/GTAmericaTrial-Lt.woff2'}) format('woff2'),
        url(#{$font-path + 'gt-america-std/GTAmericaTrial-Lt.woff'}) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url(#{$font-path + 'gt-america-std/GTAmericaTrial-Md.woff2'}) format('woff2'),
        url(#{$font-path + 'gt-america-std/GTAmericaTrial-Md.woff'}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url(#{$font-path + 'gt-america-std/GTAmericaTrial-MdIt.woff2'}) format('woff2'),
        url(#{$font-path + 'gt-america-std/GTAmericaTrial-MdIt.woff'}) format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url(#{$font-path + 'gt-america-std/GTAmericaTrial-Bd.woff2'}) format('woff2'),
        url(#{$font-path + 'gt-america-std/GTAmericaTrial-Bd.woff'}) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url(#{$font-path + 'gt-america-std/GTAmericaTrial-Th.woff2'}) format('woff2'),
        url(#{$font-path + 'gt-america-std/GTAmericaTrial-Th.woff'}) format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url(#{$font-path + 'gt-america-std/GTAmericaTrial-ThIt.woff2'}) format('woff2'),
        url(#{$font-path + 'gt-america-std/GTAmericaTrial-ThIt.woff'}) format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url(#{$font-path + 'gt-america-std/GTAmericaTrial-RgIt.woff2'}) format('woff2'),
        url(#{$font-path + 'gt-america-std/GTAmericaTrial-RgIt.woff'}) format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url(#{$font-path + 'gt-america-std/GTAmericaTrial-Rg.woff2'}) format('woff2'),
        url(#{$font-path + 'gt-america-std/GTAmericaTrial-Rg.woff'}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url(#{$font-path + 'gt-america-std/GTAmericaTrial-ULt.woff2'}) format('woff2'),
        url(#{$font-path + 'gt-america-std/GTAmericaTrial-ULt.woff'}) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT America';
    src: url(#{$font-path + 'gt-america-std/GTAmericaTrial-ULtIt.woff2'}) format('woff2'),
        url(#{$font-path + 'gt-america-std/GTAmericaTrial-ULtIt.woff'}) format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

