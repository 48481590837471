
$pg: ".pgUserMembershipCardPage";

#{$pg} {
    color: $baseTextColor;

    .membership-card, .save-to-app, .download-apps {
        margin-top: 20px;
        margin-bottom: 30px;
        border-top: 1px solid #ddd;

    }
    .member-card {
        background-color: #ebf1f9;
        border-radius: 15px;
        color: $defaultButtonBackgroundColor;
        background-size: cover;
        background-position: center;
        height: 220px;
        position: relative;
        width: 340px;
        margin: auto;
        border: 1px solid $disabledGray;
        text-align: center;
        font-family: $FuturaHeavyFontStack;
        @include drop-shadow(5px, 5px, 15px, 1px, $gray);


    }
    .card-logo-left {
        position: absolute;
        width: 48px;
        height: auto;
        left: 15px;
        top: 10px;

        img {
            max-width: 100%;
            margin-bottom: 15px;
        }
    }
    .card-logo-right {
        position: absolute;
        width: 48px;
        height: auto;
        right: 15px;
        top: 10px;

        img {
            max-width: 100%;
        }
    }

    .card-heading {
        font-size: 22px;
        margin-top: 25px;
    }

    .card-name {
        margin-top: 20px;
        font-size: 16px;
    }
    .card-number-prefix {
        font-size: 14px;
        font-family: $FuturaRegularFontStack;
    }
    .card-number {
        margin-top: 5px;
        font-size: 15px;
    }
    .card-extra {
        text-align: center;
        margin-top: 20px;
        font-size: 16px;

        div {

            &:first-child {
                padding-bottom: 10px;
            }
        }
    }

    .download-apps, .badge-items-wrap  {
        padding: 20px 0;
    }

    &-cardList {
        padding: 20px 0;
    }

    .digitalCard {
        margin: 20px 0;

        &-link {
            display: block;
            text-align: center;
        }
        
        &-img {
            height: 130px;
            margin-bottom: 10px;
        }

        &-text {
            display: block;
        }

        &-message {
            font-size: 12px;
            font-style: italic;
            text-align: center;
            display: block;
            color: $orange;
        }

        @media (max-width: $screen-sm-min) {
            padding-bottom: 30px;

            &-img {
                height: auto;
                max-width: 180px;
            }
        }
    }

}

// States

#{$pg} {

    @media (max-width: $screen-xxs-max) {

        .member-card {
            width: 290px;
            height: 200px;
        }
        .card-extra {
            margin-top: 15px;
        }
    }
}
