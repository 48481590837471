
$blk: ".epackBlock";

#{$blk} {

    &-item-image {
        padding-left: 20px;
        float: right;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li {
        background-image: url('#{$imagePath}lists/blueSquare.png');
        background-repeat: no-repeat;
        background-position: 0 7px;
        padding-left: 17px;
        margin-bottom: 8px;
    }

}


/* States */


//#{$blk} {
//
//}
//
