﻿.basicFlagLarge {
    position: relative;
    background: url('#{$imagePath}flags/pricingFlags.png') -30px -30px;
    width: 124px;
    height: 142px;

    .perUnit {
        color: white;
        text-align: center;
        font-weight: bold;
        font-size: 3.4rem;
        padding-top: 40px;
    }

    .unit {
        color: white;
        text-align: center;
        font-size: 1.6rem;
    }
}

.basicFlagSmall {
    position: relative;
    background: url('#{$imagePath}hostel-tile-pricing.svg');
    background-size: 86px;
    height: 86px;
    width: 86px;

    &.whiskers {
        background-image: url('#{$imagePath}hostel-tile-pricing-whiskers.svg');
    }

    .perUnit {
        padding-top: 29px;
        text-align: center;
        font-weight: bold;
        color: #000;
        font-size: 2.0rem;
    }

    .unit {
        margin-top: -4px;
        color: #000;
        text-align: center;
        font-size: 1.2rem;
    }

    .groupSize {
        padding: 4px;
        margin-top: 4px;
        background-color: #3F9C35;
        color: #fff;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-family: $FuturaCondensedFontStack;
        text-align: center;
    }
}

.hostelGroupsFlagSmall {
    position: relative;
    background: url('#{$imagePath}flags/pricingFlags.png') -670px -310px;
    width: 80px;
    height: 154px;

    .perUnit {
        padding-top: 22px;
        color: #fff;
        font-size: 2.0rem;
        font-weight: bold;
        text-align: center;
    }

    .unit {
        color: #fff;
        text-align: center;
        font-size: 1.2rem;
        margin-top: -3px;
    }

    .groupSize {
        text-align: center;
        color: #fff;
        font-size: 2.0rem;
        font-weight: bold;
        padding-top: 40px;
    }
}

.hostelGroupsFlagLarge {
    position: relative;
    background: url('#{$imagePath}flags/pricingFlags.png') -670px -30px;
    width: 124px;
    height: 236px;

    .perUnit {
        padding-top: 40px;
        color: #fff;
        font-size: 3.0rem;
        font-weight: bold;
        text-align: center;
    }

    .unit {
        color: #fff;
        text-align: center;
        font-size: 1.8rem;
    }

    .groupSize {
        text-align: center;
        color: #fff;
        font-size: 3.0rem;
        font-weight: bold;
        padding-top: 60px;
    }
}

.ratingBadgeFlagSmall {
    position: relative;
    background: url('#{$imagePath}hostel-tile-pricing.svg');
    background-size: 86px;
    height: 86px;
    width: 86px;

    &.whiskers {
        background-image: url('#{$imagePath}hostel-tile-pricing-whiskers.svg');
    }

    .ratingBadgeSmall {
        position: absolute;
        top: 78px;
        left: 15px;
    }

    .perUnit {
        padding-top: 29px;
        text-align: center;
        font-weight: bold;
        color: #000;
        font-size: 2.0rem;
    }

    .unit {
        margin-top: -4px;
        color: #000;
        text-align: center;
        font-size: 1.2rem;
    }
}

.includedCost {
    background: url('#{$imagePath}flags/pricingFlags.png') -510px -350px;

    .ratingBadgeSmall {
        top: 40px;
    }

    .flagCostIncluded {
        padding-top: 15px;
    }
}

.rentAYhaFlagLarge {
    position: relative;
    background: url('#{$imagePath}flags/pricingFlags.png') -830px -30px;
    width: 124px;
    height: 236px;

    .perNight {
        text-align: center;
        color: #fff;
        font-weight: bold;
        font-size: 3.0rem;
        padding-top: 39px;
    }

    .groupSize {
        position: absolute;
        top: 141px;
        left: 83px;
        font-size: 1.8rem;
        font-weight: bold;
        color: #fff;
    }
}

.rentAYhaFlagSmall {
    position: relative;
    background: url('#{$imagePath}flags/pricingFlags.png') -830px -310px;
    height: 154px;
    width: 80px;

    .perNight {
        text-align: center;
        color: #fff;
        font-weight: bold;
        font-size: 2.0rem;
        padding-top: 28px;
    }

    .groupSize {
        position: absolute;
        top: 89px;
        left: 55px;
        color: #fff;
        font-weight: bold;
    }
}

.specialsFlagSmall {
    position: relative;
    background: url('#{$imagePath}flags/pricingFlags.png') -990px -310px;
    width: 80px;
    height: 154px;

    .perUnit {
        text-align: center;
        color: #fff;
        font-size: 2.0rem;
        font-weight: bold;
        padding-top: 25px;
    }

    .unit {
        color: #fff;
        text-align: center;
        font-size: 1.2rem;
    }

    .save {
        text-align: center;
        color: #464646;
        font-size: 2.0rem;
        font-weight: bold;
        padding-top: 37px;
    }
}
