@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.u-loaderOverlay {
  display: none;
  position: absolute;
  background-color: rgba(#fff, 0.7);
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &.is-loading,
  .is-loading & {
      display: block;
  }
}

.u-loader {
  display: none;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin-left: -30px;
    margin-top: -30px;
    border-radius: 100%;
    border: 10px solid transparent;
    border-top-color: $defaultButtonBackgroundColor;
  }

  &:before {
    z-index: 10;
    animation: spin 1s infinite;
  }

  &:after {
    border: 10px solid #ccc;
  }

  &.is-loading,
  .is-loading & {
      display: block;
  }

  &--top {
    &:before,
    &:after {
      top: 100px;
    }
  }
}
