$blk: ".sidebarYoutubeBlock";

#{$blk} {
    position: relative;
    //padding-bottom: 56.25%;
    //padding-top: 30px;
    overflow: hidden;
    //height: 100%;

    &.video-item-iframe {
        z-index: 10;
    }

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        vertical-align: middle;
    }

    .video-item__play-btn {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -25px;
        margin-top: -25px;
        width: 50px;
        cursor: pointer;
    }

    .video-item-img {
        .img-responsive {
            width: 100%;
        }
    }

}

