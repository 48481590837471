$blk: ".hostelTravellerPhotoGalleryBlock";

#{$blk} {
    margin-bottom: 40px;
    
    .imageGallery {

        &-content, &-frame, &-slide {
            height: 240px;
        }

        .description-photo {
            top: 0;
            left: 0;
            font-size: 1.2rem;
        }
    }
}
