/*GULP_ENV_START*/
$environment: "prod";
/*GULP_ENV_END*/
/*GULP_UIBUILD_PATH_START*/
$build-path: "/UIBuild/theme1/1-1-30/";
/*GULP_UIBUILD_PATH_END*/


// CONSTANTS //

// fonts
$font-primary: 'GT America';
$font-secondary: 'Self Modern';

// Breakpoints
$screen-xxs-max: 320px;
$screen-xssm-min: 360px;	//iPhone 6/7/8 & Galaxy S5
$screen-xssm-max: 410px;	//iPhone 6/7/8 & Galaxy S5
$screen-xsmd-max: 480px;
$screen-xs-max: 767px;
$screen-sm-min: 768px;
$screen-sm-max: 991px;
$screen-md-min: 992px;
$screen-md-max: 1199px;
$screen-lg-min: 1200px;

$mainNavbarHeight: 90px;
$mobileHeaderHeight: 64px;


$white: 		#ffffff;
$red:			#F15F22;
$maroon : 		#B47069;
$blue: 			#3A74C6;
$green: 		#3D805F;
$grayLight: 	#888888;
$grayDark: 		#474747;
$lightGreen:	#2AB472;


$yellowLight: 	#ffcc13;
$yellow:		#fda327;

$blueDark: 		#428bca;
$blueLight:		#00A388;

$neonGreen:		#c4e510;
$orange: 		#ee4601;
$orangeDark:	#cf3d15;
$orangeBgTop: 	#FF9100;
$orangeBgBottom:#FF8001;
$roomRemaining: #f28e47;

$grayDarkest: 	#232323;
$gray: 			#666;
$grayMedium:	#757575;
$grayLighter:	#eee;
$grayLightest:	#f0f0ee;
$grayFaded: 	#f8f8f7;

$formInputBorder: 		#ddd;
$formInputBorderError: 	#c00;

$btnPrimaryTop:			$yellowLight;
$btnPrimaryBottom: 		$yellow;
$btnSecondaryTop:		#d7ee00;
$btnSecondaryBottom:	#8ec100;

$disabledGray: #ccc;

$calendarBG: #D9F6F8;
$calendarDateDisabled: #0083BE;
$calendarHeading: $calendarDateDisabled;

// Reskin

// Brand colours
// Primary
$brand-primary-orange: #FE502D;
$brand-primary-green: #3F9C35;
$brand-primary-blue: #0083BE;
$brand-primary-lightblue: #51BFDB;

// Secondary
$brand-secondary-vermilion: #FE502D;
$brand-secondary-powdered-orange: #F9704E;
$brand-secondary-light-orange: #FFB059;
$brand-secondary-blue-lagoon: #00CEDB;
$brand-secondary-mint-green: #55E18E;
$brand-secondary-emerald-green: #00A388;
$brand-secondary-midnight-blue: #10479C;
$brand-secondary-deep-purple: #5252BA;

// States
$brand-state-tas: #fe502d;
$brand-state-secondary-tas: #fa7238;
$brand-state-wa: #f9704e;
$brand-state-secondary-wa: #491647;
$brand-state-qld: #ffb059;
$brand-state-secondary-qld: #f0472a;
$brand-state-nsw: #00cedb;
$brand-state-secondary-nsw: #0b5558;
$brand-state-sa: #55e18e;
$brand-state-secondary-sa: #e9f497;
$brand-state-nt: #00a388;
$brand-state-secondary-nt: #93feb7;
$brand-state-vic: #10479c;
$brand-state-secondary-vic: #57c6b8;
$brand-state-act: #5252ba;
$brand-state-secondary-act: #a3c5ff;


// Overrides to Bootstrap button styles
$defaultButtonTextColor: white;
$defaultButtonBackgroundColor: #007C67;
$defaultButtonHoverBackgroundColor: #00A388;

$primaryButtonTextColor: white;
$primaryButtonBackgroundColor: $defaultButtonBackgroundColor;
$primaryButtonHoverBackgroundColor: $defaultButtonHoverBackgroundColor;

$secondaryButtonTextColor: white;
$secondaryButtonBackgroundColor: #007C67;
$secondaryButtonHoverBackgroundColor: #276021;

$tertiaryButtonTextColor: white;
$tertiaryButtonBackgroundColor: #007C67;

$navButtonBackgroundColor: #00CEDB;
$navButtonHoverBackgroundColor: #00A1AB;

$navSecondaryBackgroundColor: #FFB059;
$navSecondaryHoverBackgroundColor: #FF8600;

$btnArrowBgColor: #FF8600;
$btnArrowHoverBgColor: $navButtonHoverBackgroundColor;

/** TYPE **/
$font-path: "/fonts/";
@if($environment == "prod") {
	$font-path: $build-path + "fonts/";
}

// Font stacks

$sansFontStack: "Helvetica Neue",Arial,sans-serif;
$serifFontStack: "Times New Roman",Times,serif;
$foreignlang: "Microsoft YaHei New", "Microsoft Yahei", Arial, Helvetica;
$jpFont: "メイリオ",Meiryo,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro",Osaka,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;
$MuseoSansRounded900RegularFontStack:"Museo Sans Rounded 900 Regular",$sansFontStack;
$MuseoSansRounded900FontStack:"Museo Sans Rounded 900",$sansFontStack;
$FuturaRegularFontStack: "Futura LT W05 Book",$sansFontStack;
$FuturaMediumFontStack: "Futura LT W05 Medium",$sansFontStack;
$FuturaCondensedFontStack: "Futura LT W05 Bold Condensed",$sansFontStack;
$FuturaBoldCondensedFontStack: "Futura LT W05 Bold Condensed",$sansFontStack;
$FuturaHeavyFontStack: "Futura LT W05 Heavy",$sansFontStack;
$LunaRegularFontStack: "lunaregular",$sansFontStack;




$screen-xxs: 320px;
$screen-xssm-min: 360px;	//iPhone 6/7/8/X & Galaxy S5
$screen-xssm-max: 410px;	//iPhone 6/7/8/X & Galaxy S5
$screen-xsmd-max: 480px;
$screen-xs-max: 767px;
$screen-sm-min: 768px;
$screen-sm-max: 991px;
$screen-md-min: 992px;
$screen-md-max: 1199px;
$screen-lg-min: 1200px;

//$imagePath: '/ui-assets/images';
//$imagePath: '/images';

$imagePath: "/images/";
@if($environment == "prod") {
	$imagePath: $build-path + "images/";
}


// Brand colours
// Primary
$brand-primary-orange: #FE502D;
$brand-primary-green: #3F9C35;
$brand-primary-blue: #0083BE;
$brand-primary-lightblue: #51BFDB;

// Secondary
$brand-secondary-vermilion: #FE502D;
$brand-secondary-powdered-orange: #F9704E;
$brand-secondary-light-orange: #FFB059;
$brand-secondary-blue-lagoon: #00CEDB;
$brand-secondary-mint-green: #55E18E;
$brand-secondary-emerald-green: #00A388;
$brand-secondary-midnight-blue: #10479C;
$brand-secondary-deep-purple: #5252BA;

// States
$brand-state-tas: #fe502d;
$brand-state-secondary-tas: #fa7238;
$brand-state-wa: #f9704e;
$brand-state-secondary-wa: #491647;
$brand-state-qld: #ffb059;
$brand-state-secondary-qld: #f0472a;
$brand-state-nsw: #00cedb;
$brand-state-secondary-nsw: #0b5558;
$brand-state-sa: #55e18e;
$brand-state-secondary-sa: #e9f497;
$brand-state-nt: #00a388;
$brand-state-secondary-nt: #93feb7;
$brand-state-vic: #10479c;
$brand-state-secondary-vic: #57c6b8;
$brand-state-act: #5252ba;
$brand-state-secondary-act: #a3c5ff;

// Map various shared colours to brand colours
$primary-button-colour: $brand-secondary-light-orange;
$navigation-button-colour: $brand-secondary-blue-lagoon;


// TODO: Old brand colours - these should be removed & replaced with ones above
$sgRed: 		#EC1939;
$sgLightGreen: 	#71BE43;
$sgDarkGreen: 	#004631;
$sgYellow: 		#FFCA03;

$white: 		#fff;

$neonGreen:		#C4E510;
//$lightGreen:	#5CAC34;

$blueDark: 		#107BC5;
//$blue: 			#00A6E4;
$lightBlue:		#00a5e3;

$orange: 		#EE4601;
$orangeDark:	#cf3d15;

$grayDarkest: 	#232323;
$gray: 			#666;
$grayMedium:	#757575;
$grayLighter:	#eee;
$grayLightest:	#f0f0ee;

$formInputElementBorderColor: 		#ddd;
$formInputElementErrorBorderColor: 	#c00;

/* text */
$textRed: 			$red;
$textLightGreen: 	$lightGreen;
$textLightGrey: 	$grayLight;
$baseTextColor: 	$gray;
$darkTextColor: 	$grayDark;
$lightTextColor: 	$grayLight;
$footerTextColor: 	$white;
$introParagraph: 	$grayMedium;


/* backgruond-color */
$bodyBackgroundColor:	$white;
$footerSocialBackgroundColor: #edecec;


/* links */
$baseLinkColor: 		$textLightGreen;
$baseLinkColorVisited: 	$baseLinkColor;
$baseLinkColorHover: 	$baseLinkColor;
$baseLinkColorActive: 	$baseLinkColor;


/* Navbar */
$navbarFoldoutBackgroundColor: 				$grayDarkest;
$navbarSelectedItemUnderlineColor: 			$blue;
$navbarBreadcrumbsBackgroundColor: 			#efefef;
$mobileNavBackgroundColor: 					$grayDark;
$mobileNavOpenCompactorBackgroundColor: 	#d8d8d8;


/* compactor */
$compactorClosedBackgroundColor:	$grayLightest;
$compactorClosedTextColor: 			$baseTextColor;
$compactorOpenBackgroundColor: 		$blue;
$compactorOpenTextColor: 			$white;


/* sidebar */
$sidebarBackgroundColor: 				$grayLightest;
$sidebarHighlightedItemBackgroundColor: $grayDark;
$sidebarHighlightedItemTextColor: 		$white;


/* error */
$errorTableCell: 		$orangeDark;
$errorMessageText: 		$orange;
$featureBoxBorderColor: $neonGreen;


/* etc */
$hostelPageHeadBackgroundColor: $grayDark;
$sectionDividerColor: 			$grayLightest;
$backgroundGrey: 				$grayLightest;

$wishlistTextColor:				$brand-primary-orange;
$wishlistHoverColor:			$red;


/**********************************************

	Unique colors DO NOT use elsewhere

***********************************************\

/* buttons gradient*/
$buttonStartColor: 			#DB241F;
$buttonEndColor: 			#A10129;
$secondaryButtonStartColor: #F8F8F7;
$secondaryButtonEndColor: 	#EBEBEA;


/* Travel adventues tell us your story popup  */
$tellUsYourStoryBackgroundColor: 		$white;
$tellUsYourStoryTextColor: 				#3F372C;
$tellUsYourStorySectionBackgroundColor: #F7F2ED;


/* Copied over from yha_site _common.scss */
$horizontalDividerColor: 			rgb(159,145,134);
$formGroupHeadingBackgroundColor: 	#706053;
$formGroupHeadingColor: 			$white;
$formGroupContentBackgroundColor: 	#F7F2ED;
$formGroupContentColor: 			#40372F;


/* Heading  */
$headingRed: 							$textRed;
$headingLightGreen: 					$textLightGreen;
$headerBackgroundFallbackColor: 		#0A8DD3;
$headerBackGroundGradientTopColor: 		#00b0ee;
$headerBackGroundGradientBottomColor: 	$blueDark;
$headerTextColor: 						$white;
$headerLinkColor: 						$baseLinkColor;
$blueHeadingColor: 						$blue;


/* reveiw */
$reviewBg: #e4f5f9;


/* captcha */
$captchaBackgroundColor: #D9EBFD;


/* search gradient */
$searchbarWithMagnifyingGlassBackgroundTop: 	#00B0EE;
$searchbarWithMagnifyingGlassBackgroundBottom: 	#0F7CC7;


/* hostel journey time table */
$australianHostelsJourneyTableBorder: #BBB;


/* calendar */
$calendarBG: 		#D9F6F8;
$calendarLightBG: 	#c1e580;


/* Bookings page */
$availabilityTableHeaderCellBackgroundColor: #4CBDEE;
$availabilityTableBodyCellBackgroundColor: #f7f7f6;
$availabilityTableBodyTextColor: #7f7f7f;
$availabilityTablePreSelectedHeaderCellBackgroundColor: #00a3e5;
$availabilityTablePreSelectedBodyCellBackgroundColor: #d9f1fb;
$availabilityTableFocusElementBorderColor: #0096DD;




%progressbar-current {
	background-color: $blueLight;
	border-color: $blueLight;
	border-left-color: $blueLight;
	color: $white;

		@media(max-width: $screen-sm-max) {
		width: 54%;
		&:after {
		    content: attr(data-label); /* display full name */
		    margin-left: 15px;
		}
	}
}

%progressbar-before-current {
	color: $blueLight;
}

%progressbar-after-current {
	border-left-color: $blueLight;

	.start-icon {
		background-position: -1px -1px;
	}
}

%price-small {
	font-size: 2.0rem;
	line-height: 1;

	.dollar-sign,
	.cent {
		font-size: 65%;
		line-height: 1.3;
		vertical-align: top;
	}
}

%price-large {
	font-size: 3.6rem;
 	font-weight: 700;
 	line-height: 1;

	.dollar-sign,
	.cent {
	 	font-size: 1.8rem;
	 	line-height: 24px;
	 	vertical-align: top;
	}

}


//From AWI
$base-font-size: 16px; //expected base font size from the browser (can't be changed with CSS for media queries)
$goldern-ratio: 10px / $base-font-size; // means we can calculate ems in multiples of 10, which is easier to reason with
// Note: you can use funtion 'em({pixel value})'

// pass through a pixel value and have it converted to em (also used in grid system)
@function em($px) {
    $em: ($px * 0.1);
    //@debug($em, $goldern-ratio);
    @return $em * $goldern-ratio;
}




// RAS search related
$xlt-gry: #f0f0f0;
$lt-gry: #e4e4e4;
$md-gry: #d8d8d8;
$dk-gry: #B1B1B1;
$xdk-gry: #231f20;
$xxlt-clr1: #ece1f2;
$xlt-clr1: #deccf2;
$lt-clr1: #b493d9;
$md-clr1: #622f8d;//#512b7b;
$dk-clr1: #382059;
$xdk-clr1: #200d3c;
$bright-clr1: #9738f0;

$md-clr2: #f8d793;
$dk-clr2: #ffc600;
