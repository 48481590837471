$pg: ".pgTourEnquiryFormPage";

#{$pg} {
    color: $baseTextColor;

    .styled-select {
        display: inline-block;
        margin-bottom: 20px;
        padding-right: 36px;

        select {
            width: 100%;
        }
    }

    label {
        display: block;
        font-weight: bold;
    }

    input[type="text"] {
        margin-bottom: 20px;
                
        &:not(.datePickerField){
            width: 500px !important;
        }
    }

    .tour {
        width: 500px;       
    }

    .mediumSelect {
        width: 185px;         
    }

    .submitButton {
        margin: 20px 0;
    }
    .enquiry {        
        width: 500px !important;
        height: 200px;
    }
    .captchaField {
        display: inline-block;
        background: #DAEBFD;
        border: 2px solid #DAEBFD;
        border-radius: 6px;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 40px;
        width: 348px;
        height: 122px;
    }
    .field-wrapper {
        margin-bottom: 20px;
        max-width: 500px;
    }
    .invalid {
        border: 2px solid #EE4601;
    }
}
