$pg: ".pgToursPage";

#{$pg} {
    color: $baseTextColor;

    .heading {
        @extend .p-heading;
        padding-bottom: .8em;
        color: $grayDark;
        margin: 0;
        width: calc(100% - 85px);

        &-wrap {
            position: relative;
            padding: 20px 0;
        }
    }

    .yhaFlag {
        position: absolute;
        top: 0;
        right: 20px;
        z-index: 1;
    }

    .googleMap-canvas {
        height: 250px;
    }

    .top-content {
        margin-top: 10px;
    }

    .dayBreakdown {
        margin-bottom: 40px;
    }
    .tourDetailsPackageIncludes {
        .content {
            padding: 20px 30px;
            border: 2px solid #f0f0ee;
        }
    }
    .toursDepart {
        margin-top: 30px;
    }
    .toursImage {
        width: 100px;
        height: auto;
    }
    .toursDetails {
        &-table {
            text-align: center;
            font-size: 1.5rem;
            border-spacing: 2px;
            border-collapse: separate;
            width: 100%;
        }

        &-cell {
            text-align: left;
            background: #f8f8f7;
            padding: 10px;
        }
    }
    .toursDepartInfo {
        margin-top: 30px;
    }

    .tourEnquiryBtn,
    .reservationNumberBtn {
        width: 200px;
    }

    .reservationNumberBtn {
        margin-top: 16px;

        @media screen and (min-width: $screen-sm-min) {
            margin-left: 16px;
            margin-top: 0;
        }
    }

    .gotQuestions {
        padding-bottom: 40px;
        margin-bottom: 40px;
        border-bottom: 2px solid #f0f0ee;

        &-btnWrap {
            display: flex;
            flex-direction: column;

            @media screen and (min-width: $screen-sm-min) {
                flex-direction: row;
            }
        }
    }

    .thingsYouShouldKnow {
        margin-bottom: 60px;

        ul {
            @extend .blueSquareList;
        }
    }
}
