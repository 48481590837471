﻿.standardYhaCarousel {
    .headingAndControls .blueTextWhiteBackgroundStyle {
        text-align: center;

        .leftControl {
            float: left;
        }

        .rightControl {
            float: right;
        }
    }

    .headingAndControls .blueHeadingStyle {
        .blueHeading {
            max-width: 986px !important;
            width: 986px;
            margin-left: 52px;
            padding-top: 12px;
            margin-bottom: 20px;

            .blueHeadingRight {
                margin-top: 3px;
            }
        }

        .leftControl {
            position: absolute;
            bottom: 15px;
            left: 15px;
        }

        .rightControl {
            position: absolute;
            bottom: 15px;
            right: 15px;
        }

        .blue-carousel-control {
            min-width: 75px;
            height: 36px;
            text-align: center;
        }

        .carousel-control-icon {
            width: 26px;
            height: 20px;
        }
    }

    .mainCarousel {
        margin: 0px -15px;
        //		width: 100%; // Equals width of each item * 4
        position: relative;
        overflow: hidden;
    }

    .description {
        margin-bottom: 65px;
    }

    ul {
        width: 20000em;
        position: relative;
        /* Optional, required in this case since it's a <ul> element */
        list-style: none;
        margin: 0;
        padding: 0;
        @include display-flex;

        li {
            /* Required only for block elements like <li>'s */
            float: left;
            @include display-flex;
        }
    }

    .carouselItem {
        width: 255px;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        @include display-flex;
        display: block;

        .mainImage {
            width: 100%;
            max-width:100%;
            height: auto;
        }
    }

    .toursAndActivitiesItem {
        width: 255px;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;

        .mainImage {
            width: 100%;
        }

        .mainImageContainer {
            overflow: hidden;
            background: #e3e3e3;
        }

        .yhaFlag {
            position: absolute;
            top: 15px;
            right: 25px;
        }

        .textSection {

            h3 {

                a {
                    font-family: $FuturaBoldCondensedFontStack;
                    font-weight: bold;
                    font-size: 2.0rem;
                    color: #474747;;
                }

                a:hover {
                    color: #2a6496;
                }
            }
        }

        .location {
            color: $lightTextColor;
            margin-bottom: 10px;
        }

        .btn-container {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0 15px;
        }
    }

    .travellerStoriesItem {
        .nameAndDate {
            color: $lightTextColor;
            margin-bottom: 10px;
        }

        a {
            @extend .yellowChevronRight;
        }
    }
}

.standardYhaCarousel:last-child {
    .mainCarousel {
        border-bottom: none;
    }
}

.headingAndControls {
    margin-top: 25px;
}

@media (max-width: $screen-xs-max) {

    .standardYhaCarousel .toursAndActivitiesItem {
        height: auto;
        padding-bottom: 0;

        .textSection {
            height: auto;
            margin-bottom: 0;
        }

        .description {
            margin-bottom: 44px;
        }

        .btn {
            position: static;
            margin-top: 20px;
        }
    }
}
