
$blk: ".marketingPromoBlock";

#{$blk} {

    //background-color: #f0f0ee;
    color: $baseTextColor;

    &-title {
        color: #428bca;
    }

    &-imgLink {
        text-decoration: none;
    }

    &-img {
        width: auto;
        max-width: 100%;
    }

    &-buttons {
        display: block;
        margin: 10px;
    }

}


/* States */


//#{$blk} {
//
//}
//
