.wgfooter {
    &-content {
        padding-top: 34px;
        padding-bottom: 34px;
    }

    &-panel {
        padding-top: 11px;
        padding-bottom: 11px;

        &-bottom .row {
            @media (min-width: $screen-md-min) {
                display: flex;
                align-items: flex-end;
                // width: 100%;
            }
        }
    }

    &-logo {
        &-wrap {
            display: flex;
            gap: 20px;
            width: 100%;
            justify-content: center;

            @media (min-width: $screen-md-min) {
                justify-content: flex-start;
            }
        }
        &-item {
            flex: 0 0  71px;
            height: 71px;
            width: 71px;

            a {
                display: block;
            }

            img.imageLinkBlock-img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    &-socials {
        align-items: center;
        display: flex;
        padding-top: 10px;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;

        @media (min-width: $screen-md-min) {
            justify-content: flex-start;
        }

        .socialMediaLink {
            flex: 0 0  auto;
            width: auto;

            a {
                display: block;
            }

            &.googlePlus svg {
                height: 40px;
            }

            &.pinterest {
                padding: 0 8px;
            }
        }
    }

    &-links {
        color: $grayLight;
        font-size: pxToRem(14);

        a {
            color: $grayLight;
        }

        .divider {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    &-copy {
        font-family: $font-primary;
        font-size: pxToRem(16);
        text-align: center;
        padding-bottom: 16px;
        margin-top: 0;
    }

    &-copyrights {
        color: $grayDark;
        // font-size: pxToRem(14);
        // font-weight: bold;

        @media (min-width: $screen-md-min) {
            margin-left: auto;
        }
    }

    &-title {
        font-family: $font-primary;
        font-size: pxToRem(18);
        font-weight: bold;
        padding-bottom: 1em;
        margin: 0;
    }

    &-menu {
        &-list {
            font-family: $font-primary;
            list-style: none;
            padding: 0;
        }

        &-item {
            padding-bottom: 1em;
        }

        &-link {
            color: $grayDark;
            font-size: pxToRem(16);
        }

        &-secondary {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            margin-top: 8px;

            @media (min-width: $screen-md-min) {
                margin-top: 0;
            }

            .divider {
                display: inline-block;
                padding: 0 10px;
            }

            a {
                color: $grayDark;
            }
        }
    }

    &-col {
        &-left {
            @media (min-width: $screen-md-min) {
                padding-right: 34px;
            }
        }
    }

    .subscribe {
        &-form {
            margin-bottom: 32px;

            @media (min-width: $screen-md-min) {
                margin-bottom: 0;
            }

            &-info {
                display: block;
                font-size: pxToRem(20);
                font-weight: 700;
                padding-bottom: 20px;
                padding-top: 30px;
            }

            label {
                font-weight: normal;
            }

            .formElement {
                height: 50px;
                font-size: 1.6rem;
                padding: 14px 18px;
                color: #000;
                border: 1px solid #ddd;
                margin-bottom: 10px;
                width: 100%;
            }

            .btn {
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    fill: $white;
                }
            }
        }

        &-button {
            font-size: pxToRem(20);

            .b-icon {
                margin-left: 0;
                margin-right: 8px;
            }
        }
    }
}

.footer {
    background-color: $grayDark;
    font-size: 1.4rem;
}

.footer-social {
    background: $footerSocialBackgroundColor;
    padding: 10px 0 6px;
}

.footerTop {
    background-color: $grayDark;
    color: $white;
    padding: 30px;

    & > div {
        padding-bottom: 20px;

        @media (min-width: $screen-md-min) {
            padding-bottom: 0px;
        }
    }

    .socialMediaLink {
        a {
            color: #a5d2f8;
        }
    }
}

.footerBottom {
    background-color: $grayDarkest;
    color: white;
    padding: 15px 0;

    .divider {
        margin: 0px 4px;
    }

    a {
        color: $white;
        text-decoration: none;
    }

    .footerLinks {
        line-height: 38px;
        margin-bottom: 10px;

        @media (min-width: $screen-md-min) {
            margin-bottom: 0;
        }
    }
}

.footer .languageSiteLinks {
    display: none;
}

.footer .footerBlurb {
    .blurb {
        font-size: 1.6rem;
        margin-bottom: 15px;
    }

    .hiLogo {
        margin-bottom: 15px;
        position: relative;

        .ico-hi_logo,
        .ico-yha_logo {
            display: inline-block;
            height: 60px;
            margin-bottom: 5px;
            width: 60px;
            vertical-align: middle
        }

        .ico-yha_logo {
            //margin-top: -18px;
            margin-right: 10px;
        }

        .logo-title {
            display: block;
            vertical-align: middle;
            padding-left: 10px;
        }
    }
}

.facebookLikebox {
    padding-top: 12px;

    .fb-like-box span {
        background-color: #fff;
    }
}

.footer .footerBottom .footerSearch {
    display: inline-block;
    float: left;
    width: 100%;

    .field-group {
        display: inline-block;
        float: left;
        width: calc(100% - 38px);
    }

    input {
        border: 0 none;
        float: left;
        height: 38px;
        padding: 10px 7px;
        width: 100%;
        border-radius: 5px 0px 0px 5px;
    }

    a {
        float: left;
        background-color: $searchbarWithMagnifyingGlassBackgroundTop; // IE8 fallback
        background-image: linear-gradient($searchbarWithMagnifyingGlassBackgroundTop, $searchbarWithMagnifyingGlassBackgroundBottom);
        border-radius: 0px 5px 5px 0px;
    }

    label {
        display: none;
    }

    .btn {
        border: 0 none;
        padding: 10px 12px;
        height: 38px;
        width: 38px;
    }
}

.footer .socialMediaLinks {

    .socialMediaLink {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            .icon {
            }
        }

        .icon {
            background: transparent url('#{$imagePath}/sprite/footer_sprite.png') no-repeat -70px -34px;
            display: block;
            width: 40px;
            height: 40px;

            &.ico-fb {
                background-position: -70px -34px;
            }

            &.ico-tt {
                background-position: -120px -34px;
            }

            &.ico-ig {
                background-position: -170px -34px;
            }

            &.ico-gp {
                background-position: -220px -34px;
            }

            &.ico-yt {
                background-position: -270px -34px;
            }
        }

        .text {
            display: table-cell;
            vertical-align: middle;
            padding-left: 50px;
            height: 40px;
        }

        a:hover {
            text-decoration: none;

            .link-style {
                border-bottom: 1px solid;
            }
        }
    }

}


@media (max-width: $screen-sm-max) {
    .footer .languageSiteLinks {
        display: inline-block;
        height: 38px;
        width: calc(100% - 180px);
        vertical-align: bottom;
        width: auto;
        text-align: center;

        .languageSiteLink {
            border-bottom: 2px solid $grayDarkest;
            display: inline-block;
            margin-left: 10px;

            &.selected {
                border-bottom-color: $blueDark;
            }

            .footer-flag {
                background: transparent url('#{$imagePath}sprite/footer_sprite.png') no-repeat -70px 5px;
                display: block;
                width: 37px;
                height: 34px;

                &.flag-au {
                    background-position: -70px 5px;
                }

                &.flag-ko {
                    background-position: -117px 5px;
                }

                &.flag-ja {
                    background-position: -164px 5px;
                }

                &.flag-cn {
                    background-position: -210px 5px;
                }
            }
        }

        + .divider {
            display: none;
        }
    }
}
