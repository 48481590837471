$pg: ".pgUserProfilePage";

#{$pg} {

    color: $baseTextColor;

    .section {
        position: relative;
        margin-top: 15px;
        border-top: 1px solid #ddd;

    }

    .text, .nationality {
        width: 300px;
        max-width: 100%;
    }

    .dobDay, .dobMonth, .dobYear {
        width: 96px;
        max-width: 100%;
    }
    .requiredText {
        position: absolute;
        top: 15px;
        right: 10px;
    }

    .control-label {
        display: inline-block;
        width: 160px;
    }

    .form-control-static {
        font-weight: 700;
        vertical-align: top;
    }

    .pwdDispRow {
        padding-top: 10px;
    }

    .changePassBtn {
        margin-top: 20px;
        cursor: pointer;
    }

    .updateProfile {
        margin-top: 10px;
    }
    .cancelLink {
        padding-top: 15px;
        padding-left: 10px;
    }


}
