$pg: ".pgGroupBookingPage";

#{$pg} {

    .heroImage, .bannerImage {
        img {
            //width: 100%;
            max-width: 100%;
            height: auto;
            max-height: 100%;
        }
    }
    .summary {
        background: #f0f0ee;
        //height: 507px;
        height: auto;
        //margin-left: -15px;
        padding: 12px;
        text-align: center;

        .wysiwygContent {
            h2 {
                margin: 0 !important;
            }

            p {
                margin-bottom: 10px !important;
            }
        }
    }



}
