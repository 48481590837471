$blk: ".locationDetailsBlock";

#{$blk} {

    .tourDetailsInfo {
        padding-bottom: 30px;
    }
    .tourName {
        color: #888;
        margin-bottom: 20px;
    }

}


/* States */

//
//#{$blk} {
//
//}
