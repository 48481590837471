
$blk: ".priceBlock";

#{$blk} {

    margin-top: 30px;

    &-image {
        width: 100px;
        height: auto;
    }

    &-left {
        margin-bottom: 20px ;
    }
    &-right {
        float: right;
        margin-top: -4px;
        margin-bottom: -25px;

        &Image {
            width: 30px;
            height: auto;
            margin-right: 100px;
        }
    }

    &-table {
        text-align: center;
        font-size: 1.5rem;
        border-spacing: 2px;
        border-collapse: separate;
        width: 100%;
    }

    &-headCell {
        text-align: left;
        background: #f0f0ee;
        padding: 10px;
        font-weight: bold;
    }

    &-bookingBtn {
        width: 200px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    &-conditions {
        color: #888;
        font-size: 1.2rem;
        margin-bottom: 50px;
    }




}


/* States */


//#{$blk} {
//
//}
//
