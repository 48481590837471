@import "../../../glb/scss/variables";
//@import "../../../glb/scss/mixins"; // just add what you need

$wg: ".backToTopWidget";

#{$wg} {
	background-color: rgba(#474747, 0.7);
    border: 0;
    position: fixed;
    bottom: 5%;
    right: 5%;
    height: 36px;
	width: 50px;
    transform: rotate(90deg);

    opacity: 0;
    transition: opacity .5s;

	&-icon {
		color: white;
        font-size: 20px
	}

    .has-scrolled & {
        opacity: 1;
        transition: opacity .5s;
    }
}
