$par: ".promoFeatureItem";

.promoItemFeatureList {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

#{$par} {
    display: inline-flex;
    position: relative;
    white-space: normal;
    min-width: 255px;
    padding-bottom: 25px;
    margin-bottom: 20px;
    height: auto;

    &-inner {
        @media (min-width: $screen-xs-max){
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
        }
    }

    &-image {
        a {
            display: block;
        }
        picture {
            display: block;
            overflow: hidden;
        }
		img {
			height: auto;
			margin-bottom: 10px;
			max-width: 100%;

            @media (max-width: $screen-xs-max){
                width: 100%;
            }
		}
	}

	&-heading {
        color: $grayDark;
	}

    &-caption {
        font-size: pxToRem(18);
        padding-left: 46px;
        padding-right: 46px;
        padding-bottom: 1.2em;
        line-height: pxToRem(26);
    }

    &-cta {
        margin-top: auto;
    }
}

/* States */

#{$par} {

    @media (min-width: $screen-xxs) and (max-width: $screen-sm-min) {
        padding-left: 22px !important;
        padding-right: 22px !important;
    }

}


