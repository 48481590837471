
$blk: ".iconWidgetBlock";

#{$blk} {
	text-align: center;

	@media (max-width: 991px) {
		margin-bottom: 12px;
	}

    &-image {
        img {
            height: auto;
            max-width: 100%;
            width: 100%;
        }
    }

    &-subtitle {
        font-size: 18px;
        max-width: 255px;
        margin: auto;
    }

	&-cta {
        padding-top: 25px;
	}

	&-link {
		color: $grayDark;
		display: block;
		font-size: 2.2rem;
	}


}

