
$pg: ".pgHsSpPg";

#{$pg} {

    .no-specials {
         padding: 10px;

        .no-specials-img {
            img {
                width: 150px;
                height: auto;
            }
        }

        .no-specials-msg {
            font-family: $FuturaMediumFontStack;
            font-size: 2.6rem;
            color: #008457;
            padding: 33px 0;
        }
    }
}
