$blk: ".travelDealBlock";

#{$blk} {
	font-size: 1.8rem;
    font-family: $FuturaMediumFontStack;
    margin-bottom: 40px;

	&-list {
		margin: 0;
		padding: 0;
	}

	&-list__item {
		list-style-type: none;
		display: block;
		position: relative;
        min-height: 65px;

		&:nth-child(odd) {
			clear: both;
		}

	}


	&-list__link {
		border-bottom: 1px solid #cbcbcb;
		color: #000;
		display: block;
		width: 100%;
		padding: 10px 100px 20px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

	&-list__price {
		position: absolute;
		top: 50%;
		right: 15px;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
        padding-bottom: 20px;

		&-value {
			color: #fe502d;
		}
	}

}
#{$blk} {
	@media (max-width: $screen-xs-max) {
		font-size: 1.5rem;

		&-list__link {
			padding: 0px 0px 15px 0;
			border-bottom-width: 0px;
			white-space: pre-wrap;
			text-align: center;
		}

		&-list__price {
			position: relative;
			right: 0;
			top: 0;			
			padding: 10px 0px;
			border-bottom: 1px solid #cbcbcb;
			float: left !important;
			width: 100%;
			text-align: center;
		}

		ul li:nth-child(1){
			margin-top: 20px;
		}

		//Only show the Top 5 items on mobile
		ul li:nth-child(n+6){
			display: none;
		}
	}	
}
