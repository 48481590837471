$pg: ".pgContentPage";

#{$pg} {

    color: $baseTextColor;

    .mainBanner {

        img {
            max-width: 100%;
            margin: 10px 0;
        }
    }

    .rightSideBar {
        margin-top: 40px;

        .badgeItemBlock {
            width: 100%;
            padding: 25px;
        }

        .badgeItemBlock-image {
            img {
                width: auto;
                max-width: 200px;
            }
        }

        .promoFeatureItem {
            width: 100%;
            padding: 25px;
        }
        .promoFeatureItem-image {
            img {
                width: auto;
                max-width: 200px;
            }
        }

        .imageLinkBlock {
            text-align: center;
            padding: 25px;
        }

        .miniCarouselItem {
            display: block;
            width: 255px;
            margin: 0 auto;
        }
        .nearbyHostels-tile {
            padding: 20px;
        }
    }

}

/* states */
.right-col,
.sidebar,
.rightSideBar  {
    .col-md-8 ~ & {
        @media (min-width: 992px) {
            padding-left: 32px;
        }
    }
}
