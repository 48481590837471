
$wg: ".journeyTimes";

#{$wg} {

    background-color: #F3F3F3;
    padding: 26px 30px 0;

    &-heading {
        margin-top: 15px;
    }

    &-jTItems {
        position: relative;
        column-width: 224px;
        column-gap: 24px;
    }

    &-jItem {
        display: inline-block;
        margin:  0.25rem;
        padding:  1rem;
        width:  100%;
        font-family: $FuturaCondensedFontStack;
        padding-bottom: 20px;
    }


    &-fromCity {

        font-size: 20px;
        text-transform: uppercase;
    }

    &-rightCol, &-rightCityCol {
        width: 100px;
        float: right;
    }
    &-rightCityCol {
        margin-top: -4px;
    }

    &-rightColItem, &-rightCityColItem {
        width: 30px;
        height: 28px;
        display: inline-block;
        background-color: transparent;
        margin: 0;
        font-size: 12px;
        font-family: $FuturaRegularFontStack;
        text-align: center;
    }

    &-rightCityColItem {
        padding-top: 7px;
    }
    &-cityItem {
        //position: relative;
        font-size: 15px;
        font-family: $FuturaRegularFontStack;
        border-top: 1px solid #dedede;
        padding: 5px 0;
    }
    &-whiteBG {
        background-color: #fff;
    }

    .journeyByAir, .journeyByBus, .journeyByTrain {
        background-position: center;
        background-repeat: no-repeat;
    }

    .journeyByAir {
        background-image: url('#{$imagePath}journey-air.svg');
    }
    .journeyByBus {
        background-image: url('#{$imagePath}journey-bus.svg');
    }
    .journeyByTrain {
        background-image: url('#{$imagePath}journey-train.svg');
    }



}


