
html:lang(zh),
html:lang(ko) {

    body,h1,h2,h3,h4,h5,h6,a,p {
        font-family: $foreignlang !important;
    }

    .btn {
        font-size: 1.8rem;
    }
}

html:lang(ja) {

    body,h1,h2,h3,h4,h5,h6,a,p {
        font-family: $jpFont !important;
    }

    .progress-indicator li,
    .section-roomDetails .rd-heading,
    .aside-heading,
    .btn {
        font-family: $jpFont !important;
        font-weight: 700;
    }

   
}

/* specific page / section */
html:lang(ja) {

    .searchWithMap .searchWithMapInner .checkin-date input,
    .searchWithMap .searchWithMapInner .checkout-date input {
        font-size: 1.4rem;
    }
}

html:lang(zh),
html:lang(ko) {
    .searchWithMap .searchWithMapInner .btn {
        font-size: 2rem;
    }
}

html:lang(zh),
html:lang(ko),
html:lang(ja) {
    .pen-icon {
        font-size: 14px;
    }

    // .languageSiteLinks {
    //     padding: 0px 5px 0px 5px !important;
    // }

    .loginLinks > div.loginLinks, .loginLinks > div.myBookingsLink {
        padding: 9px 5px 9px 5px !important;
    }

    .hostelPage {
        .hostelSummary .head {
            h1, .h1 {
                font-size: 2.5rem !important;
            }
        }

        .findUsContent .fieldName {
            font-size: 1.3rem;
        }
    }

    .topHostelCarouselItem-hostelname {
        font-size: 2rem !important;
    }

}


@media (max-width: $screen-xs-max) {

    /* zh */
    .gr__cn-wd_yha_com_au {

        .hostelTilesControl .orderBy .linkDropdown {
            display: none;
        }
    }

    /* for another language class name TBC */
}
