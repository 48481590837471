

.schres {
    // abbreviated prefix for sub-classes. Don't put styles here.
    &-title{
        margin-bottom: 1.1rem;
        font-size: 2.6rem;
    }
}

/* States */
.searchresult {
    background: #fff;
    padding:1.5rem  4rem 3.3rem;

    @include break-md(){
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.schres {
    // abbreviated prefix for sub-classes. Don't put styles here.

    &-title {
        color: $blueDark;

    }

    &-items{
        border-bottom: 1px solid $md-gry;
        padding-bottom: 3.3rem;
        margin-bottom: 2.7rem;

        &:last-child{
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
}

