
$pg: ".pgHsRwPg";

#{$pg} {
    .reviewsTab {

        .leaveReview {
            display: inline-block;
            margin-bottom: 40px;
        }
        .whatTravellersThink {
            margin-bottom: 20px;
            line-height: 1.1;

            .facebookLikeButton {
                display: inline-block;
                float: right;
                margin-top: -1px;
                overflow: hidden;
                width: 88px;
            }
        }

        .hostelReview {
            margin-bottom: 30px;
        }

        .loadMoreReviews {
            border-top: 1px solid $grayLightest;
            padding: 40px 0;
            text-align: center;
            width: 100%;

            .btn-loadreviews {
                padding: 10px 40px;
            }
        }

        .pagination {
            display: block;
            margin: auto;
        }
        .ratingBadgeSmall {
            display: inline-table;
            position: absolute;
            left: 50%;
            margin-left: -16px;
            top: 12px;
            font-weight: bold;
        }
        .filters {
            .icheckbox {
                display: none;
            }
        }

        > .row > .col-md-4 {
            @media (min-width: 992px) {
                padding-left: 32px;
            }
        }
    }
}
