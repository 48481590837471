$blk: ".hiSidebarBlock";

#{$blk} {

    &-content {
        padding: 40px 20px;
    }

    iframe {
        display: block !important;
        margin: 0 auto !important;
    }
}

/* States */


//#{$blk} {
//
//
//}
