
$blk: ".discountBlock";

#{$blk} {
    background: #fff;
    border: 2px solid #4A4A4A;
    border-radius: 0;
    color: #515151;
    margin-bottom: 40px;
    padding: 20px;
    text-align: center;

    .heading {
        font-family: $FuturaBoldCondensedFontStack;
        font-size: 2.0rem;
        margin-bottom: 10px;
    }

    div {
        margin-bottom: 10px;
    }

    .bold {
        font-weight: bold;
    }

    .light {
        color: #818181;
        font-size: 1.2rem;
    }

}


/* States */


//#{$blk} {
//
//}

