$par: ".hostelListingTile";

#{$par} {
    &-name {
        font-family: $font-secondary;
        font-size: pxToRem(22);
        font-weight: 500;
        line-height: 28px;
        a {
            color: $grayDark;
            display: block;
            text-decoration: none !important;

            &:hover,
            &:active,
            &:focus {
                color: $grayDark !important;
            }
        }
    }

    &-location {
        color:$grayLight;
        line-height: 24px;
        padding-bottom: 20px;
    }

    &-detailsBtn {
        position: relative;
        margin-top: 20px;
    }

    &-description {
        padding-bottom: 20px;
    }

    .hostelImageContainer {
        min-height: 175px;
        position: relative;
        background: #e3e3e3;
    }

    .yhaFlag {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .yhaLogo, .exploreLogo {
        position: absolute;
        background-size: cover;
        bottom: 10px;
        left: 10px;
        width: 40px;
        height: 40px;
        margin-right: 5px;
    }

    .yhaLogo {
        background-image: url('#{$imagePath}yhaLogoHostelTypeIconSmall.png');
    }

    .exploreLogo {
        background-image: url('#{$imagePath}yhaExploreHostelTypeIconSmall.png');
    }

    .wishList {
        .button-like {
            padding: 0.25rem;
            margin: 5px 0px;

            .text {
                margin-left: 0rem;
                font-size: 1.4rem;
            }

            .glyphicon{
                font-size:1.7rem;
            }
        }
    }
}

/* States */

#{$par} {

   // height: 565px;
    //@media (min-width: $screen-xs-max) {
    //    height: 500px;
    //}
    @media (min-width: $screen-md-min) {
       // height: 495px;
    }

}


