
$wg: ".calendar";

#{$wg} {

    .head {
        min-height: 90px;
            color: #fff;
            //padding: 23px 0;

        .col-left {
            float: left;

            @media (max-width: $screen-sm-max) {
                float: none;
            }
        }

        .col-right {
            padding-top: 25px;
            padding-bottom: 25px;

            .checkin {
                display: inline-block;
                width: 145px;

                .checkinDate {
                    background: #fff url('#{$imagePath}forms/dateInputBackground.png') no-repeat right 7px top 7px;
                    color: $baseTextColor;
                    height: 48px;
                    padding-right: 42px;
                    width: 100%;

                    &:focus,
                    &:active {
                        border-color: $calendarBG;
                    }
                }
            }

            .btn-availability {
                float: right;
                width: 190px;
            }

            @media (max-width: $screen-sm-max) {
                padding-top: 0;
            }

            @media (max-width: $screen-xs-max) {
                padding-bottom: 25px;
                text-align: center;
            }

            @media (max-width: $screen-xs-max) {
                padding: 0 15px 25px;
            }

        }
    }
    .head-innerWrap {
        display: inline-block;
        padding: 0;
        width: 100%;
    }
    .head-btnWrap {
        .btn-primary {
            font-weight: bold;
            min-width: 166px;
        }
        @media (min-width: $screen-sm-min) {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        @media (max-width: $screen-xs-max) {
            padding-top: 20px;
        }

        .btn-arrow-right {

            &:active,
            &:hover,
            &:focus {
                color: $green;
                text-decoration: none;
            }
        }
    }

    .edit-date-wrap {
        @media (min-width: $screen-sm-min) {
            align-items: flex-end;
            display: flex;
            justify-content: flex-end;
        }

        .calendar {
            display: inline-block;
            position: relative;
            vertical-align: middle;
            @media (min-width: $screen-sm-min) {
                padding-bottom: 33px;
            }

            label {
                background: url('#{$imagePath}Calendar_Icon_REV.svg') center no-repeat $lightGreen;
                //background-size: cover;
                display: block;
                margin: 0;
                height: 50px;
                width: 50px;
                position: absolute;
                padding: 0 22px;;
                right: 0;
                top: 30px;
            }

            .input-calendar {
                font-size: 1.6rem;
                height: 50px;
                text-align: left;
                max-width: 100%;
                width: 180px;
                border-radius: 0;
                padding: 15px;
            }
        }

        .calendar-label {
            font-size: 1.8rem;
            font-weight: 700;
            margin-bottom: 5px;
            padding-left: 15px;
        }

        .input-wrap {
            position: relative;
            @media (min-width: $screen-sm-min) {
                margin-right: 11px;
            }
            @media (max-width: $screen-xs-max) and (min-width: 500px) {
                margin-left: 6px;
                margin-right: 6px;
            }
        }

        .btn-use-epack {
            width: 180px;
            display: inline-block;
            margin-top: 10px;
            color: $white;
            font-weight: bold;

            svg {
                vertical-align: -2px;
                margin-left: 0;
                margin-right: 15px;
            }
        }

        .btn-arrow-right:hover {
            color: $green;
            text-decoration: none;
        }

        @media (max-width: 500px) {
            .calendar {
                width: 100%;

                .input-calendar {
                    width: 100%;
                }
            }

            .input-wrap {
                margin-bottom: 15px;
                width: 100%;
            }

        }

        @media (max-width: $screen-xs-max) {
            .btn-book-now, .btn-use-epack {
                width: 100%;
                vertical-align: bottom;
                margin-top: 20px;

                svg {
                    margin-right: 0;
                }
            }
        }
    }
}
