$blk: ".groupsCtaBlock";

#{$blk} {
    background-color: #fff;
    display: block;
    position: relative;

    &-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0 30px 0;
        gap: 30px;

        > .btn {
            margin: 0 !important;
        }
    }

    &-enquireNow {
        margin-left: 30px;
    }
}

/* States */


#{$blk} {

    &-content {
        .rightSideBar & {
            padding: 12px 0;
            display: flex;
            justify-content: space-between;
            gap: 20px;

            .btn {
                margin: 0 !important;
                min-width: inherit;
                width: 100%;
            }

            @include breakpoint(only-md) {
                padding: 12px 0;
            }
        }
    }

    &-enquireNow {
        .rightSideBar & {
            margin-left: 10px;
        }
    }

}
