// Margin
//
// Margin styles
//
// .b-margin - Default
// .b-margin--medium-bottom - medium sized bottom margin
//
// Markup:
// <p class="b-margin--medium-bottom">I have a medium margin</p>

// Style guide: Components.Margin

.b-margin {
    // Modifiers
    &--tight-top {
        margin-top: 10px;
    }

    &--medium-top {
        margin-top: 20px;
    }

    &--loose-top {
        margin-top: 40px;
    }

    &--tight-right {
        margin-right: 10px;
    }

    &--tight-bottom {
        margin-bottom: 10px;
    }

    &--medium-bottom {
        margin-bottom: 20px;
    }

    &--loose-bottom {
        margin-bottom: 40px;
    }


    &--no-top {
        margin-top: 0;
    }

    &--no-bottom {
        margin-bottom: 0;
    }

    &--none {
        margin-bottom: 0;
    }

    &--all-none {
        margin: 0;
    }
}
