
$wg: ".schbr";

.searchbar {
    //@include cssload-show;

    .rasformgroup {
        margin: 0;
        height: 100%;
    }
}

.btnnormalize {
    border: none;
    background-color: transparent;
    margin: 0;
    padding: 0;

    // gets rid of BS3 default styles
    &:focus, &:active:focus, &.active:focus {
        outline: none;
        outline-offset: 0;
    }
}

#{$wg} {



    // abbreviated prefix for sub-classes. Don't put styles here.
    &-input {
        font-style: italic;
        height: 100%;
        width: 100%;
        border: 1px solid #DDDDDD;
    }

    &-form {
        position: relative;

        .is-header & {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
    }

    &-btnsubmit {
        @extend .btnnormalize;
        font-size: 3rem;
        position: absolute;
        top: 50%;
        margin-top: -1.6rem;
        width: 3.1rem;
    }

    &-btnclose {
        @extend .btnnormalize;
        position: absolute;
        top: 50%;
        padding: 1.5rem;
    }
}

/* States */

.searchbar {
    position: relative;

    &.is-header {
        min-height: 70px;
    }

    &:not(.is-header):not(.is-basic) {

        &:before {
            content: '';
            border-top: 1px solid $lt-gry;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;

            @include break-sm {
                display: none;
            }
        }
    }
}

@mixin schbr-placeholder {
    color: $xlt-clr1;
    // need this so place holder text fits on mobile
    @include break-sm {
        padding-top: 5px;
        font-size: 1.8rem;
    }
}

#{$wg} {
    // abbreviated prefix for sub-classes. Don't put styles here.
    &-form {

        .searchbar:not(.is-header):not(.is-basic) &{
            width: 50%;
            margin-left: 25%;
            display: block;

            @include break-md {
                width: 70%;
                margin-left: 15%;
            }

            @include break-sm {
                width: 100%;
                margin-left: 0;
            }
        }

        .is-header & {
            background-color: $lt-clr1;

            .rasformgroup {
                margin-bottom: 0;
            }
        }
    }

    &-input {
        min-height: 7rem;
        font-size: 2rem;
        box-shadow: 1px 4px 10px $xlt-gry inset;
        transition: padding-left 0.15s ease-in-out;
        padding-right: 58px;
        color: $xdk-gry;

        &.placeholder {
            color: lighten($xdk-gry, 60%);
        }

        .is-focussed:not(.is-header):not(.is-basic) & {
            padding-left: 5.2rem;
        }

        .th-eshow & {
            background-color: white;
            border-color: $lt-gry;
        }

        .is-basic &,
        .is-header & {
            padding-left: 7.4rem;
            padding-right: 1.5rem;
        }


        .is-header & {
            background-color: $lt-clr1;
            border: none;
            box-shadow: none;
            color: #fff;
            // grouping doesn't work for some reason
            &::-webkit-input-placeholder {
                @include schbr-placeholder;
            }

            &::-moz-placeholder {
                @include schbr-placeholder;
            }

            &:-ms-input-placeholder {
                @include schbr-placeholder;
            }

        }

        .is-header.is-focussed &,
        .is-basic.is-focussed &{
            padding-right: 7.4rem;
        }

        &::-ms-clear {
            display: none;
        }
    }

    &-btnsubmit {
        right: 1.8rem;
        color: $xdk-gry;

        .is-header & {
            color: $xlt-clr1;
        }
        .is-header &,
        .is-basic & {
            right: auto;
            left: 2.4rem;
        }
    }

    &-btnclose {
        display: none;
        left: 1.1rem;
        margin-top: -2rem;
        font-size: 1.2rem;
        color: $xdk-gry;
        opacity: 0;
        transition: opacity 0.15s linear;

        .is-header & {
            color: $xlt-clr1;
        }

        .is-header &,
        .is-basic &{
            left: auto;
            right: 1.5rem;
            margin-top: -2.3rem;
            font-size: 1.6rem;
        }

        .is-focussed & {
            display: block;
            opacity: 1;
            transition-delay: 0.1s;
        }

        .is-transitioning & {
            display: block !important;
        }
    }
}


