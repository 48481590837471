$wg: ".groupsPageHeader";

#{$wg} {

    &-content {
        min-height: 90px;
        color: $grayDark;

        .col-left {
            float: left;

            @media (max-width: $screen-sm-max) {
                float: none;
            }
        }
    }
    .head-innerWrap {
        display: inline-block;
        padding: 0;
        width: 100%;
    }
    .ngb-url {
        //color: $white;
        font-weight: bold;
        display: inline;
        padding-bottom: 20px;

        svg {
            margin-left: 0;
            margin-right: 5px;
            vertical-align: 0;
        }
    }

    @media (max-width: $screen-xs-max) {
        .ngb-url{
            vertical-align: bottom;
            width: 100%;
        }
    }
}
