$pg: ".pgGroupsQuoteConfirmationPage";

#{$pg} {
    color: $baseTextColor;

    section {
        padding: 14px 0;
    }

    .pageWrap {
        margin: 0 auto;
        width: 100%;
        max-width: 620px;
    }

    .section-divider {
        border-top: 4px solid $grayDark;
        font-size: 16px;
        line-height: 30px;

        + .section-divider {
            margin-top: 24px;
        }

        h3 {
            margin-top: 0;
            margin-bottom: 10px;
        }

        h4 {
            text-transform: none;
        }
        .inner {
            padding: 0 10px;

            &--padded {
                padding: 10px 10px 0;
            }

            &-bg {
                background-color: #f0f0ee;
                font-size: 17px;
                font-family: $FuturaCondensedFontStack;
                letter-spacing: 0.4px;
                padding: 10px;
                text-transform: uppercase;
            }

            &-bg-gt {
                background-color: $grayDark;
                color: #ffffff;
                font-size: 20px;
                font-family: $FuturaCondensedFontStack;
                letter-spacing: 0.4px;
                margin-top: 20px;
                padding: 10px 20px;
                text-transform: uppercase;
            }
        }


        .ruler {
            border-top: 1px solid $grayDark;
            margin: 0;

            &--light-m0 {
                border-top: 1px solid #d1d1d1;
                margin: 0;
            }

            &--light {
                border-top: 1px solid #d1d1d1;
            }
        }
    }

    .hostelName {
        font-size: 18px;
        padding-bottom: 10px;
    }

    .stepper {
        margin-top: 40px;
    }

    .submit-form {
        width: 200px;
    }

    .submitButton,
    .backButton {
        padding-top: 15px;
    }

    .rightLinks {
        text-align: right;

        @include breakpoint(only-xs) {
            text-align: left;
        }
    }

    .btn-arrow-link {
        background: none !important;
        border: none;
        color: #23527c;
        cursor: pointer;
        padding-top: 20px;
    }

}
