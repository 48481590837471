$par: ".travelStoryTitle";

#{$par} {
    margin-bottom: 40px;

    &-wrap {
        display: block;
        color: #666;
        margin: 0 auto;
        width: 100%;
        max-width: 360px;
        text-decoration: none;

        &:hover {
            color: $grayDark !important;
            text-decoration: none !important;
        }
    }

    &-image {
        max-width: 100%;
        width: 100%;
    }

    &-heading {
        color: $grayDark;
        font-weight: bold;
        font-size: 1.8rem;
        //@include line-clamp(1, 1.5);
    }

    &-desc {
        padding-bottom: 0;
        //line-height: 20px;
        //@include line-clamp(3, 1.45);
    }
}

/* States */
//
//#{$par} {
//
//
//}





