
.bookingEnquiryForm {

    fieldset {
        width: 400px;
        max-width: 100%;
        margin: 0;
        padding: 0;
    }

    .fieldGroup {
        margin-bottom: 20px;
    }

    label {
        display: block;
    }

    input[type="text"] {
        width: 100%;
        max-width: 100%;
    }

    textarea {
        width: 100%;
        max-width: 100%;
        height: 120px;
    }

    .styled-select {
        width: 100%;
        max-width: 100%;

        select {
            width: 425px;
        }
    }

    .arrivalDate,
    .departureDate {
        display: inline-block;
        width: 100%;

        input[type="text"] {
            width: 185px;
            background: url('#{$imagePath}forms/dateInputBackground.png') no-repeat right 10px top 8px;
            cursor: pointer;

            @media (max-width: $screen-sm-max) {
                width: 100%;
            }
        }
    }

    .numGuests .styled-select,
    .ageRange .styled-select {
        width: 185px;

        select {
            width: 210px;
        }
    }

    .privacyStatement {
        display: none;
        margin-bottom: 30px;
    }

    .disclaimer {
        color: #949494;
        margin-top: 20px;
    }

    .privacyStatementButton {
        display: block;
        margin-bottom: 30px;
        width: 198px;
    }

    .btn {
        width: 240px;
    }

    .captchaField {
        background: #DAEBFD;
        border: 2px solid #DAEBFD;
        border-radius: 6px;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 30px;

        &.invalid {
            border-color: $errorMessageText;
        }

        input[type="text"] {
            width: 330px;
        }

        .question > div {
            display: inline-block;
        }

        .required {
            display: none;
        }

        .captchaQuestion {
            font-weight: bold;
            margin-right: 10px;
            width: 60px;
        }

        .captchaAnswer .text {
            width: 255px;
        }

        .captchaNotice {
            padding-left: 75px;
            margin-top: 5px;
            width: 100%;
        }
    }

    .formError, .captchaError {
        color: $errorMessageText;
        background: url('#{$imagePath}forms/errorMessageIndicator.png') no-repeat;
        padding-left: 24px;
        margin-top: 5px;
    }

    input.invalid, textarea.invalid, .styled-select.invalid {
        border: 2px solid $errorMessageText;
    }

    .groupBookingConfirmation {
        background: $blueHeadingColor;
        padding: 20px 30px 10px;
        margin: 30px 0px;
        border-radius: 6px;

        p {
            color: white;

            a {
                color: white;
                text-decoration: underline;
            }
        }
    }
}

@media (max-width: $screen-sm-max) {

    .bookingEnquiryForm {
        margin-top: 30px;

        fieldset {
            margin: 0;
            padding: 0;
        }
    }

    .enquiryHeading {
        display: inline-block;
        margin-bottom: 30px;
        width: 100%;

        .requiredText {
            display: block;
            float: none;
        }
    }

    .bookingEnquiryForm .numGuests .styled-select,
    .bookingEnquiryForm .numGuests .styled-select select,
    .bookingEnquiryForm .ageRange .styled-select select {
        width: 185px;
    }

    .bookingEnquiryForm .captchaField .captchaAnswer .text {
        width: 195px;
    }

    .bookingEnquiryForm .captchaField .captchaNotice {
        margin-left: 0;
        width: 100%;
    }




}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

    .bookingEnquiryForm .numGuests .styled-select select,
    .bookingEnquiryForm .ageRange .styled-select select {
        width: 185px;
    }

}
