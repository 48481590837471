$blk: ".imageLinkBlock";

#{$blk} {
  background-color: transparent;


  img {
    max-width: 100%;
    max-height: 100%;
  }

}


/* States */

//
//#{$blk} {
//
//}
