$pg: ".pgTourBookingConfirmationPage";

#{$pg} {
    color: $baseTextColor;

    section {
        padding: 20px 0;
        border-bottom: 1px solid #ddd;
    }

    .subHeading {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .pageWrap {
        margin: 0 auto;
        width: 100%;
        max-width: 670px;
    }

    .stepper {
        margin-top: 40px;
    }
    .summary {
        strong  {
            font-size: 1.8rem;
        }
    }

    .form-cta {
        padding-bottom: 20px;
        padding-top: 30px;

        .backButton, .nextButton {
            margin: 10px 0;
        }

        .backButton {
            float: left;
        }

        .nextButton {
            float: right;
        }

        .return-link {
            float: left;
            clear: left;
            margin-top: 10px;
            margin-bottom: 40px;
            font-size: 1.6rem;
        }

    }

    .lightbox-background {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('#{$imagePath}bookings-2.0/bg_lightbox_background.png');
        z-index: 32767;
    }

    .lightbox {
        display: none;
        position: fixed;
        top: 0;
        left: 50%;
        width: 670px;
        transform: translateX(-50%);
        background: #fff;
        z-index: 32769;
        border: 0 none;
        box-shadow: 2px 2px 4px 2px #000;
        overflow: hidden;
        padding: 20px 0;
        height: 100vh;
        max-width: 100%;

        .btn-close {
            position: absolute;
            display: block;
            top: 10px;
            right: 10px;
            height: 30px;
            width: 30px;
            background: url('#{$imagePath}bookings-2.0/sprites.png') no-repeat right 1px top -730px;
            cursor: pointer;
        }
    }

}

//States

#{$pg} {

    @media (max-width: $screen-xsmd-max) {
        .form-cta {
            .backButton, .nextButton {
                margin: 10px 0;
            }

            .backButton {
                float: none;
            }

            .nextButton {
                float: none;
            }

            .return-link {
                float: none;
                display: block;
                text-align: center;
            }

        }
    }

    .lightbox-background, .lightbox {
        &.open {
            display: block;
        }
    }
}
