

$par: ".groupBookingPagePartial";

#{$par} {

    .groupHostelTile {
        display: block;
        height: 600px;
    }

    .hostel-details-wrapper {
        margin-bottom: 20px;
    }
    .hostelImageContainer {
        position: relative;
    }
    .hostelImage {
        min-height: 167px;
        width: 100%;
        max-width: 100%;
    }
    .hostelName {
        font-weight: bold;
        font-size: 1.6rem;
        margin-top: 10px;
        display: table;
        height: 40px;

        .text {
            display: table-cell;
            vertical-align: middle;
            color: #000;
            font-size: 2rem;
            color: $grayDark;
        }
    }
    .hostelLocation {
        color: #888;
        margin: 4px 0;
    }
    .buttons {
        position: absolute;
        bottom: 0;
        left: 15px;
        right: 15px;
    }
    .b-rent-a-yha-icon {
        position: relative;
        top: 3px;
        width: 94px;
        height: 22px;
    }

    .wishList {
        .button-like {
            padding: 0.25rem;
            margin: 5px 0px;

            .text {
                margin-left: 0rem;
                font-size: 1.4rem;
            }

            .glyphicon{
                font-size:1.7rem;
            }
        }
    }

    .btn.groupBooking {
        text-transform: capitalize;
    }
}

/* States */

#{$par} {

    @media (min-width: $screen-sm-min) {
        .groupHostelTile {
            padding-bottom: 44px;
        }
    }

}


