
$wg: ".nearbyHostels, .groupsNearbyHostels";

#{$wg} {

	background-color: #f0f0ee;


	&-title {
		color: white;
	}

	&-inner-content {
		padding: 20px;
	}

	&-list {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	&-list-item {
		padding-bottom: 40px;
	}

	&-distance {
	    color: #999;
	    font-weight: bold;
	    padding: 10px 0;
	    text-align: center;
	}


	//&-tile {
        //.yhaFlag {
	    //    position: absolute;
	    //    top: 15px;
	    //    right: 30px;
	    //}
	//}

	&-wishList {
		text-align: center;

		.button-like {
			padding: 0rem;
		}
	}

}

/* States */


#{$wg} {



    @media(max-width: 767px) {
        &-inner-content {
            padding-top: 10px;
        }
    }


    @media (max-width: 991px) {
        &-list {
            max-width: 400px;
            margin: 0 auto;
        }
    }


    @media(max-width: 767px) {
        &-list-item {
            padding-bottom: 20px;
        }
    }
}

