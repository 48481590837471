
/*========== NEW STYLES =========*/
.btn {
    border: 1px solid $lightGreen;
    background-color: $lightGreen;
    color: $white;
    border-radius: 0;
    font-weight: 300;
    font-size: pxToRem(16) !important;
    line-height: pxToRem(24) !important;
    padding: 11px 24px !important;
    transition: all ease-in-out .2s;
    text-transform: none;

    &:hover,
    &:active,
    &:focus {
        border: 1px solid $green;
        background-color: $green;
        color: $white;
    }

    &-primary {
        border: 1px solid $lightGreen !important;
        background-color: $lightGreen !important;

        &:hover,
        &:active,
        &:focus {
            border-color: $green !important;
            background-color: $green !important;
            box-shadow: none !important;
        }
    }

    &-outline {
        &-primary {
            background-color: transparent;
            border: 1px solid $lightGreen !important;
            color: $lightGreen;
            text-transform: none !important;

            &:hover,
            &:active,
            &:focus {
                color: $white !important;
                background-color: $lightGreen !important;
                box-shadow: none !important;
            }
        }
    }
}
/*=========== OLD STYLES ========*/
// Overrides to Bootstrap button styles
$defaultButtonTextColor: white;
$defaultButtonBackgroundColor: #007C67;
$defaultButtonHoverBackgroundColor: #00A388;

$primaryButtonTextColor: white;
$primaryButtonBackgroundColor: $defaultButtonBackgroundColor;
$primaryButtonHoverBackgroundColor: $defaultButtonHoverBackgroundColor;

$secondaryButtonTextColor: white;
$secondaryButtonBackgroundColor: #007C67;
$secondaryButtonHoverBackgroundColor: #00A388;

$tertiaryButtonTextColor: white;
$tertiaryButtonBackgroundColor: #007C67;

$navButtonBackgroundColor: $lightGreen;
$navButtonHoverBackgroundColor: #00A1AB;

$navSecondaryBackgroundColor: #FFB059;
$navSecondaryHoverBackgroundColor: #FF8600;

$btnArrowBgColor: #FF8600;
$btnArrowHoverBgColor: $navButtonHoverBackgroundColor;

.btn {
    /*
    font-family: $FuturaCondensedFontStack;
	font-size: 2.0rem;
	padding: 8px 13px;
    text-shadow: none;
	text-decoration: none;
    border: none;
	border-radius: 0;
    background-image: none;
    min-width: 134px;

    &:hover {
        text-decoration: none;
    }

	.btn-sub-label {
		text-transform: none;
		display: block;
		font-size: 1rem;
		letter-spacing: 1px;
	}


    &-booknow,
	&-book-now {
		min-width: 180px;
		display: inline-block;
		vertical-align: bottom;
		height: 50px;
		line-height: 1.8em;
	}
    */
}

.btn-default,
.btn-default:focus,
.btn-default:active,
.btn-default:visited {
    @extend .btn-primary;
}

.btn-primary {
    /*
	background-color: $primaryButtonBackgroundColor;
	color: $primaryButtonTextColor;

	&:hover {
        background-color: $primaryButtonHoverBackgroundColor;
	}
    */
}

.btn-secondary {
    /*
	background-color: $secondaryButtonBackgroundColor;
	color: $secondaryButtonTextColor;

	&:hover {
        color: $secondaryButtonTextColor;
        background-color: $secondaryButtonHoverBackgroundColor;
	}
    */
    @extend .btn-outline-primary;
}

.btn-tertiary {
	background: $tertiaryButtonBackgroundColor;

	&:hover {
		color: $tertiaryButtonTextColor;
	}
}

.btn-nav {
	@extend .btn-primary;

    &.btn-carousel {
        border: none !important;
        width: 46px;

        &:hover,
        &:active,
        &:focus {
            background-color: transparent !important;
        }
    }

}

.btn-nav-secondary {
	background: $navSecondaryBackgroundColor;
    color: black;

	&:hover {
		background: $navSecondaryHoverBackgroundColor;
	}
}

.btn-arrow {
    font-family: $FuturaRegularFontStack;
	background: $btnArrowBgColor;
    color: black;

	&:hover {
		color: $btnArrowHoverBgColor;
	}
}

.btn-arrow-right {
	display: inline-block;
	margin-top: 10px;
	color: $lightGreen;
	font-weight: bold;

	svg {
		fill: $lightGreen;
		vertical-align: -2px;
		margin-left: 15px;
	}
}

.btnWrap {
	&-descend {
		display: flex;
		flex-direction: column;
		padding-bottom: 10px;
		padding-top: 10px;
		&--center {
			align-items: center;
		}

		&--right {
			align-items: flex-end;
		}
		&--left {
			align-items: flex-start;
		}

		&:only-child {
			margin: 10px;
		}
		*:not(:first-child) {
			margin-top: 10px;
		}
	}
}


// Modal dialog close buttons
button.close:not([data-dismiss="alert"]) {
	border-radius: 22px;
	box-shadow: 0 5px 10px 0 rgba(0,0,0,0.5);
	opacity: 1;
    transition: all 0.5s ease-in-out;
    transition-property: right,top;
	background: #000 url('#{$imagePath}modal-close-button.png') no-repeat 50%;
	border: 2px solid $white;
	color: $white;
	position: absolute;
	top: -22px;
	right: -22px;
	width: 44px;
	height: 44px;
	text-indent: -999em;

	&:hover, &:focus {
		opacity: 1;
		color: $white;
	}
}

/* button size */
.btn-md {
	min-width: 200px;

	@media (max-width: $screen-xsmd-max) {
		width: 100%;
	}
}

.btn-sm {
	font-size: 1.8rem;
	min-width: 150px;
	padding: 5px 10px;
}

/* button carousel */
.slick-arrow,
button.btn-carousel,
a.btn-carousel {
	background-color: transparent !important;
	display: inline-block;
	height: 46px;
	line-height: 46px;
    padding: 0 !important;
    position: relative;
	text-align: center;
	transition: opacity .3s ease-in-out;
	width: 46px;

	.icon-chevron {
		box-sizing: border-box;
		position: relative;
		display: inline-block;
		transform: scale(var(--ggs,1));
        position: absolute;
        left: 0;
        top: 0;
		width: 46px;
		height: 46px;

		&::after {
			content: "";
			display: block;
			box-sizing: border-box;
			position: absolute;
			width: 25px;
			height: 25px;
			border-bottom: 4px solid $lightGreen;
			border-right: 4px solid $lightGreen;
			top: 10px;
		}
	}

	&.is-chevron-black .icon-chevron::after {
		border-color: #000000;
	}

	&-left .icon-chevron::after {
		left: 10px;
		transform: rotate(130deg);
	}

	&-right .icon-chevron::after {
		right: 10px;
		transform: rotate(-45deg);
	}
    &:active,
    &:focus,
    &:hover {
        box-shadow: none;
    }
}
