
$blk: ".carouselBlock";
$wgHeight: auto;
$wgMaxWidth: auto;
$content-inset: auto;

#{$blk} {
    margin: auto;

    &-content {
        position: relative;
        padding-bottom: 5em;
        padding-top: 3em;
    }
    &-slider {
        display: flex;
        flex-direction: column;
        position: relative;
        max-width: $content-inset;
    }
    &-frame {
        height: $wgHeight;
        width: 100%;
        //max-width: $content-inset;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        font-size: 0;
        line-height: 0;
    }
    &-slides {
        display: inline-block;;
        width: 100%;
        padding: 0;
    }

    &-slide {
        position: relative;
        display: inline-block;
        //width: 100%;
        height: $wgHeight;
        //white-space: normal;
        vertical-align: middle;
        line-height: 20px;
        font-size: 16px;

        > a {
            display: block;
        }
    }

    &-controls {
        padding-top: 50px;

        .hide-control & {
            display: none !important;

            [data-carousel-autoplay="true"] & {
                display: inline-flex !important;
            }
        }
    }
}
