
$wg: ".yhaSearchBar";

#{$wg} {
    //margin-bottom: 20px;

    .hidden {
        display: none;
    }

    .keyword {
        width: 100%;
        height: 50px;
        border-radius: 0;
        font-size: 1.6rem;
        padding: 14px 18px;
        color: #000;
        border: 1px solid #ddd;
    }

    .searchWrapper {
        width: 100%;
        position: relative;
    }

    .searchContainer {
        width: 100%;
        position: absolute;
        z-index: 100;
        margin-top: 4px;
        background: #d9f6f8;
        color: #464646;
        font-size: 1.6rem;
        text-align: left;
    }

    .category {
        margin: 0;
        padding: 4px 10px;
        background: $lightGreen;
        color: #fff;
        text-transform: uppercase;
        font-size: 1.6rem;
    }

    .catItem {
        display: block;
        padding: 4px 10px;
        color: #464646;
        padding: 0;
    }

    .itemLink {
        display: block;
        padding: 4px 10px;
        color: #464646;

    }
}


