
$wg: ".fullWidthPromo";

@mixin smallDevices() {
    &-shape {
        left: 0;
        top: 34px;
        bottom: auto;
        width: 100%;
    }

    &-captions {
        display: block !important;
        padding: 40px 2px;
    }

    &-title {
        margin-top: 0;
        font-size: pxToRem(48);
        line-height: 56px;
        width: 100% !important;
    }

    &-inner {
        padding-left: 0 !important;
    }

    &-cta {
        a {
            display: block;
        }
    }

    &-image-frame {
        overflow: hidden;
    }

    &-variant-maroon .fullWidthPromo-image-frame {
        background-image: none !important;
        background-color: $maroon;
    }

    &-variant-blue .fullWidthPromo-shape svg {
        margin-top: auto;
    }

    .container {
        width: 100%;
    }
}

#{$wg} {
    &-content {
        padding-bottom: 3rem;
    }

    &-image-frame {
        background-color: $grayLighter;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &-captions {
        position: relative;
        padding: 9rem 6rem;
        max-width: 660px;
        margin: auto;
        z-index: 1;
    }

    &-title {
        color: $white;
        font-family: $font-secondary;
        font-size: pxToRem(64);
        font-weight: 400;
        padding-bottom: 30px;
    }

    &-description {
        color: $white;
        font-size: pxToRem(18);
        font-weight: 400;
        line-height: 26px;
        padding-bottom: 40px;

        h1, h2, h3, h4, h5, h6 {
            font-family: $font-secondary;
            line-height: 1.4em;
            font-weight: 400;
            padding-bottom: 0.5em;
        }
    }

    &-shape {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 660px;
        overflow: hidden;
        z-index: 0;
    }

    &-cta {
        .btn {
            padding: 11px 48px !important;
        }
    }

    .container {
        position: relative;
    }

}

/* States */

#{$wg} {
    &-variant-blue {
        #{$wg} {
            &-image-frame {
                background-color: $blue;
            }

            &-captions {
                display: flex;
                max-width: none;
            }

            &-title {
                flex: 0 0 50%;
                width: 50%;
            }

            &-description {
                font-size: pxToRem(16);
            }

            &-shape {
                @media (min-width: $screen-md-min ) {
                    left: -20px;
                    width: calc(50% + 20px);
                }

                img {
                    height: auto;
                    max-width: 100%;
                    width: 100%;
                }

                svg {
                    margin-top: -50px;
                }
            }

            &-inner {
                padding-left: 5em;
            }

            &-cta {
                .btn {
                    border-color: $white !important;
                    background-color: transparent !important;
                    padding: 11px 40px !important;

                    &:hover,
                    &:active,
                    &:focus {
                        background-color: $white !important;
                        border-color: $white !important;
                        color: $blue !important;
                    }
                }
            }
        }
    }

    .container > & {
        padding-left: 0;
        padding-right: 0;
        overflow: hidden;

        .container {
            width: 100%;
        }
    }

    .rightHandPanel & {
        margin-top: 40px;
        @include smallDevices();
    }

    @media (max-width: $screen-sm-max ) {
        @include smallDevices();
    }

}


