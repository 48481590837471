
$pg: ".hsStbPg";

#{$pg} {
    .sustainabilityTab {
        h1 {
            font-family: $MuseoSansRounded900FontStack;
            font-size: 3.0rem;
            color: #515151;
            margin-bottom: 10px;
            margin-top: 0px;
        }

        .overview {
            font-size: 1.6rem;
        }

        p {
            color: #616161;
        }

        .sustainabilityImage {
            width: 100%;
            height: auto;
        }

        .subheading {
            font-size: 1.6rem;
            color: #515151;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .ourInitiatives {
            margin-top: 40px;
            color: #616161;

            .row {
                margin-bottom: 40px;
            }
        }

        .listHeading {
            font-weight: bold;
            margin-bottom: 10px;
            display: block;
        }

        .sustainabilityContents {
            padding: 40px 0;

            .badgeItem {
                text-align: center;
            }

            .sidebarInfoBox {
                margin-top: 30px;
            }
            .badgeItem-image {
                img {
                    width: 100%;
                    height: auto;
                    max-width: none;
                }
            }
        }
    }
    .sidebarInfoBox {
        background: #fff;
        text-align: center;
        padding: 20px;
        border: 2px solid #4A4A4A;
        color: #515151;

        .heading {
            font-family: $FuturaBoldCondensedFontStack;
            font-size: 2.0rem;
            margin-bottom: 10px;
        }

        div {
            margin-bottom: 10px;
        }

        .bold {
            font-weight: bold;
        }

        .light {
            color: #818181;
            font-size: 1.2rem;
        }
    }


    @media (max-width: $screen-sm-max) {

        .sustainabilityTab {
            margin-top: 40px;
        }

        .sustainabilityTab .sustainabilityContents {

            .badgeItem,
            .sidebarInfoBox {
                margin: 0 auto 30px;
                max-width: 400px;
            }

        }

    }

    @media (max-width: $screen-md-max) {

        .sustainabilityTab {

            &.hostelTab ul {
                padding-left: 30px;
            }

            .sustainabilityContents {
                padding: 20px 15px;
            }
        }
    }
}



