// For changing input placeholder text
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }

    &:-moz-placeholder {
        @content
    }

    &::-moz-placeholder {
        @content
    }

    &:-ms-input-placeholder {
        @content
    }
}

@mixin flex-column($columns) {
  -webkit-flex: $columns;
      -ms-flex: $columns;
          flex: $columns;
}

@mixin display-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex-order($order) {
    -webkit-box-ordinal-group: $order;
    -moz-box-ordinal-group: $order;
    -ms-flex-order: $order;
    -webkit-order: $order;
    order: $order;
}

@mixin flex-grow($width) {
    -webkit-flex-grow: $width;
    -moz-flex-grow: $width;
    -webkit-flex: $width;
    -ms-flex-grow: $width;
    flex-grow: $width;
}

@mixin flex-align-items($prop) {
    -webkit-align-items: $prop;
    align-items: $prop;
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

@function pxToRem($value) {
    $remValue: ($value / 16) * 1em;
    @return $remValue;
}

%visuallyhidden {
    /*  hiding elements while at the same time make them accessible on screen readers
        - usage @extend %visuallyhidden;
    */
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
}

@mixin ellipsis($width) {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    width: $width;
    text-overflow: ellipsis;
}

@mixin breakpoint($screenSize) {

    @if $screenSize == only-lg {
        @media (min-width: $screen-lg-min ) { @content;}
    }
    @else if $screenSize == only-md {
        @media (min-width: $screen-md-min ) and (max-width: $screen-md-max){ @content;}
    }
    @else if $screenSize == only-sm {
        @media (min-width: $screen-sm-min ) and (max-width: $screen-sm-max){ @content;}
    }
    @else if $screenSize == only-xs {
        @media (max-width: $screen-xs-max){ @content;}
    }
}

@mixin break-sm( $isMin:false ) {

    @if( $isMin == true ) {
        @media only screen and (min-width: $screen-sm-min) {
            @content;
        }
    } @else {

        @media only screen and (max-width: $screen-xs-max) {
            @content;
        }
    }
}

@mixin break-md( $isMin:false, $justMd: false ) {

    // just tablet breaks
    @if($justMd == true) {
        @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            @content;
        }
    } @elseif ( $isMin == true ) {
        @media only screen and (min-width: $screen-md-min) {
            @content;
        }
    } @else {

        @media only screen and (max-width: $screen-sm-max) {
            @content;
        }
    }
}

@mixin responsiveTable() {
    /*
        mobile table vertical view
        - add class="table vw-mobile"
        - add data-label="" to each of the tbody td (column th) eg: data-label="First name"
    */
    thead {
        display: none;
    }

    tr:nth-child(even) td {
        background-color: $secondaryButtonEndColor;
    }

    th {
        display: none;
    }

    td {
        display: block;
        padding-left: 135px;
        position: relative;
        text-align: left;

        &:before {
            content: attr(data-label);
            display: inline-block;
            font-weight: bold;
            padding-right: 20px;
            width: 130px;
            position: absolute;
            left: 15px;
        }
    }
}

@mixin drop-shadow($h, $v, $blur, $spread, $color) {
    -moz-box-shadow: $h $v $blur $spread $color;
    -webkit-box-shadow: $h $v $blur $spread $color;
    box-shadow: $h $v $blur $spread $color;
}

a {
    color: $lightGreen;

    &:hover,
    &:active,
    &:focus {
        color: $green;
    }
}

.clearfix {
    clear: both;
}

td.error{
    font-weight: bold;
    color: $errorTableCell;
}

.invisible {
    visibility: hidden;
}

.italic {
    font-style: italic;
}

.blueHeading {
    text-align: center;
    max-width: 100%;

    .blueHeadingLeft {
        min-width: 224px;
        padding-bottom: 15px;
        margin-bottom: 25px;
        font-family: $FuturaBoldCondensedFontStack;
        font-size: 3.6rem;
        font-weight: 700;
        color: $brand-primary-orange;
        text-transform: uppercase;
        background: url('#{$imagePath}dividers/heading-h2.svg') 50% 100% no-repeat;
    }

    .blueHeadingRight {
        font-family: $FuturaMediumFontStack;
        font-size: 2.0rem;
        font-weight: 500;
        line-height: 22px;
        text-transform: none;
    }
}

.backgroundbanner {
    height: 0;
}

.stateMap {
    width: 40px;
    height: 38px;
    background-size: cover;
    display: inline-block;

    &.all {
        background-image: url('#{$imagePath}mapicons/icon-map-all.png');
    }

    &.nsw {
        background-image: url('#{$imagePath}icons/NSW_map_grey.svg');
    }

    &.nt {
        background-image: url('#{$imagePath}icons/NT_map_grey.svg');
    }

    &.qld {
        background-image: url('#{$imagePath}icons/QLD_map_grey.svg');
    }

    &.sa {
        background-image: url('#{$imagePath}icons/SA_map_grey.svg');
    }

    &.tas {
        background-image: url('#{$imagePath}icons/TAS_map_grey.svg');
    }

    &.vic {
        background-image: url('#{$imagePath}icons/VIC_map_grey.svg');
    }

    &.wa {
        background-image: url('#{$imagePath}icons/WA_map_grey.svg');
    }
}

.mainContentSpacer.small {
    height: 50px;
    width: 100px;
}

.mainContentSpacer.medium {
    height: 295px;
    width: 100px;
}

.mainContentSpacer.large {
    height: 365px;
    width: 100px;
}

// These styles allow us to hide bootstrap columns based on screen width
@media (max-width: $screen-xs-max) {
    .col-xs-0 {
        display: none;
    }
}

@media (max-width: $screen-sm-max) {
    .col-sm-0 {
        display: none;
    }
}

@media (max-width: $screen-md-max) {
    .col-md-0 {
        display: none;
    }
}

@media (min-width: $screen-lg-min) {
    .col-lg-0 {
        display: none;
    }
}

.mainPageContent.container {
    position: relative;
    background: white;
}

#aspnetForm,
.full-page-wrapper > footer,
#page-wrapper > footer {
    position: relative;
}

.mainPageContent {
    a:not(.btn) {
        text-decoration: none;
    }

    a:not(.btn):hover {
        text-decoration: underline;
        color: #2a6496;
    }
}

.hover-content {
    display: none;
}

#help-hover {
    position: absolute;
    background: url('#{$imagePath}bookings-2.0/bg_room_details_hover.png') no-repeat -228px 100%;
    width: 228px;
    margin: -16px 0 0 -15px;
    z-index: 31337;
    font-size: 1.1rem;
    color: #004665;
}

#help-hover div {
    width: 228px;
    padding: 14px 14px 0 14px;
    margin-bottom: 23px;
    background: url('#{$imagePath}bookings-2.0/bg_room_details_hover.png') no-repeat;
}

#help-hover h3 {
    font-size: 1.8rem;
    line-height: 18px;
}

#help-hover p {
    padding-bottom: 5px;
}

.what-is-this {
    display: block;
    float: left;
    clear: none;
    padding: 0 0 4px 21px;
    margin: 4px 0 0 10px;
    background: url('#{$imagePath}bookings-2.0/sprites.png') no-repeat 0 -1370px;
}

div[data-ux-pagebuilder="Column"] img {
    width: auto;
    max-width: 100%;
}

.twoColumnPageTemplate div[data-ux-pagebuilder="Column"] > ul > li{
  background-image:none;
  padding-left: 0px;
  margin-bottom: 0px;
}

.styled-select {
    &.error {
        border: none !important;
    }

    select:focus {
        outline: none;
    }
}

.overlay {
    opacity: 0.7;
    background-color: #000;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99998;
}

/* typography */
html {
    font-size: 62.5%; // 10px
}

body {
    font-size: 1.6rem; // 16px
    font-family: $font-primary;
}


.headingSection {
    text-align: center;

    h1 {
        @extend .p-heading;
        margin-bottom: 1.2em;
        /*
        font-family: $font-secondary;
        font-size: pxToRem((40));
        line-height: 44px;
        font-weight: 400;
        padding-bottom: 15px;
        */
        // https://jira.yha.com.au/browse/CMSR-2069 : Remove background
        // background: url('#{$imagePath}dividers/heading-h2.svg') 50% 100% no-repeat;
    }
}

.intro-p,
.headingSection .subcontent {
    font-size: pxToRem(16);
    line-height: 24px;
    margin: 0 auto 30px;
    max-width: 920px;
}

.lead {
    font-size: 1.8rem;
}

.text-color-invert {
    color: $white;
}

/* structure */
.inline {
    display: inline-block;
    vertical-align: top;
}

.aside-right-top { /* display as a right column on desktop and display at the top for responsive */
    float: right;

    @media (max-width: $screen-sm-max) {
        float: none;
    }
}

.box-scroll {
    background-color: $white;
    border: 1px solid $formInputElementBorderColor;
    height: 100px;
    margin: 0 0 10px 0;
    overflow: auto;
    padding: 15px;
}

/* forms */
.form-control:focus {
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(221, 221, 221, 1);
}

.field-required {
    color: $lightBlue;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 14px;
    padding-left: 5px;
    vertical-align: middle;
}

/* tooltips */
.tooltip-display {
    display: inline;
    overflow: hidden;
    position: relative;
    opacity: 1;

    &:before {
        content: '';
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: rgba(0, 166, 228, 1) transparent transparent transparent;
        width: 0;
        height: 0;
        position: absolute;
        bottom: 12px;
        left: 50%;
        margin-left: -6px;
        visibility: hidden;
        opacity: 0;
    }

    &:after {
        background-color: $blue; /* fallback */
        background-color: rgba(0, 166, 228, 1);
        display: block;
        height: auto;
        min-width: 100px;
        padding: 12px;
        content: attr(data-tooltip);
        color: $white;
        position: absolute;
        bottom: 10px;
        left: 0;
        text-decoration: none;
        text-align: center;
        visible: hidden;
        z-index: 999;
        opacity: 0;
        border-radius: 5px;
    }

    &:hover {
        overflow: visible;

        &:before {
            bottom: 17px;
            visibility: visible;
            opacity: 1;
        }

        &:after {
            bottom: 23px;
            visibility: visible;
            opacity: 1;
        }
    }

    &.animate:after,
    &.animate:before {
        transition: all 0.2s ease-in-out;
    }
}

.ico-arrow-left {
    background: url('#{$imagePath}story/select_arrow.png') no-repeat center;
    display: inline-block;
    height: 20px;
    margin-right: 5px;
    width: 16px;
    vertical-align: bottom;
    transform: rotate(90deg);
}

.ico-arrow-right {
    background: url('#{$imagePath}story/select_arrow.png') no-repeat center;
    display: inline-block;
    height: 20px;
    margin-left: 5px;
    width: 16px;
    vertical-align: bottom;
    transform: rotate(-90deg);
}

/* pagination filter */
.pagination-filter {
    background-color: $grayLightest;
    height: 50px;
    line-height: normal;
    margin-bottom: 30px;
    margin-top: 30px;
    padding: 16px 0;
    text-align: center;

    a {
        line-height: 20px;
    }

    .pagination-filter-center {
        border: 1px solid $grayLight;
        border-width: 0 1px;

        label {
            margin-bottom: 0;
        }
    }

    @media (max-width: $screen-xsmd-max) {

        @include display-flex;

        .col-xs-4 {
            padding: 0 5px;
            width: auto;
        }

        .pagination-filter-left {
            @include flex-column(1);
        }

        .pagination-filter-center {
            @include flex-column(2);
        }

        .pagination-filter-right {
            @include flex-column(1);
        }
    }
}
/* end pagination filter */

/* _print-buttons */
.print-buttons {
    margin-bottom: 10px;
    min-height: 50px;

    @media (max-width: $screen-sm-max) {
        margin-bottom: 30px;
    }

    ul {
        list-style: none;
        padding: 0;

        &:before{
            display: table;
            content: '';
        }

        &:after {
            clear: both;
        }
    }

    li {
        float: left;
        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }
    }
}
/* end _print-buttons */

/* term and condition box */
.terms-and-conditions-box {
    background-color: $white;
    border: 1px solid $formInputElementBorderColor;
    height: 93px;
    margin: 0 0 10px 0;
    overflow: auto;
    padding: 15px;

    p {
        font-size: 1.1rem;
    }
}


@media (max-width: $screen-xsmd-max) {

    .table.vw-mobile {
        @include responsiveTable();
    }
}

@media (max-width: 640px) {

    .table.vw-mobile-sm {
       @include responsiveTable();
    }
}

@media (max-width: $screen-sm-max) {

    /* typography */
    body {
        font-size: 1.6rem; // 16px
    }

    h1 {
        font-size: 3.0rem;
    }

    h2 {
        font-size: 2.6rem;
    }

    h3 {
        font-size: 2.2rem;
    }

    h4 {
        font-size: 1.8rem;
        color: $darkTextColor;
    }

    p {
        font-size: 1.6rem
    }

    .headingSection h1 {
        font-size: 3.6rem;
        margin-bottom: 20px;
    }

    .input-sm {
        width: 92px;
    }
}

/* addthis plugin for mobile (display at the bottom of the screen)
https://www.addthis.com/  >>> Mobile sharing toolbar */
.addthis-smartlayers-mobile {
    height: 48px;
}

select:not([multiple]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 50%;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiB3aWR0aD0iNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0IDIwbDEwIDEwIDEwLTEweiIgZmlsbD0iIzJBQjQ3MiIvPjwvc3ZnPg==');
    padding: .5em;
    padding-right: 1.5em
}

.full-page-wrapper {
    background-color: white;
    position: relative;
    left: 0;
    right: 0;
}


.readMoreInfoArticle {

    margin: 25px 0;

    .readMore-content {
        overflow: hidden;
        position: relative;
        margin-bottom: 12px;
        height: auto;

        &.readMore-content--hidden {
            height: 300px;

            &::after {
                position: absolute;
                width: 100%;
                height: 120px;
                bottom: 0;
                left: 0;
                background-image: linear-gradient(to bottom,rgba(255,255,255,0) 0,#fff 100%);
                background-repeat: repeat-x;
                pointer-events: none;
                content: '';
            }
        }
    }
}

.enquireNowBtn {
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-left: 50%;
    transform: translateX(-50%);
}



.auth0MessageWrap {
    position: relative;
    width: 100%;
}

.auth0Error {
    background: #ff3e00;
    color: #fff;
    text-align: center;
    padding: 10px;
    line-height: 1.8;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
}

.auth0Message {
    background: #7ed321;
    color: #fff;
    text-align: center;
    padding: 10px;
    line-height: 1.8;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    max-width: 300px;
    margin: 0 auto;
    position: absolute;
    width: 300px;
    z-index: 10000000;
    margin-left: 50%;
    transform: translateX(-50%);
    top: 50px;
}


.site-maintenance {
    background-color: #daeb4c;
    padding: 16px 0;
    font-size: 18px;
}

.right-col,
.rightSideBar {
    .detail-page-tab & {
        padding-top: 40px;
    }
}

//CMSR-1605
.hi-hostel-wrap {
    margin-top: -8px;
    margin-bottom: -20px;
    @media (min-width: $screen-sm-max) {
        margin-top: -24px;
        margin-bottom: 0;
    }

    .imageLinkBlock {
        display: inline-block;

        img {
            max-width: 50px;

            @media (min-width: $screen-xs-max) {
                max-width: 60px;
            }

        }
    }
}

//CMSR-1450 Added HI Partner Link
.hi-hostels {
    display: inline-block;
    text-align: center;
    background-color: #000;
    padding: 4px 10px;

    @media (min-width: $screen-sm-max) {
        padding: 10px;
    }

    &-link {
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;

        &:hover {
            color: #eeeeee;
        }
    }
}

// Flexbox rows to column height issue in bootstrap 3
.row {
    /*
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    */
}

.row.display-flex {
    display: flex;
    flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
    margin-left: -.3px;  // fix for safari bug
    margin-right: -.3px; // fix for safari bug
}

// T&C checkboxes align inline
.icheckbox + p {
    display: inline-block !important;
}


.acceptTNC-Error {
    color: #ff0000 !important;
    * {
        color: #ff0000 !important;
    }
}


.sidebarBackground {
    background-color: $grayLightest;
}

.section-ruler {
    display: block;
    width: 100%;
    height: 0;
    border-bottom: 1px solid #ddd;
    margin: 16px;
}

.d-flex {
    display: flex;
}

.ml-auto {
    margin-left: auto;
}

ul.LoggedInMenu {
    list-style: none;
    margin: 0;
    padding-bottom: 50px;

    li {
        padding: 10px 0;
    }
}
