
$wg: ".googleMap";

#{$wg} {

	background-color: #ccc;


	&-title {
		color: white;
	}

	&-popup {
		position: relative;
		width: 200px;

		&-name {
			color: #464646;
			font-size: 1.6rem;
			font-weight: bold;
			text-align: center;
			margin-top: 10px;
		}

		&-location {
			color: #7f7f7f;
			font-size: 1.2rem;
			text-align: center;
			margin-top: 4px;
		}

		&-btnview {
			width: 100%;
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}
}

/* States */

#{$wg} {


    &-canvas {
        width: 100%;
        height: 510px;

        @media (max-width: $screen-sm-max) {
            height: 200px;
        }
    }


}


