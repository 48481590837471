@mixin line-clamp($count: 2, $lineHeight: 1.4) {

    display: block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: relative;
    line-height: $lineHeight;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 !important;
    &:after {
        content: '...';
        text-align: right;
        bottom: 0;
        right: 0;
        width: 25%;
        display: block;
        position: absolute;
        height: calc(1em * #{$lineHeight});
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 75%);

        @supports (-webkit-line-clamp: 1) {
                display: none !important;
        }
    }

    -webkit-line-clamp: $count;
    height: calc(1em * #{$lineHeight} * #{$count});
}
