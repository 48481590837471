
$wg: ".findUs";

#{$wg} {
	&-inner {
	}

	table {
		td {
			padding-bottom: 5px;
			padding-top: 5px;
			vertical-align: top;
		}
	}


	&-fieldname {
	    font-family: $FuturaHeavyFontStack;
	    width: 100px;
	}

	&-showmap {
		padding: 18px 40px;
		position: relative;
		text-align: center;

		a {
			margin-left: 10px;
			display: block;
		}
	}

	&-map {
	    height: 229px;
	    width: 248px;
	}

	&-arrow {
	    width: 37px;
	    height: 44px;
	    position: absolute;
	    left: 50%;
	    margin-left: -35px;
	    bottom: 35px;
	}

	&-showlocation {
		margin-top: -25px;
		margin-left: 10px;
		display: block;
	}

    &-content {
        .groupsFindUsBlock & {
            padding: 20px 0;
        }
    }

}


/* States */

#{$wg} {

    &--nomap & {
        &-mapWrap {
            display: none;
        }

        &-address {
            padding-top: 15px;
        }
    }

    &--notimes & {
        &-timesWrap {
            display: none;
        }
    }

}
