$pg: ".pgUserBookingsPage";

#{$pg} {
    color: $baseTextColor;
    .page-heading {
        font-family: $FuturaBoldCondensedFontStack;
        font-size: 2.4rem;
        margin-top: 25px;
    }

    .hostel-deals-results {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px dotted $gray;

        &-info {
            display: block;
            line-height: 1.5;
        }

        .hostelImageContainer {
            min-height: 175px;
            position: relative;
        }

        .hostelImage {
            width: 100%;
        }
    }

    .btn-toolbar {
        display: flex !important;
        justify-content: space-between !important;
        margin: 10px 0px 0px 0px;

        .btn {
            margin-right: 30px;
        }

        .btn:first-child {
             margin-left: 0px;
        }

        .btn:last-child{
             margin-right: 0;
        }

    }

    .btn-toolbar > .btn {
        flex: 1 1 auto;
    }

    .content-area {
        margin: 10px 0;
    }
}

/* STATES */
#{$pg} {

    @media (max-width: $screen-xs-max){
        .btn-toolbar {
            .btn {
                min-width: 110px;
                margin-right: 0px;
            }
        }
    }

     @media (max-width: $screen-xsmd-max) {
         .btn-toolbar {
             .btn {
                 min-width: 70px;
                 font-size: 1.3rem;
                 margin-right: 0px;
             }
         }
     }

     @media (max-width: $screen-xxs-max) { //iPhone 5/SE
        .btn-toolbar {
            display: block !important;
        }
     }
}

