
$pg: ".pgHsTtdPg";

#{$pg} {

    .relatedActivities {
        margin-bottom: 30px;
    }

    .blueHeading {
        margin-top: 50px;
        margin-bottom: 30px;

        .blueHeadingLink {
            float: right;

            a {
                font-family: $sansFontStack;
                font-size: 1.4rem;
                font-weight: normal;
                color: white;
            }
        }
    }

    .blueHeading.hostelConnect {
        margin-top: 0px;
        margin-bottom: 0px;
    }


    .freeThingsToDo {
        li {
            display: inline-table;
            width: 330px;
            padding-right: 50px;
        }
    }

    .socialMediaLinks {
        padding: 20px 0;

        .imageLinkBlock {
            display: inline-block;
        }

        a {
            display: inline-block;
            margin-right: 5px;
        }

        img {
            width: 44px;
        }
    }

    .instagramLink, .facebookLink {
        background: $sidebarBackgroundColor;
        text-align: center;
        padding: 20px 0px;
    }

    .instagramFeedContainer {
        background: $sidebarBackgroundColor;
        padding: 0px 10px;
    }

    .facebookFeedContainer {
        padding: 0px 15px 30px 15px;
        text-align: center;

        .fb-like-box {
            background: white;
        }
    }
    .travellerTipsCarouselWrapper {
        margin: 40px 0px;
    }

    .travellerTipsCarouselWrapper .travellerTipsCarouselControls {

        .heading-control {
            padding: 0 52px;
            width: 100%;
            max-width: 100%;
            position: relative;
        }

        .left-control {
            position: absolute;
            left: 0;
            top: 0;
        }

        .right-control {
            position: absolute;
            right: 0;
            top: 0;
        }

        .blueHeading {
            margin: 0;
            min-height: 50px;
            text-align: center;
            width: 100%;
        }
    }

    &-slider {
        position: relative;
        //max-width: $content-inset;
    }
    &-frame {
        height: auto;
        width: 100%;
        //max-width: $content-inset;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
    }
    &-slides {
        display: block;
        width: 100%;
        padding: 0;
    }

    &-slide {
        position: relative;
        display: inline-block;
        width: 100%;
        height: auto;
        white-space: normal;

    }

    .travellerTipsCarouselWrapper .travellerTip {
        margin: 20px 0;

        .speechBubble {
            background: $reviewBg;
            padding: 16px;
            position: relative;
            min-height: 85px;
            max-width: 100%;
            width: 100%;

            .speechMarks {
                display: block;
                width: 22px;
                height: 20px;
                margin-bottom: 5px;
            }

            .speechMarksRight {
                position: absolute;
                right: 20px;
                top: 20px;
            }

            .speechArrow {
                position: absolute;
                bottom: -18px;
                left: 50px;
            }
        }

        .authorName {
            margin-top: 16px;
            font-weight: 700;
        }

        @media (max-width: $screen-xsmd-max) {

            .speechMarksLeft,
            .speechMarksRight {
                display: none;
            }

            .comment {
                padding: 0;
            }
        }
    }

    // States

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

        .blueHeading {
            display: inline-block;
            margin: 30px 0;
            width: 100%;

            .blueHeadingLink {
                float: none;
            }
        }

        .atTheHostel .col-cust-2 {
            margin-bottom: 30px;
        }

        .relatedActivities .row {
            border-bottom: 0 none;
            margin-bottom: 0;
            padding-bottom: 0;
        }

    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

        .relatedActivities {
            margin-bottom: 30px;
        }

        .facebookFeedContainer {
            padding: 0;
        }
    }


}
