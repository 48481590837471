$par: ".travelCarouselTile";

#{$par} {
    .toursAndActivitiesItem {
        position: relative;
        max-height: 480px;
        white-space: normal;

        .mainImageContainer {
            //height: 228px;
            height: auto;
            overflow: hidden;
        }

        .mainImage {
            width: 100%;
        }

        .yhaFlag {
            position: absolute;
            top: 15px;
            right: 10px;
        }

        .textSection {
            overflow: hidden;
            height: 250px;

            a {
                color: $grayDark;
            }
        }

        .location {
            color: $lightTextColor;
            margin-bottom: 10px;
        }

        .btn {
            //position: absolute;
            //bottom: 20px;
            //left: 0px;
            //width: 200px;
            margin: 8px 8px;
            width: 100%;
        }
    }
}

/* States */

#{$par} {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {

        .toursAndActivitiesItem {
            height: auto;
            margin-bottom: 30px;
            padding-bottom: 90px;

            .mainImageContainer {
                height: auto;
            }

            .textSection {
                height: 200px;
            }
        }
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {

        .toursAndActivitiesItem {

            .mainImageContainer {
                height: auto;
                max-height: 185px;
            }

            .btn {
                bottom: 0;
            }
        }
    }
    @media (min-width: $screen-xxs-max) and (max-width: $screen-sm-min) {

        .toursAndActivitiesItem {

            .mainImageContainer {
                height: auto;
                max-height: 185px;
            }

            .btn {
                bottom: 0;
            }
        }
    }

}





