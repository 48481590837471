$par: ".tourActivitiesPagePartial";

#{$par} {
    padding-bottom: 60px;
    margin-bottom: 40px;

    .toursAndActivitiesItem {
        position: relative;

        .mainImageContainer {
            height: auto;

            picture {
                display: block;
                height: 100%;
            }

            img {
                margin-bottom: 20px;
            }
        }

        .mainImage {
            width: 100%;
        }

        .yhaFlag {
            position: absolute;
            top: 15px;
            right: 10px;
        }
        .textSection {
            overflow: hidden;

            h3 {
                margin: 0;
            }

            a {
                color: $grayDark;
            }

            .description {
                //@include line-clamp(5, 1.4);
            }
        }

        .location {
            color: $lightTextColor;
            margin-bottom: 10px;
            //@include line-clamp(1, 1.4);

        }

        .btn-container {
            padding: 8px 0;
            position: absolute;
            bottom: -70px;
        }
    }
}

/* States */
//
#{$par} {
    /*

    height: 540px;

    @media (min-width: $screen-sm-max) {
        height: 510px;
    }

    .toursAndActivitiesItem {
        height: 540px;

        @media (min-width: $screen-sm-max) {
            height: 510px;
        }
    }
    */


}





