

$wg: ".paging";

#{$wg} {

    background-color: transparent;
    text-align: center;

    &-content {
        padding: 10px 0;
    }

    &-btn {
        margin: 3px;
        color: #4A4A4A;
        overflow: visible;
        font: inherit;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer;
        border: none;
        display: inline-block;
        //padding: 0 12px;
        vertical-align: middle;
        user-select: none;
        transition: background-color 0.15s linear;
        text-decoration: none;
        text-align: center;
        border-radius: 0;
        position: relative;
        padding: 12px;
        height: 48px;
        width: 48px;
    }

    &-more {
        color: $grayDark;
        //color: #747779;
        background-color: transparent;
        overflow: visible;
        font-size: 20px;
        display: inline-block;
        padding: 0 12px;
        text-align: center;
        position: relative;
    }

    &-next, &-prev {
        border: 1px solid $lightGreen;
        //background-color: $lightGreen;
        //color: $white;
        svg {
            height: 50px;
        }
    }
}

/* States */


#{$wg} {
    &-btn {

        &.no-vis {
            display: none;
        }
        &.compact {
            margin:1px;
        }
        &.is-active {
            background-color: $lightGreen;
            color: $white;
        }
    }
    &-more {

        &.no-vis {
            display: none;
        }
    }

}
