$blk: ".privateDormitoryRoomTypeBlock";

#{$blk} {
    background-color: #ffffff;

    &-content {
        border: 1px solid $grayDark;
        padding: 40px;
        margin-top: 40px;
    }

    &-heading {
        font-size: pxToRem(24);
        line-height: 28px;
        margin: 0 0 24px 0;
    }

    &-btnBookNow {
        margin: 5px 0 0 0 !important;
    }

    .imageGallery {
       // margin-bottom: 30px;

        &-content, &-frame, &-slide {
            height: inherit;
        }

        .video-container {
            padding-bottom: inherit;
        }
    }

    &-description {
        strong,
        b {
            font-family: $FuturaHeavyFontStack;
            font-size: 16px;
        }
    }

    .book-with-epack {
        width: 180px;
        display: inline-block;
        margin-top: 10px;
        color: #000000;
        font-weight: bold;

        svg {
            vertical-align: -2px;
            margin-left: 0;
        }
    }

}

/* States */

#{$blk} {
    @media (min-width: 768px) {
        &-content {
            > .row {
                display: flex;
                flex-direction: row;
                width: 100%;

                > [class^="col-"],
                > [class*="col-"] {
                    float: none !important;
                }
            }
        }
    }
}

