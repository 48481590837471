//@import "../../../glb/scss/variables";
//@import "../../../glb/scss/mixins/mx-selectors";

$pg: ".pgLoginPage";

#{$pg} {
    color: $baseTextColor;

    .signUpColumn {

        margin-top:110px;

        li {
            margin-bottom: 20px;
        }

    }
    .privacy {
        max-width: 300px;
        margin: auto;
        text-align: center;
        font-size: 14px;
        padding-top: 5px;
        color: $grayDarkest;
    }



    @media (max-width: $screen-sm-max) {

        .signUpColumn {
            max-width: 300px;
            margin: auto;
            text-align: center;
        }

    }



}
