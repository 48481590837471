
$blk: ".travelInsuranceBlock";

#{$blk} {
    .contents {
        padding: 20px 0;
    }
    .contentLink {
        padding-left: 20px;
        background: url('#{$imagePath}lists/chevron-thin-right-green.png') no-repeat top 3px left;
        background-size: 17px auto;
        margin: 20px 0;
    }

    img {
        width: 100%;

        max-width: 100%;
    }

    .button {
        margin-top: 20px;
    }


}


/* States */

//
//#{$blk} {
//
//}

