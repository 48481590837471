
$blk: ".findWorkInAusItemBlock";

#{$blk} {
    border-bottom: 1px solid #fff;
    padding: 20px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        padding-left: 20px;
        background: url('#{$imagePath}lists/chevron-thin-right-green.png') no-repeat top 6px left;
        background-size: 10px auto;
        margin-bottom: 10px;
    }

    li:last-child {
        margin-bottom: 0;
    }



}


/* States */

//
//#{$blk} {
//
//}

