$pg: ".pgEpackPaymentPage";

#{$pg} {

    color: $baseTextColor;

    .pageTitle {
        color: $baseTextColor;
    }

    .stepper {
        padding: 25px 0;
    }
    .heading {
        padding-top: 40px;
    }
    .mandatoryMessage {
        line-height: 56px;
        float: right;
    }
    .termsAndConditionsBlock {
        height: auto;
    }
    .acceptTerms {
        padding-bottom: 25px;
    }
    .invalid {
        background: url('#{$imagePath}forms/errorMessageIndicator.png') no-repeat;
        color: #EE4601;
        margin-top: 5px;
        padding-bottom: 2px;
        padding-left: 24px;
    }
}
