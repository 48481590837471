$pg: ".pgToursActivitiesListingPage";

#{$pg} {
    color: $baseTextColor;

    .headingSection {
        text-align: center;
    }

    .mainBanner {
        margin-bottom: 40px;

        .hero-image {
            max-width: 100%;
            //width: 100%;
            &-title {
                color: #fff;
                position: absolute;
                width: 100%;
                text-align: center;
                top: 50%;
                transform: translateY(-50%);
                font-size: 32px;
                margin: 0;
            }
        }

    }

    .sortDD {
        vertical-align: middle;
        padding: 25px 5px;
        font-size: 16px;
        text-align: right;

        span {
            display: inline-block;
        }

        label {
            display: inline-block;
        }
        select {
            display: inline-block;
            height: 48px;
            min-width: 165px;
        }
    }

    .pagingWrap {
        padding: 40px 0 40px;
    }

    .hsLstnWg-selectedHostelsTitle {

        @media (max-width: $screen-sm-max) {
            display: none;
        }
    }

}
