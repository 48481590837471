
$pg: ".pgHsJbPg";

#{$pg} {


    .wysiwygContent {
        img {
            width: auto;
            max-width: 100%;
        }
    }

}
